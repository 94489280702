export class EngineTurn {
    id: number;
    boatId: number;
    userId: number;
    boatModel: string;
    marinaId: number;
    engineTurnDate: any;
    isActive: boolean;
    isSea: boolean;
    observation: string;

    constructor() {
        this.engineTurnDate = new Date();
    }
}
