import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { Address, Customer, Marina, SlingConfig } from 'src/app/models';
import { FederalIdType } from 'src/app/models/federal-id-type';
import { Gender } from 'src/app/models/gender';
import { Licence } from 'src/app/models/license';
import { CustomerService } from 'src/app/services/customer.service';
import { GroupService } from 'src/app/services/group.service';
import { LocationService } from 'src/app/services/location.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { ToastService } from 'src/app/services/toast.service';
import { ValidatorsUtil } from 'src/app/services/validators.util';
import { MessageUtil } from 'src/app/utils/message.util';
import { Countries } from 'src/app/utils/location/countries';
import { environment } from 'src/environments/environment';
import { StorageUtil } from 'src/app/utils/storage.util';
import Swal from 'sweetalert2';
import { FileUpload } from 'primeng/fileupload';
import { FormUtil } from 'src/app/utils/form.util';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { DocumentStatus } from 'src/app/models/enums/document-status';
import { DocumentContractView } from 'src/app/models/dtos/document-contract-view';
import { VerifyContractsDialogComponent } from 'src/app/components/contracts/verify-contracts-dialog/verify-contracts-dialog.component';
import { ContractDocumentService } from 'src/app/services/contract-document.service';
import { FinancialMultipleCompaniesType } from 'src/app/models/enums/finances/financial-multiple-companies-type';
import { MaritalStatus } from 'src/app/models/enums/customer/marital-status';
import { CustomerProfile } from 'src/app/models/customer-profile';
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';
import { AccessGroupService } from '../../../../services/access-controler/access-group.service';
import { AccessGroup } from '../../../../models/dtos/access-controller/access-group';
import { AccessGroupEnum } from '../../../../models/dtos/access-controller/access-group-enum';
import { SelectItem } from 'primeng/api';
import { forkJoin, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { LicenseFiles } from 'src/app/models/license-files';
import { CustomerCatracasComponent } from '../customer-catracas/customer-catracas.component';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss'],
})
export class CustomerFormComponent implements OnInit {
  @ViewChild('upload', { static: false }) upload: FileUpload;

  uploadLicenceFileInProgress = false;
  uploadIsPdf: boolean = false;

  loadedForm = false;

  typesLicense = [
    { label: 'Selecione...' },
    { label: 'Veleiro', value: 'SAILBOAT' },
    { label: 'Motonauta', value: 'MOTONAUTA' },
    { label: 'Arrais-Amador', value: 'ARRAIS_AMATEUR' },
    { label: 'Mestre-Amador', value: 'MASTER_AMATEUR' },
    { label: 'Capitão-Amador', value: 'AMATEUR_CAPTAIN' },
    { label: 'Arrais-Amador e Motonauta', value: 'ARRAIS_AMATEUR_MOTONAUTA' },
    { label: 'Mestre-Amador e Motonauta', value: 'MASTER_AMATEUR_MOTONAUTA' },
    { label: 'Capitão-Amador e Motonauta', value: 'AMATEUR_CAPTAIN_MOTONAUTA' },
  ];
  customerForm = new UntypedFormGroup({});
  customer: Customer = new Customer();
  loading = false;

  errorMessage: string;
  countries: { label: string; value: any }[];
  groups: { label: string; value: any }[] = new Array();
  states: { label: string; value: any }[];
  cities: string[];
  cityCtrl: UntypedFormControl;
  cityCtrl2: UntypedFormControl;
  cityCtrl3: UntypedFormControl;

  licensePdf: Uint8Array;

  filteredCities: any;

  availableDays = new Array();

  validFederalId = true;

  isFederalIdInUse = false;

  isFederalTypePhysical = true;
  nameLabel = 'NAME';
  registrationLabel = 'PHYSICAL-REGISTRATION';
  dateLabel = 'DATE-BIRTH';
  slingConfig: SlingConfig;

  isFederalTypeForeigner = false;

  pt = {
    firstDayOfWeek: 0,
    dayNames: [
      'Domingo',
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
    ],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    monthNamesShort: [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ],
    today: 'Hoje',
    clear: 'Limpar',
  };

  billingCompanyOptions: { label: string; value: any }[];
  userApp: boolean;
  marina: Marina;
  federalIdType = 'Physical';
  onlyAssociated = false;
  gender = Gender.M;

  documentContracts: DocumentContractView[] = [];

  maritalStatus: SelectItem<MaritalStatus>[] = [
    { label: 'Selecione', value: null },
    { label: 'Solteiro', value: MaritalStatus.Solteiro },
    { label: 'Casado', value: MaritalStatus.Casado },
    { label: 'Divorciado', value: MaritalStatus.Divorciado },
    { label: 'Viuvo', value: MaritalStatus.Viuvo },
  ];

  isWorkshop: Boolean;
  imgPreviewPhoto: string;
  customerProfile: Array<any>;

  spouse: string = '';

  accessGroupOptions: SelectItem[] = [{ label: 'Selecione...', value: null }];
  selectedAccessGroup: SelectItem;

  constructor(
    private config: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    private validatorsUtil: ValidatorsUtil,
    private locationService: LocationService,
    private customerService: CustomerService,
    private messageUtil: MessageUtil,
    private groupService: GroupService,
    private spinner: NgxSpinnerService,
    private slingConfigService: SlingConfigService,
    private toasty: ToastService,
    private dialog: DialogService,
    private contractDocumentService: ContractDocumentService,
    private operationalConfigService: OperationalConfigService,
    private accessCategoryService: AccessGroupService,
    private router: Router
  ) {
    this.marina = StorageUtil.getMarina();
    this.cities = [];
    this.cityCtrl = new UntypedFormControl();
    this.cityCtrl2 = new UntypedFormControl();
    this.cityCtrl3 = new UntypedFormControl();
  }

  async ngOnInit(): Promise<void> {
    forkJoin([
      this.getAccessGroupOptions(),
      this.loadGroupActive(),
      this.getWorkshopConfig(),
      this.findSlingConfig(),
    ]).subscribe({
      next: (results) => {
        this.isWorkshop = results[2];
        this.getCustomerProfiles();

        this.loadCountries();
        this.loadDaysDue();
        if (this.config?.data?.customer) {
          console.log(this.config?.data?.customer);

          this.userApp = this.config.data.userApp;
          const customer: Customer = this.config.data.customer;
          if (customer.commercialAddress == null) {
            customer.commercialAddress = new Address();
          } else {
            this.loadStates(customer.commercialAddress.country);
            this.loadCities(
              customer.commercialAddress.country,
              customer.commercialAddress.state
            );
          }
          if (customer.residentialAddress == null) {
            customer.residentialAddress = new Address();
          } else {
            this.loadStates(customer.residentialAddress.country);
            this.loadCities(
              customer.residentialAddress.country,
              customer.residentialAddress.state
            );
          }
          if (customer.licence == null) {
            customer.licence = new Licence();
          }
          customer.birthday = customer.birthday
            ? new Date(customer.birthday)
            : null;
          customer.billingDay = customer.billingDay
            ? new Date(customer.billingDay)
            : null;
          if (customer.licence.expiration) {
            customer.licence.expiration = new Date(customer.licence.expiration);
          }
          this.customer = customer;
          if (this.customer.id) {
            this.onlyAssociated = this.checkIfCustomerIsOnlyAssociated(
              this.customer
            );
            this.federalIdType = this.customer.federalIdType;
            this.gender = this.customer.gender;
            this.verifyContract();
          }
          this.customer.group = customer.group ? customer.group : { id: null };
          this.imgPreviewPhoto = customer?.photoUrl;

          this.selectedAccessGroup = this.accessGroupOptions.find(
            (item) => item?.value?.id === this.customer?.accessGroup?.id
          );
          this.customer.accessGroup = this.selectedAccessGroup.value;
          if (this.customer.accessGroup == null) {
            this.customer.accessGroup = this.accessGroupOptions[0].value;
          }
          this.startForm(customer);
        } else {
          this.selectedAccessGroup = this.accessGroupOptions[0];
          this.customer.accessGroup = this.selectedAccessGroup.value;
          this.startForm(this.customer);
        }

        this.loadedForm = true;
        this.validateFederalIdType();
        this.createBilingCompanyOptions();
        if (
          this.customer.billingCompany &&
          this.customer.billingCompany.id > 0
        ) {
          this.customerForm
            .get('billingCompanyOptionId')
            .setValue(this.customer.billingCompany);
        } else {
          if (
            this.billingCompanyOptions &&
            this.billingCompanyOptions.length > 0
          ) {
            this.customerForm
              .get('billingCompanyOptionId')
              .setValue(this.billingCompanyOptions[0].value); // default
          }
        }
      },
      error: (error) => {
        this.messageUtil.generateMessage(
          error.error.status,
          'SUMMARY.ERROR',
          error.error.message
        );
      },
    });
  }

  private getAccessGroupOptions(): Observable<void> {
    return new Observable<void>((observer) => {
      this.accessCategoryService.findAll().subscribe({
        next: (data) => {
          this.accessGroupOptions = data
            .map(
              (ag) =>
                <SelectItem>{
                  label: ag.name,
                  value: ag,
                }
            )
            .filter(
              (ag) =>
                ag.value.active === true &&
                (ag.value.type === AccessGroupEnum.NON_MEMBER ||
                  ag.value.type === AccessGroupEnum.OWNER)
            );
          observer.next();
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        },
      });
    });
  }

  private loadGroupActive(): Observable<void> {
    return new Observable<void>((observer) => {
      this.groupService.findAllActive().subscribe({
        next: (data) => {
          this.groups = [{ label: 'Selecione...', value: null }].concat(
            data.map((g) => ({ label: g.name, value: g.id }))
          );

          observer.next();
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        },
      });
    });
  }

  private getWorkshopConfig() {
    return this.operationalConfigService.isWorkshop();
  }

  private findSlingConfig(): Observable<void> {
    return new Observable<void>((observer) => {
      this.slingConfigService.getSlingConfigToday().subscribe({
        next: (data) => {
          this.slingConfig = data;
          observer.next();
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        },
      });
    });
  }

  getCustomerProfiles() {
    this.customerProfile = [
      { label: 'Marina', value: CustomerProfile.Marine },
      this.isWorkshop
        ? { label: 'Oficina', value: CustomerProfile.Workshop }
        : null,
      this.isWorkshop
        ? {
            label: 'Oficina e Marina',
            value: CustomerProfile.MarineAndWorkshop,
          }
        : null,
    ];
  }

  loadDaysDue(): void {
    this.slingConfigService
      .getDaysDue()
      .subscribe(
        (data) =>
          (this.availableDays = [{ label: 'Selecione...', value: null }].concat(
            data
              .split(',')
              .map((d) => ({ label: d, value: Number.parseInt(d, 10) }))
          ))
      );
  }

  viewDocumentation(model): void {
    window.open(model.tieFile);
  }

  async checkFederalIdField(): Promise<void> {
    await this.federalIdInUse();
    this.validateFederalId();
  }

  async federalIdInUse(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      this.isFederalIdInUse = false;
      const federalIdClean = this.customerForm
        .get('federalId')
        .value.replace('/', '_');
      this.customerService.getByFederalId(federalIdClean).subscribe(
        async (data) => {
          if (data !== null && data.id !== this.customerForm.get('id').value) {
            this.isFederalIdInUse = true;
            this.customerForm
              .get('federalId')
              .setErrors({ federalIdInUse: true });
            this.customerForm.get('federalId').markAsTouched();
            this.customerForm.get('federalId').markAsDirty();
            resolve();
          }
        },
        async (err) => {
          resolve();
        }
      );
    });
  }

  validateFederalId(): void {
    this.validFederalId = this.validatorsUtil.cpfCnpjValidator(
      this.customerForm.value.federalId
    );
    if (!this.validFederalId) {
      this.customerForm.get('federalId').setErrors({ invalidFederalId: true });
      this.customerForm.get('federalId').markAsTouched();
      this.customerForm.get('federalId').markAsDirty();
    }
  }

  loadCountries(): void {
    this.countries = [{ label: 'Selecione...', value: null }].concat(
      Countries.countryList.map((c) => ({ label: c.name, value: c.code }))
    );
  }

  loadStates(countryId): void {
    this.states = [{ label: 'Selecione...', value: null }].concat(
      this.locationService
        .getStates()
        .map((s) => ({ label: s.name, value: s.id }))
    );
  }

  loadCities(countryId: string, stateId: string): void {
    this.locationService.getCities(countryId, stateId).subscribe(
      (data) => {
        this.cities = data.map((c) => c.name);
      },
      (error) => {
        this.cities = null;
        this.errorMessage = error;
      }
    );
  }

  receiveUploadUrlPhoto($event) {
    this.customerForm.get('photoUrl').setValue($event);
    this.imgPreviewPhoto = $event;
  }

  filterCities(val: any): void {
    this.filteredCities = val
      ? this.cities.filter((c) =>
          c.toUpperCase().includes(val.query.toUpperCase())
        )
      : this.cities;
  }

  validateRequiredFields(): void {
    const addressTypeRequired =
      this.isFederalTypePhysical || this.isFederalTypeForeigner
        ? 'residentialAddress'
        : 'commercialAddress';
    const addressType =
      !this.isFederalTypePhysical && !this.isFederalTypeForeigner
        ? 'residentialAddress'
        : 'commercialAddress';
    this.customerForm.get(`${addressTypeRequired}.zip`).clearValidators();
    this.customerForm.get(`${addressTypeRequired}.country`).clearValidators();
    this.customerForm.get(`${addressTypeRequired}.state`).clearValidators();
    this.customerForm.get(`${addressTypeRequired}.city`).clearValidators();
    this.customerForm.get(`${addressTypeRequired}.street`).clearValidators();
    this.customerForm.get(`${addressTypeRequired}.number`).clearValidators();
    this.customerForm.get(`${addressType}.zip`).clearValidators();
    this.customerForm.get(`${addressType}.country`).clearValidators();
    this.customerForm.get(`${addressType}.state`).clearValidators();
    this.customerForm.get(`${addressType}.city`).clearValidators();
    this.customerForm.get(`${addressType}.street`).clearValidators();
    this.customerForm.get(`${addressType}.number`).clearValidators();
    this.customerForm.get('emailCollection').clearValidators();
    this.customerForm.get('dueDate').clearValidators();
    if (!this.onlyAssociated) {
      this.customerForm
        .get(`${addressTypeRequired}.zip`)
        .setValidators(Validators.required);
      this.customerForm
        .get(`${addressTypeRequired}.country`)
        .setValidators(Validators.required);
      this.customerForm
        .get(`${addressTypeRequired}.state`)
        .setValidators(Validators.required);
      this.customerForm
        .get(`${addressTypeRequired}.city`)
        .setValidators(Validators.required);
      this.customerForm
        .get(`${addressTypeRequired}.street`)
        .setValidators(Validators.required);
      this.customerForm
        .get(`${addressTypeRequired}.number`)
        .setValidators(Validators.required);
      this.customerForm
        .get('emailCollection')
        .setValidators(Validators.required);
      this.customerForm.get('dueDate').setValidators(Validators.required);
    }
    this.customerForm.get(addressTypeRequired).updateValueAndValidity();
    this.customerForm.get(addressType).updateValueAndValidity();
    this.customerForm.get('emailCollection').updateValueAndValidity();
    this.customerForm.get('dueDate').updateValueAndValidity();

    this.customerForm
      .get(`${addressTypeRequired}.zip`)
      .updateValueAndValidity();
    this.customerForm
      .get(`${addressTypeRequired}.country`)
      .updateValueAndValidity();
    this.customerForm
      .get(`${addressTypeRequired}.state`)
      .updateValueAndValidity();
    this.customerForm
      .get(`${addressTypeRequired}.city`)
      .updateValueAndValidity();
    this.customerForm
      .get(`${addressTypeRequired}.street`)
      .updateValueAndValidity();
    this.customerForm
      .get(`${addressTypeRequired}.number`)
      .updateValueAndValidity();
    this.customerForm.get(`${addressType}.zip`).updateValueAndValidity();
    this.customerForm.get(`${addressType}.country`).updateValueAndValidity();
    this.customerForm.get(`${addressType}.state`).updateValueAndValidity();
    this.customerForm.get(`${addressType}.city`).updateValueAndValidity();
    this.customerForm.get(`${addressType}.street`).updateValueAndValidity();
    this.customerForm.get(`${addressType}.number`).updateValueAndValidity();
  }

  valiteCommercialAddress() {
    if (
      (this.isFederalTypePhysical || this.isFederalTypeForeigner) &&
      !this.onlyAssociated
    ) {
      const zip = this.customerForm.get('commercialAddress.zip').value;
      const country = this.customerForm.get('commercialAddress.country').value;
      const state = this.customerForm.get('commercialAddress.state').value;
      const city = this.customerForm.get('commercialAddress.city').value;
      const street = this.customerForm.get('commercialAddress.street').value;
      const number = this.customerForm.get('commercialAddress.number').value;
      const district = this.customerForm.get(
        'commercialAddress.district'
      ).value;
      const complement = this.customerForm.get(
        'commercialAddress.complement'
      ).value;

      if (
        (zip != null && zip.trim() !== '') ||
        (country != null && country.trim() !== '') ||
        (state != null && state.trim() !== '') ||
        (city != null && city.trim() !== '') ||
        (street != null && street.trim() !== '') ||
        (number != null && number.trim() !== '') ||
        (district != null && district.trim() !== '') ||
        (complement != null && complement.trim() !== '')
      ) {
        this.customerForm
          .get('commercialAddress.zip')
          .setValidators(Validators.required);
        this.customerForm
          .get('commercialAddress.country')
          .setValidators(Validators.required);
        this.customerForm
          .get('commercialAddress.state')
          .setValidators(Validators.required);
        this.customerForm
          .get('commercialAddress.city')
          .setValidators(Validators.required);
        this.customerForm
          .get('commercialAddress.street')
          .setValidators(Validators.required);
        this.customerForm
          .get('commercialAddress.number')
          .setValidators(Validators.required);
      } else {
        this.customerForm.get('commercialAddress.zip').clearValidators();
        this.customerForm.get('commercialAddress.country').clearValidators();
        this.customerForm.get('commercialAddress.state').clearValidators();
        this.customerForm.get('commercialAddress.city').clearValidators();
        this.customerForm.get('commercialAddress.street').clearValidators();
        this.customerForm.get('commercialAddress.number').clearValidators();
        this.customerForm.get('commercialAddress.zip').setValue(null);
        this.customerForm.get('commercialAddress.country').setValue(null);
        this.customerForm.get('commercialAddress.state').setValue(null);
        this.customerForm.get('commercialAddress.city').setValue(null);
        this.customerForm.get('commercialAddress.street').setValue(null);
        this.customerForm.get('commercialAddress.number').setValue(null);
        this.customerForm.get('commercialAddress.district').setValue(null);
        this.customerForm.get('commercialAddress.complement').setValue(null);
      }
      this.customerForm.get('commercialAddress.zip').updateValueAndValidity();
      this.customerForm
        .get('commercialAddress.country')
        .updateValueAndValidity();
      this.customerForm.get('commercialAddress.state').updateValueAndValidity();
      this.customerForm.get('commercialAddress.city').updateValueAndValidity();
      this.customerForm
        .get('commercialAddress.street')
        .updateValueAndValidity();
      this.customerForm
        .get('commercialAddress.number')
        .updateValueAndValidity();
      this.customerForm
        .get('commercialAddress.district')
        .updateValueAndValidity();
      this.customerForm
        .get('commercialAddress.complement')
        .updateValueAndValidity();
    }
  }

  validateFederalIdType(): void {
    if (this.federalIdType === FederalIdType.Physical) {
      this.isFederalTypePhysical = true;
      this.isFederalTypeForeigner = false;
      this.nameLabel = 'NAME';
      this.registrationLabel = 'PHYSICAL-REGISTRATION';
      this.dateLabel = 'DATE-BIRTH';
    } else if (this.federalIdType === FederalIdType.Foreigner) {
      this.isFederalTypePhysical = false;
      this.isFederalTypeForeigner = true;
      this.nameLabel = 'NAME';
      this.registrationLabel = 'CPF-RESIDENT';
      this.dateLabel = 'DATE-BIRTH';
    } else {
      this.isFederalTypePhysical = false;
      this.isFederalTypeForeigner = false;
      this.nameLabel = 'COMPANY-NAME';
      this.registrationLabel = 'JURIDICAL-REGISTRATION';
      this.dateLabel = 'OPENING-DATE';
    }
    if (!this.customer.id) {
      this.customerForm.get('federalId').setValue('');
      this.customerForm.get('federalId').setErrors([]);
    }
    this.validateRequiredFields();
  }

  getCep(addressType: string): void {
    this.spinner.show();
    let address: Address = new Address();
    let zipCode = '';
    if (addressType === 'residential') {
      zipCode = this.customerForm.get('residentialAddress.zip').value;
      address = this.customerForm.get('residentialAddress').value;
    } else if (addressType === 'commercial') {
      zipCode = this.customerForm.get('commercialAddress.zip').value;
      address = this.customerForm.get('commercialAddress').value;
    }

    if (address.zip) {
      address.zip = address.zip.replace(/\D+/g, '');
    }

    this.locationService.getCep(address.zip).subscribe(
      (data) => {
        this.spinner.hide();
        if (data !== null) {
          this.resetAddressFields(address);
          const cep = data;
          address.zip = zipCode;
          address.country = 'BR';
          this.loadStates(address.country);
          address.state = cep.uf;
          address.city = this.firstLetterToUpperCase(cep.cidade);
          address.district = this.firstLetterToUpperCase(cep.bairro);
          address.street = this.firstLetterToUpperCase(cep.logradouro);
          if (addressType === 'residential') {
            this.customerForm.get('residentialAddress').setValue(address);
          } else {
            this.customerForm.get('commercialAddress').setValue(address);
          }
        } else {
          this.messageUtil.generateMessage(
            'warning',
            'SUMMARY.WARNING',
            'MSG.CEP-INVALID'
          );
          address.zip = null;
        }
      },
      (error) => {
        this.spinner.hide();
        this.resetAddressFields(address);
        this.errorMessage = error;
      }
    );
  }

  resetAddressFields(address: Address): void {
    address.zip = null;
    address.country = null;
    address.state = null;
    address.city = null;
    address.district = null;
    address.street = null;
    address.number = null;
    address.complement = null;
  }

  get urlUploadLicenceFile(): string {
    return environment.apiHost + '/secure/customers/licence-file/attachment';
  }

  beforeUploadLicenceFile(event): void {
    const token: string = StorageUtil.getToken();
    event.xhr.setRequestHeader('Authorization', 'Bearer ' + token);
    this.uploadLicenceFileInProgress = true;
  }

  afterUploadLicenceFile(event): void {
    const attachment = JSON.parse(event.xhr.response).data.attachment;
    this.customerForm.get('licence.file').setValue(attachment.url);
    this.uploadLicenceFileInProgress = false;
  }

  errorUploadLicenceFile(event): void {
    this.uploadLicenceFileInProgress = false;
    alert('Erro ao tentar enviar anexo');
  }

  private firstLetterToUpperCase(text: string): string {
    const words = text.toLowerCase().split(' ');
    for (let a = 0; a < words.length; a++) {
      const w = words[a];
      if (w[0] !== undefined) {
        words[a] = w[0].toUpperCase() + w.slice(1);
      }
    }
    return words.join(' ');
  }

  formValidations(form): boolean {
    if (
      form.valid &&
      (this.isFederalTypePhysical ? this.cityCtrl.valid : this.cityCtrl2.valid)
    ) {
      return true;
    } else {
      this.messageUtil.generateMessage(
        'warning',
        'SUMMARY.WARNING',
        'MSG.FIELD-REQUIRED'
      );
      return false;
    }
  }

  openIntegration(customerInserted): void {
    this.dialog.open(CustomerCatracasComponent, {
      data: {
        customer: customerInserted,
      },
      header: 'Integração das Catracas',
      width: '80%',
      height: '80%',
    });
  }

  save(): void {
    this.valiteCommercialAddress();
    FormUtil.touchAndSendFormGroup(this.customerForm);
    const accessGroupCustomer: AccessGroup =
      this.customerForm.get('accessGroup').value.value;

    if (
      !this.isOnlyAssociated() &&
      accessGroupCustomer.type === AccessGroupEnum.NON_MEMBER
    ) {
      this.messageUtil.generateMessage(
        'warning',
        'SUMMARY.WARNING',
        'Cliente proprietário de barco não pode ser definido como autorizado'
      );
    } else if (!this.customerForm.valid) {
      this.messageUtil.generateMessage(
        'warning',
        'SUMMARY.WARNING',
        'Por favor, preencha os campos obrigatórios'
      );
      return;
    } else {
      if (accessGroupCustomer.type === AccessGroupEnum.NON_MEMBER) {
        this.customerForm.get('onlyAssociated').setValue(true);
      } else {
        this.customerForm.get('onlyAssociated').setValue(false);
      }
      Object.keys(this.customerForm.controls).forEach((key) => {
        this.customer[key] = this.customerForm.get(key).value;
      });
      this.loading = true;
      this.spinner.show();
      if (
        this.customerForm.get('billingCompanyOptionId').value &&
        this.customerForm.get('billingCompanyOptionId').value.id > 0
      ) {
        this.customer.billingCompany = this.billingCompanyOptions.find((op) => {
          return (
            op.value.id ===
            this.customerForm.get('billingCompanyOptionId').value.id
          );
        }).value;
        this.customer.billingCompany.marina = { id: this.customer.marina.id };
      } else {
        this.customer.billingCompany = null;
      }
      if (this.customer.id) {
        this.contractAlert();
      } else {
        this.customer.marina.id = StorageUtil.getMarinaId();
        let customerInserted: Customer;
        this.customer.accessGroup = accessGroupCustomer;

        this.customerService.create(this.customer).subscribe({
          next: (data) => {
            customerInserted = data;
            if (data?.catracas.length > 0) {
              this.openIntegration(customerInserted);
            }
            this.toasty.success('Cliente cadastrado com sucesso!');
            if (this.router.url.includes('new-budget-customer')) {
              window.parent.postMessage({ type: 'success' }, '*');
            }
            this.dialogRef.close(data);
          },
          error: (error) => {
            if (error.error.data.exception.message === 'MSG.EMAIL-EXISTS') {
              this.customerService
                .getByEmailAndMarinaId(this.customer.email)
                .subscribe({
                  next: (value) => {
                    Swal.fire(
                      'Falha ao cadastrar!',
                      this.messageUtil.translateKey(
                        error.error.data.exception.message
                      ) +
                        ' no cliente ' +
                        value.name,
                      'error'
                    );
                    this.spinner.hide();
                  },
                  error: (error) => {
                    Swal.fire(
                      'Falha ao cadastrar!',
                      '\nEmail reservado.',
                      'error'
                    );
                    this.spinner.hide();
                  },
                });
            } else {
              Swal.fire(
                'Falha ao cadastrar!',
                this.messageUtil.translateKey(
                  error.error.data.exception.message
                ),
                'error'
              );
              this.spinner.hide();
            }
          },
          // integracao financeiro
          complete: () => {
            this.spinner.hide();
          },
        });
      }
    }
  }

  private isOnlyAssociated(): boolean {
    if (this.customer.id) {
      return (
        !this.customer.boatCustomersDTO ||
        this.customer.boatCustomersDTO.find((bc) =>
          bc.customers.find(
            (c) => c.id === this.customer.id && c.customerType === 'Owner'
          )
        ) === undefined
      );
    } else {
      return true;
    }
  }

  viewLicence(customer): void {
    window.open(customer.licence.file);
  }

  hasNoRegistration(customers: any[]): boolean {
    return (
      customers.find((customer) => customer.customerType === 'User') !==
      undefined
    );
  }

  closeaDialog(): void {
    this.dialogRef.close();
  }

  onlyAssociatedFn(accessGroup: AccessGroup): ValidatorFn {
    const customer = new Customer();
    customer.accessGroup = accessGroup;
    return this.checkIfCustomerIsOnlyAssociated(customer)
      ? Validators.required
      : null;
  }

  ownerFn(customer: Customer): ValidatorFn {
    return !this.checkIfCustomerIsOnlyAssociated(customer)
      ? Validators.required
      : null;
  }

  isResidentialAddressRequired(customer: Customer): ValidatorFn {
    return this.isFederalTypePhysical &&
      !this.checkIfCustomerIsOnlyAssociated(customer)
      ? Validators.required
      : null;
  }

  isCommercialAddressRequired(customer: Customer): ValidatorFn {
    return !this.isFederalTypePhysical &&
      !this.checkIfCustomerIsOnlyAssociated(customer)
      ? Validators.required
      : null;
  }

  checkIfCustomerIsOnlyAssociated(customer: Customer) {
    return customer.accessGroup.type === AccessGroupEnum.NON_MEMBER;
  }

  isEdit(): boolean {
    return (
      this.customer &&
      this.customer.id !== undefined &&
      this.customer.id !== null
    );
  }

  startForm(customer: Customer): void {
    this.customerForm = new UntypedFormGroup({
      id: new UntypedFormControl(customer.id),
      federalIdType: new UntypedFormControl(
        { value: customer.federalIdType, disabled: this.isEdit() },
        Validators.required
      ),
      onlyAssociated: new UntypedFormControl(
        customer.onlyAssociated,
        Validators.required
      ),
      accessGroup: new FormControl<SelectItem>(
        this.selectedAccessGroup,
        Validators.required
      ),
      name: new UntypedFormControl(
        customer.name,
        Validators.compose([Validators.required, Validators.maxLength(255)])
      ),
      federalId: new UntypedFormControl(
        customer.federalId,
        Validators.compose([Validators.required, Validators.maxLength(20)])
      ),
      gender: new UntypedFormControl(
        customer.gender ? customer.gender : Gender.M,
        Validators.required
      ),
      registration: new UntypedFormControl(customer.registration),
      profession: new UntypedFormControl(customer.profession),
      email: new UntypedFormControl(
        customer.email,
        Validators.compose([Validators.required, Validators.email])
      ),
      maritalStatus: new UntypedFormControl(customer.maritalStatus),
      spouse: new UntypedFormControl(customer.spouse),
      phone: new UntypedFormControl(
        customer.phone,
        Validators.compose([Validators.required])
      ),
      phone2: new UntypedFormControl(customer.phone2),
      residentialPhone: new UntypedFormControl(customer.residentialPhone),
      commercialPhone: new UntypedFormControl(customer.commercialPhone),
      photoUrl: new UntypedFormControl(customer.photoUrl),
      birthday: new UntypedFormControl(customer.birthday),
      emailCollection: new UntypedFormControl(
        customer.emailCollection,
        this.ownerFn(customer)
      ),
      billingDay: new UntypedFormControl(customer.billingDay),
      dueDate: new UntypedFormControl(customer.dueDate, this.ownerFn(customer)),
      group: new UntypedFormGroup({
        id: new UntypedFormControl(
          customer.group?.id || null,
          this.onlyAssociatedFn(customer.accessGroup)
        ),
      }),
      residentialAddress: new UntypedFormGroup({
        zip: new UntypedFormControl(
          customer.residentialAddress.zip,
          this.isResidentialAddressRequired(customer)
        ),
        country: new UntypedFormControl(
          customer.residentialAddress.country,
          this.isResidentialAddressRequired(customer)
        ),
        state: new UntypedFormControl(
          customer.residentialAddress.state,
          this.isResidentialAddressRequired(customer)
        ),
        city: new UntypedFormControl(
          customer.residentialAddress.city,
          this.isResidentialAddressRequired(customer)
        ),
        street: new UntypedFormControl(
          customer.residentialAddress.street,
          this.isResidentialAddressRequired(customer)
        ),
        district: new UntypedFormControl(customer.residentialAddress.district),
        number: new UntypedFormControl(
          customer.residentialAddress.number,
          this.isResidentialAddressRequired(customer)
        ),
        complement: new UntypedFormControl(
          customer.residentialAddress.complement
        ),
      }),
      commercialAddress: new UntypedFormGroup({
        zip: new UntypedFormControl(
          customer.commercialAddress.zip,
          this.isCommercialAddressRequired(customer)
        ),
        country: new UntypedFormControl(
          customer.commercialAddress.country,
          this.isCommercialAddressRequired(customer)
        ),
        state: new UntypedFormControl(
          customer.commercialAddress.state,
          this.isCommercialAddressRequired(customer)
        ),
        city: new UntypedFormControl(
          customer.commercialAddress.city,
          this.isCommercialAddressRequired(customer)
        ),
        street: new UntypedFormControl(
          customer.commercialAddress.street,
          this.isCommercialAddressRequired(customer)
        ),
        district: new UntypedFormControl(customer.commercialAddress.district),
        number: new UntypedFormControl(
          customer.commercialAddress.number,
          this.isCommercialAddressRequired(customer)
        ),
        complement: new UntypedFormControl(
          customer.commercialAddress.complement
        ),
      }),
      emergencyContact: new UntypedFormControl(customer.emergencyContact),
      emergencyPhone: new UntypedFormControl(customer.emergencyPhone),
      observation: new UntypedFormControl(customer.observation),
      licence: new UntypedFormGroup({
        register: new UntypedFormControl(customer.licence.register),
        expiration: new UntypedFormControl(customer.licence.expiration),
        typeLicense: new UntypedFormControl(customer.licence.typeLicense),
        country: new UntypedFormControl(customer.licence.country),
        state: new UntypedFormControl(customer.licence.state),
        city: new UntypedFormControl(customer.licence.city),
        files: new FormArray<FormGroup>([]),
      }),
      billingCompanyOptionId: new UntypedFormControl(customer.billingCompany),
      issRetidoFonte: new UntypedFormControl(customer.issRetidoFonte),
      customerProfile: new UntypedFormControl(customer.customerProfile),
    });

    if (customer.licence.files && customer.licence.files.length > 0) {
      customer.licence.files.forEach((f) => {
        this.addFile(f);
      });
    }
    console.log(this.customerForm.get('licence.files'));
  }

  addFile(f?: LicenseFiles): void {
    if (!f) {
      f = new LicenseFiles();
    }
    const controls = [
      ...(this.customerForm.get('licence.files') as UntypedFormArray).controls,
    ];
    const formGroup = new UntypedFormGroup({
      id: new UntypedFormControl(f.id),
      name: new UntypedFormControl(f.name),
      path: new UntypedFormControl(f.path),
    });

    controls.push(formGroup);

    (this.customerForm.get('licence.files') as UntypedFormArray).push(
      formGroup
    );
  }

  deleteFile(index: number): void {
    const controls = (
      this.customerForm.get('licence.files') as UntypedFormArray
    ).controls;

    controls.splice(index, 1);
    this.customerForm.setControl(
      'licence.files',
      new UntypedFormArray([...controls])
    );
  }

  openFile(file: LicenseFiles): void {
    console.log(file);
    window.open(file.path);
  }

  createBilingCompanyOptions(): void {
    let compArray: any[] = [];
    if (this.marina.marinaCompanies && this.marina.marinaCompanies.length > 0) {
      compArray = [
        {
          id: -1,
          companyFederalName: this.marina.companyName + ' (principal)',
          companyFederalId: this.marina.companyFederalId,
          invoiceGatewayId: null,
          expirationDigitalCertificate: null,
        },
        ...this.marina.marinaCompanies,
      ];
    }

    this.billingCompanyOptions = compArray.map((o) => ({
      label: o.companyFederalName + ' - ' + o.companyFederalId,
      value: o,
    }));
  }

  async onBasicUpload(files): Promise<any> {
    this.customerService.uploadLicence(files).subscribe(
      (data) => {
        const file = new LicenseFiles();
        file.path = data.url;
        file.name = 'Novo arquivo';

        this.addFile(file);

        this.upload.clear();
      },
      (event) => {
        this.messageUtil.generateMessage(
          'warning',
          'SUMMARY.WARNING',
          'Erro ao tentar enviar o arquivo.'
        );
        this.upload.clear();
      }
    );
  }

  getErrorTooltip(control: AbstractControl): string {
    let tooltip = '';
    if (control && control.touched && control.dirty && control.errors) {
      Object.keys(control.errors).forEach((key) => {
        switch (key) {
          case 'email':
            tooltip += this.messageUtil.translateKey('NOT-FORMATTED-EMAIL');
            tooltip += '\n';
            break;
          case 'required':
            tooltip += this.messageUtil.translateKey('REQUIRED-FIELD');
            tooltip += '\n';
            break;
          case 'federalIdInUse':
            tooltip += this.messageUtil.translateKey(
              'FEDERAL-ID-ALREADY-EXISTS'
            );
            tooltip += '\n';
            break;
          case 'invalidFederalId':
            tooltip += this.messageUtil.translateKey('INVALID-FEDERAL-ID');
            tooltip += '\n';
            break;
          default:
            break;
        }
      });
    }

    return tooltip;
  }

  verifyContract(): void {
    if (!this.customer.id || !this.slingConfig.hasContracts) {
      return;
    }

    const pagination: PaginationFilter = {
      size: 100,
      page: 0,
      order: 'DESC',
      sort: 'createdAt',
    };

    const statusList = [
      DocumentStatus.SIGNED,
      DocumentStatus.WAITING_SIGNATORIES,
      DocumentStatus.WAITING_SIGNATURES,
      DocumentStatus.PROCESSING_SIGNATURES,
      DocumentStatus.PROCESSING_FILE,
    ];

    this.contractDocumentService
      .getDocuments(
        { emailSigner: this.customer.email, statusList, serviceActive: true },
        pagination
      )
      .subscribe(
        (data) => {
          this.documentContracts = data.content.filter((d) => d.hasNewer === 0);
        },
        (error) => console.log(error)
      );
  }

  contractAlert(): void {
    this.spinner.hide();
    if (this.documentContracts.length > 0) {
      Swal.fire({
        title: this.messageUtil.translateKey('SUMMARY.WARNING'),
        icon: 'warning',
        text: 'Este registro possui contratos. Verifique se eles serão afetados.',
        showCancelButton: true,
        width: 600,
        showDenyButton: true,
        showConfirmButton: true,
        focusDeny: true,
        reverseButtons: true,
        denyButtonText: `Gerenciar contratos`,
        confirmButtonText: 'Salvar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.saveVerifiedCustomer();
        } else if (result.isDenied) {
          this.managerContract();
        }
      });
    } else {
      this.saveVerifiedCustomer();
    }
  }

  managerContract(): void {
    this.dialog.open(VerifyContractsDialogComponent, {
      data: {
        documents: this.documentContracts,
      },
      header: 'Gerenciar contratos',
    });
  }

  saveVerifiedCustomer(): void {
    this.customer.accessGroup =
      this.customerForm.get('accessGroup').value.value;
    this.customerService.update(this.customer).subscribe({
      next: (data) => {
        this.toasty.success('Cliente alterado com sucesso!');
        if (data?.catracas.length > 0) {
          this.openIntegration(data);
        }

        this.dialogRef.close(data);
      },
      error: (error) => {
        if (error.error.message === 'MSG.EMAIL-EXISTS') {
          this.customerService
            .getByEmailAndMarinaId(this.customer.email)
            .subscribe(
              (value) => {
                Swal.fire(
                  'Falha ao cadastrar!',
                  this.messageUtil.translateKey(error.error.message) +
                    ' no cliente ' +
                    value.name,
                  'error'
                );
                this.spinner.hide();
              },
              (error) => {
                Swal.fire('Falha ao cadastrar!', '\nEmail reservado.', 'error');
                this.spinner.hide();
              }
            );
        } else {
          Swal.fire(
            'Falha ao alterar o cliente!',
            this.messageUtil.translateKey(error.error.message),
            'error'
          );
          this.spinner.hide();
        }
      },
      complete: () => this.spinner.hide(),
    });
  }

  enableFinancialCompanyOptions(): boolean {
    const enable =
      this.slingConfig.financialMultipleCompaniesType ===
        FinancialMultipleCompaniesType.ByCustomer &&
      this.marina?.marinaCompanies?.length > 0;
    return enable;
  }

  getNameDocumentFile(fileName: string): string {
    return fileName.substring(fileName.indexOf('_EASY_') + 6);
  }
}
