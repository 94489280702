import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { BoatWash } from 'src/app/models/boatWash';
import { BoatWashService } from 'src/app/services/boat-wash.service';
import { EngineTurnService } from 'src/app/services/engine-turn.service';
import { ToastService } from 'src/app/services/toast.service';
import { MessageUtil } from 'src/app/utils/message.util';
import  moment from 'moment';
import { EngineTurn } from 'src/app/models/engine-turn';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { StorageUtil } from 'src/app/utils/storage.util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-boat-wash-engine-turn-form',
  templateUrl: './boat-wash-engine-turn-form.component.html',
  styleUrls: ['./boat-wash-engine-turn-form.component.scss']
})
export class BoatWashEngineTurnFormComponent implements OnInit {

  boatsWash = [];
  enginesTurn: any[];
  requestDate = new Date();
  washEngineTurnOption: string;
  today = new Date();
  observation: string = '';
  OneTimeScheduling: boolean = false
  executeBoatWash: boolean = false;
  executeEngineTurn: boolean = false;
  isSubmitting: boolean = false;

  pt = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };

  requestPayload = {
    executeBoatWash: false,
    executeEngineTurn: false,
    boatWashes: [],
    engineTurns: [],
  };
  clickCount: number = 0;


  constructor(
    private boatWashService: BoatWashService,
    private messageUtil: MessageUtil,
    private toasty: ToastService,
    private engineTurnService: EngineTurnService,
    public dialog: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.resetClickCount();
    this.isSubmitting = false;
    if (this.config.data.boatsWash.length == 1) {
      this.OneTimeScheduling = true;
    }
      
    
    
    this.boatsWash = this.config.data.boatsWash;
    this.enginesTurn = this.config.data.enginesTurn;
  }


  resetClickCount() {
    this.clickCount = 0;
  }

  confirmRegistration(): void {

    if (this.isSubmitting) {
      return;
    }
  this.clickCount++;

  if (this.clickCount > 1) {
    Swal.fire({
      title: 'Operação já foi confirmada!',
      text: 'Você já clicou mais de uma vez, a operação não será repetida.',
      icon: 'warning',
      confirmButtonText: 'Fechar'
    }).then(() => {
      this.resetClickCount();
      this.dialog.close(null);
    });
    return;
  }

  this.isSubmitting = true;

  Swal.fire({
    title: 'Confirmar operação?',
    showCancelButton: true,
    confirmButtonText: 'Confirmar',
    showLoaderOnConfirm: true,
    reverseButtons: true,
    cancelButtonColor: '#d33',
    allowOutsideClick: false,
    preConfirm: () => {
      return this.save();
    }
  }).then((result) => {
    if (result.isConfirmed) {
    }

    this.resetClickCount();
  });
}

  onObservationChange(value: string): void {
    console.log('Observação alterada:', value);
    this.observation = value;
  }

  private save(): void {
    this.requestPayload = {
      executeBoatWash: false,
      executeEngineTurn: false,
      boatWashes: [],
      engineTurns: []
    };
  
    if (this.washEngineTurnOption === 'WASH_ONLY') {
      this.requestPayload.executeBoatWash = true;
    }
  
    if (this.washEngineTurnOption === 'ENGINE_TURN_ONLY') {
      this.requestPayload.executeEngineTurn = true;
    }
  
    if (this.washEngineTurnOption === 'WASH_AND_ENGINE_TURN') {
      this.requestPayload.executeBoatWash = true;
      this.requestPayload.executeEngineTurn = true;
    }
  
    this.createBoatWashAndEngineTurnRegistration();
  }


  getDayOfWeek(): string {
    if (this.requestDate) {
      switch (this.requestDate.getDay()) {
        case 0:
          return 'Domingo';
        case 1:
          return 'Segunda-feira';
        case 2:
          return 'Terça-feira';
        case 3:
          return 'Quarta-feira';
        case 4:
          return 'Quinta-feira';
        case 5:
          return 'Sexta-feira';
        case 6:
          return 'Sábado';
        default:
          return '';
      }
    }
  }

  private createBoatWashFastRegistration(): void {
    const boatsWash = [];
    this.boatsWash.forEach((b) => {
      const boatWash = new BoatWash();
      boatWash.boatId = b.boatId;
      boatWash.isActive = false;
      boatWash.isSea = b.isSea;
      boatWash.observation = this.observation;
      if (this.requestDate) {
        boatWash.washDate = moment(this.requestDate).utc(true).format('YYYY-MM-DDTHH:mm');
      }
      boatsWash.push(boatWash);
    });
    this.boatWashService.createFastAutoRegistrarionList(boatsWash).subscribe(() => {
      this.dialog.close(null);
      this.toasty.success('Lavação salva com sucesso');
    }, (error) => {
      const exception = error.error.data.exception;
      this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
    }
    );
  }

  private createBoatWashAndEngineTurnRegistration(): void {
    try {
      this.boatsWash.forEach((b) => {
        const boatWash = new BoatWash();
        boatWash.boatId = b.boatId;
        boatWash.isActive = false;
        boatWash.isSea = b.isSea;
        boatWash.observation = this.observation;
        if (this.requestDate) {
          boatWash.washDate = moment(this.requestDate).utc(true).format('YYYY-MM-DDTHH:mm');
        }
        this.requestPayload.boatWashes.push(boatWash);
      });
  
       this.enginesTurn.forEach((et) => {
        const engineTurn = new EngineTurn();
        engineTurn.boatId = et.boatId;
        engineTurn.isActive = false;
        if (this.requestDate) {
          engineTurn.engineTurnDate = moment(this.requestDate).utc(true).format('YYYY-MM-DDTHH:mm');
        }
        engineTurn.isSea = et.isSea;
        engineTurn.observation = this.observation;
        this.requestPayload.engineTurns.push(engineTurn);
      });
  
      this.boatWashService.createBoatWashAndEngineTurnRegisterList(this.requestPayload).subscribe(
        () => {
          this.dialog.close(null);
          this.toasty.success('Lavação e giro de motor salvos com sucesso');
        },
        (error) => {
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
        }
      );
    } catch (error) {
      console.error('Erro ao criar registros de Lavação e Giro de Motor:', error);
      this.toasty.error('Erro ao salvar registros. Tente novamente.');
    }
  }
  

  private createEngineTurnFastRegistration(): void {
    const enginesTurn = [];
    this.enginesTurn.forEach((et) => {
      const engineTurn = new EngineTurn();
      engineTurn.boatId = et.boatId;
      engineTurn.isActive = false;
      if (this.requestDate) {
        engineTurn.engineTurnDate = moment(this.requestDate).utc(true).format('YYYY-MM-DDTHH:mm');
      }
      engineTurn.isSea = et.isSea;
      engineTurn.observation = this.observation;
      enginesTurn.push(engineTurn);
    });
    this.engineTurnService.createFastAutoRegistrarionList(enginesTurn).subscribe(
      () => {
        this.dialog.close(null);
        this.toasty.success('Giro de motor salvo com sucesso');
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }
}
