import { CollectorDTO } from "./access-controller/collectorDTO";

export class ReceivedByCollectorFilter {
    customerId: number;
    customerName: string;
    collectors: CollectorDTO[];
    accountIds: number[];
    startPaymentDate: Date;
    endPaymentDate: Date;
    size: number;
    page: number;
    order: string;

    constructor() {
        this.size = 10000;
        this.page = 0;
        this.order = 'ASC';
    }
}
