<p-table [value]="washOrEngineTurnHistory" autoLayout="true">
    <ng-template pTemplate="header">
        <tr>
            <th>Data</th>
            <th>Usuário</th>
            <th class="text-center">Observações</th>
            <th class="text-center">Origem</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-washOrEngineTurn>
        <tr>
            <td>{{washOrEngineTurn.dateHistory | date: 'dd/MM/yyyy'}}</td>
            <td>{{washOrEngineTurn.userName}}</td>
            <td class="text-center">
                <i *ngIf="washOrEngineTurn.observation && washOrEngineTurn.observation !== ''"
                    class="table-icon pi pi-comment"
                    pTooltip="{{washOrEngineTurn.observation}}"
                    tooltipPosition="top"
                    [showDelay]="0"
                    [hideDelay]="0"
                     appendTo="body"
                     style="font-size: 1.5rem; cursor: pointer;"
                     (click)="showObservationDialog(washOrEngineTurn, false)">
                </i>

                <i *ngIf="!washOrEngineTurn.observation || washOrEngineTurn.observation === ''"
                   class="table-icon pi pi-pencil"
                   pTooltip="Adicionar Observação"
                   tooltipPosition="top"
                   [showDelay]="0"
                   [hideDelay]="0"
                   appendTo="body"
                   style="font-size: 1.5rem; cursor: pointer;"
                   (click)="showObservationDialog(washOrEngineTurn, true)">
                </i>
              </td>
            <td class="text-center">
                <i *ngIf="!washOrEngineTurn.isSea" class="pi pi-clock" style="color: #002640; font-size: 25px;"></i>
                <img *ngIf="washOrEngineTurn.isSea" src="assets/images/sea.png"
                    style="height: 40px; width: 50px; padding: 7px 3px;" />
            </td>
        </tr>
    </ng-template>
</p-table>

<p-dialog 
    [(visible)]="displayDialog" 
    [style]="{width: '50vw'}" 
    [modal]="true" 
    [draggable]="false" 
    [resizable]="false">
    <ng-template pTemplate="header">
        <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
            <span>Observação</span>
            <div style="display: flex; align-items: center;">
                <!-- Botão de editar -->
                <button pButton 
        icon="pi pi-pencil" 
        class="edit" 
        style="background-color: transparent; 
               border: none; 
               color: lightgray; 
               cursor: pointer; 
               outline: none; 
               box-shadow: none;" 
               pTooltip="Editar Observação" tooltipPosition="bottom"
        (click)="editObservation()">
</button>
            </div>
        </div>
    </ng-template>

    <div *ngIf="isEditing">
        <textarea [(ngModel)]="selectedObservation" 
        class="obs-txt" 
        style="width: 100%; height: 150px; resize: none;" 
        placeholder="Digite sua observação"></textarea>
        <div style="text-align: right; margin-top: 10px;">
            <button pButton label="Salvar" 
                    icon="pi pi-save" 
                    class="easymarine primary save" 
                    (click)="saveObservation()">
            </button>
        </div>
    </div>

    <div *ngIf="!isEditing">
        <p class="obs-txt" style="white-space: pre-wrap; word-wrap: break-word;">{{selectedObservation}}</p>
    </div>
</p-dialog>




<div *ngIf="washOrEngineTurnHistory.length === 0">
    <div>
        <div>
            <strong>Nenhum registro encontrado.</strong>
        </div>
    </div>
</div>
