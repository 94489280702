import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SelectorBoatDTO } from 'src/app/models/dtos/selector-boat-DTO';
import { ProductCategoryType } from 'src/app/models/enums';
import { Measurement } from 'src/app/models/measurement';
import { BoatService } from 'src/app/services/boat.service';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-single-order-quantity',
  templateUrl: './single-order-quantity.component.html',
  styleUrls: ['./single-order-quantity.component.scss']
})
export class SingleOrderQuantityComponent implements OnInit {

  product: any;
  quantity: number;
  referenceDate: string;
  priceRanges: SelectItem[] = [];
  boats: SelectorBoatDTO[];

  constructor(
    public dialogRef: DynamicDialogRef,
    private messageUtil: MessageUtil,
    private config: DynamicDialogConfig,
    private boatService: BoatService
  ) { }

  ngOnInit(): void {
    if (this.config.data.product) {
      this.product = this.config.data.product;
      this.initQuantity();
      if (this.isFixedRangePrice()) {
        this.product.price.priceRanges.forEach(priceRange => {
          this.priceRanges.push({ label: priceRange.value, value: priceRange.value });
        });
      }
      if (this.isPerRangeLength()) {
        this.getBoats();
      }    
    }
  }

  getBoats(): void {
    this.boatService.getAllForSelector().subscribe({
      next: (data) => {        
        this.boats = data.filter((boat) => boat.active);
        let boats = this.boats.filter((boat) => {
          const hasValidLength = this.product.price.priceRanges.some(priceRange => 
            boat.commercialLength >= priceRange.startLengthRange && 
            boat.commercialLength <= priceRange.endLengthRange
          );
          return boat.active && hasValidLength;
        });
        
        boats.forEach(boat => {
          this.priceRanges.push({ label: boat.name+': R$'+this.getPriceByBoat(boat).toString(), value: this.getPriceByBoat(boat) });
        });  
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
        console.log('complete');
      }
    });
  }

  getPriceByBoat(boat: any): number {
    let priceByFeet = this.product.price.priceRanges.find(pR => 
        pR.endLengthRange >= boat.commercialLength && pR.startLengthRange <= boat.commercialLength);
    return boat.commercialLength * priceByFeet.value;
  }

  isFixedRangePrice() {
    return this.product.pricingType === 'FixedPerRange';
  }
  
  isPerRangeLength() {
    return this.product.pricingType === 'PerRangeLength';
  }

  initQuantity(): void {
    this.product.quantity = 1;
    this.updateValues(this.product, 'quantity', { value: this.product.quantity });
  }

  increaseQuantity(): void {
    this.product.quantity++;
    if (this.product.measurement === 'Liters') {
      this.updateValues(this.product, 'quantity', { value: this.product.quantity });
    }
  }

  decreaseQuantity(): void {
    if (this.product.quantity > 0) {
      this.product.quantity--;

      if (this.product.measurement === 'Liters') {
        this.updateValues(this.product, 'quantity', { value: this.product.quantity });
      }
    }
  }

  concatenateQuantity(quantity: number): void {
    if (this.product.quantity === 1) {
      this.product.quantity = 0;
    }
    this.product.quantity += quantity;
    this.updateValues(this.product, 'quantity', { value: this.product.quantity });
  }

  clear(): void {
    this.product.quantity = 0;
    this.updateValues(this.product, 'quantity', { value: this.product.quantity });
  }

  confirm(): void {
    if (!this.checkReferenceDate()){
      return;
    }
    if (this.product.price.value > 0) {
      this.dialogRef.close(this.product);
      return;
    }
    this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'Informe um valor para o produto.');
  }

  checkReferenceDate(): boolean {
    if (this.referenceDate === undefined || this.referenceDate === ''){ // nao quero valores undefined jamais
      this.referenceDate = null;
      this.product.referenceDate = null;
      return true;
    }
    if (this.referenceDate !== null){
      const parts = this.referenceDate.split('/');
      if (parts.length < 2){
        this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'Preencha corretamente os campos de referencia');
        return false;
      }
      const month = parseInt(parts[0], 10);
      const year = parseInt(parts[1], 10);
      if (month > 12 || month <= 0){
        this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'O mês de referência tem que ser entre 1 e 12');
        return false;
      }
      const now = new Date();
      let limitYear = now.getFullYear()-5;
      if (year < limitYear){
        let limitYearString: string = limitYear.toString();
        console.log(limitYearString);
        
        this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'O ano de referência não pode ser inferior a '+ limitYearString+'.');
        return false;
      }
      this.product.referenceDate = new Date(year, month - 1, 1, 0, 0, 0, 0);
      return true;
    }
  }

  updateValues(product, nameField, $event): void {
    if (product.measurement === Measurement.Liters || product.measurement === Measurement.Kilograms) {
      if (nameField === 'value') {
        product.quantity = $event.value / product.price.value;
      } else {
        product.value = $event.value * product.price.value;
      }
    }
  }

  numberDecimals(): number {
    if(this.product.productCategory.productCategoryType === ProductCategoryType.Fuel){
      return 2;
    }
    return 2;
  }
}
