<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{'RECEIVED-BY-COLLECTOR' | translate}}</span>
        <div class="header-buttons">
            
            <button class="fab-button primary" (click)="exportTable()" label="Exportar valores"
                pTooltip="Exportar Valores" tooltipPosition="left">
                <i class="pi pi-file"></i>
            </button>

            <button class="fab-button primary" appFiltersButton pTooltip="Exibir/Ocultar Filtros" tooltipPosition="left">
                <i class="pi pi-filter"></i>
            </button>
        </div>
    </p-header>

    <form class="filters" (submit)="getPaymentsByFilter()" appFilters>
        <div class="filter-row">
            <div class="form-control">
                <label>
                    {{ "GENERAL-SEARCH" | translate }}
                  </label>
                  <span class="p-input-icon-right">
                    <input
                      pInputText   
                      type="text"
                      class="new-input"
                      [(ngModel)]="globalFilter"
                      (input)="filterGlobal()"
                      name="invoiceNumber"
                      placeholder="Nº fatura ou cliente"
                    />
                    <i class="pi pi-search"></i>
                  </span>
            </div>
            <div class="form-control">
                <label for="accountIds">{{'ACCOUNT' | translate}}</label>
                <p-multiSelect [options]="accountOptions" [(ngModel)]="receivedByCollectorFilter.accountIds" name="accountIds"
                    (onChange)="this.onChangeAccount()" appendTo="body" selectedItemsLabel="{0} itens selecionados">
                </p-multiSelect>
            </div>
            <div *ngIf="hasCompany" class="form-control">
                <label for="marinaCompanyIds">{{'COMPANY' | translate}}</label>
                <p-multiSelect [options]="collectorsOptions" [(ngModel)]="receivedByCollectorFilter.collectors" name="marinaCompanyIds"
                    (onChange)="this.onChangeCollector()" appendTo="body" selectedItemsLabel="{0} itens selecionados">
                </p-multiSelect>
            </div>
            
            <div class="form-control">
                <label for="rangeIssueDate">{{
                  "EMISSION-DATE-FROM-TO" | translate
                }}</label>
                <p-calendar
                  dateFormat="dd/mm/yy"
                  name="rangePaymentDates"
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  appendTo="body"
                  [yearRange]="'2000:2055'"
                  rPCalendarMask
                  selectionMode="range"
                  [(ngModel)]="this.rangePaymentDates"
                  [showIcon]="true"
                  (onClickOutside)="this.onChangePaymentDate()"
                  (onSelect)="this.onChangePaymentDate()"
                  i18n
                >
                </p-calendar>
              </div>

            <div class="buttons">
                <div class="form-button">
                    <button (click)="clear()" class="easymarine danger" type="button">{{ 'CLEAR' | translate }}</button>
                </div>
                <div class="form-button">
                    <button class="easymarine primary" type="submit">{{ 'FIND' | translate }}</button>
                </div>
            </div>
        </div>
    </form>
    <div class="table">
        <p-table #tt [value]="displayPayments" [paginator]="true" [rows]="receivedByCollectorFilter.size" [lazy]="true" csvSeparator=";"
           styleClass="easy-table" [alwaysShowPaginator]="false" autoLayout="true">
            <ng-template pTemplate="caption">
                <div class="p-caption">
                    <div class="flex flex-column align-items-end justify-content-end">
                      <span class="total-registers">{{ 'TOTAL-REGISTERS' | translate }}:
                          {{displayPayments.length}}
                      </span>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th *ngIf="hasCompany" class="text-center" [pSortableColumn]="'collectorName'">
                        {{ 'COMPANY' | translate }}
                        <p-sortIcon [field]="'collectorName'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'accountName'">
                        {{ 'ACCOUNT' | translate }}
                        <p-sortIcon [field]="'accountName'"></p-sortIcon>
                    </th>
                    <th class="text-center">
                        {{ 'TITLE' | translate }}
                    </th>
                    <th class="text-center">
                        {{ 'CUSTOMER' | translate }}
                    </th>
                    <th class="text-center" [pSortableColumn]="'invoiceNumber'">
                        Fatura
                        <p-sortIcon [field]="'invoiceNumber'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'dueDate'">
                        Vencimento
                        <p-sortIcon [field]="'dueDate'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'competenceDate'">
                        Pagamento
                        <p-sortIcon [field]="'competenceDate'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'value'">
                        Total
                        <p-sortIcon [field]="'value'"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-payment let-index>
                <tr>
                  <td *ngIf="hasCompany" class="text-center">{{ payment.collectorName }}</td>
                  <td class="text-center">{{ payment.accountName }}</td>
                  <td class="text-center">{{ payment.titleId }}</td>
                  <td class="text-center">{{ payment.titleName }}</td>
                  <td class="text-center">{{ payment.invoiceNumber }}</td>
                  <td class="text-center">{{ payment.dueDate | date: 'dd/MM/yyyy' }}</td>
                  <td class="text-center">{{ payment.paymentDate | date: 'dd/MM/yyyy' }}</td>
                  <td class="text-center">{{ payment.value | currency: 'BRL':'symbol':'1.2-2' }}</td>
                </tr>
              </ng-template>
              
        </p-table>
    </div>
</p-fieldset>
