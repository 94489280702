<p-fieldset>
  <p-header class="article-title">
    <span class="article-title">{{ "SAILOR-LIST" | translate }}</span>
    <div class="header-buttons">
      <button class="primary fab-button" (click)="openSailorFormDialog()" pTooltip="Adicionar novo marinheiro"
        tooltipPosition="bottom">
        <i class="pi pi-plus" style="font-size: 21px"></i>
      </button>
      <button class="fab-button primary" (click)="exportTable()" label="Exportar valores"
                pTooltip="Exportar Valores" tooltipPosition="left">
                <i class="pi pi-file"></i>
        </button>
      <button class="fab-button primary" appFiltersButton pTooltip="Exibir/Ocultar Filtros" tooltipPosition="left">
        <i class="pi pi-filter"></i>
      </button>
    </div>
  </p-header>

  <form class="filters" (submit)="getSailors()" appFilters>
    <div class="filter-row">
      <div class="form-control">
        <label for="name">{{ "NAME" | translate }}</label>
        <input pInputText type="text" name="name" [(ngModel)]="sailorFilter.name" />
      </div>
      <div class="form-control">
        <label>{{ "CUSTOMER" | translate }}</label>
        <p-dropdown placeholder="Selecione..." [disabled]="false" required [filter]="true" [options]="customers"
          [(ngModel)]="sailorFilter.client" appendTo="body" name="selectedCustomerId">
        </p-dropdown>
      </div>
      <div class="form-control">
        <label>{{ "BOAT" | translate }}</label>
        <p-dropdown placeholder="Selecione..." [disabled]="false" required [filter]="true" [options]="boats"
          [(ngModel)]="sailorFilter.boat" appendTo="body" name="selectedBoatId">
        </p-dropdown>
      </div>

      <div class="form-control">
        <label for="federalId">{{ "CPF" | translate }}</label>
        <input pInputText type="text" name="federalId" [(ngModel)]="sailorFilter.federalId" />
      </div>

      <div class="form-control">
        <label for="status">{{ "STATUS" | translate }}</label>
        <p-dropdown [(ngModel)]="sailorFilter.status" name="status" appendTo="body" [options]="[
            { label: 'Todos', value: -1 },
            { label: 'Inativo', value: 0 },
            { label: 'Ativo', value: 1 },
            { label: 'Aguardando documentação', value: 2 },
            { label: 'Em análise', value: 3 },
            { label: 'Cadastro na Marina', value: 4 }
          ]">
        </p-dropdown>
      </div>

      <div class="form-control">
        <label for="email">{{ "EMAIL" | translate }}</label>
        <input pInputText type="text" name="email" [(ngModel)]="sailorFilter.email" />
      </div>

      <div class="form-control">
        <label for="sailorType">{{ "SAILOR-TYPES" | translate }}</label>
        <p-dropdown [(ngModel)]="sailorFilter.sailorType" name="sailorType" appendTo="body" [options]="[
            { label: 'Todos', value: '' },
            { label: 'Marinheiro de Solo', value: 'DIRT' },
            { label: 'Marinheiro de Água', value: 'WATER' },
            { label: 'Auxiliar de Marinheiro', value: 'AUXILIARY_SAILOR' },
            { label: 'Marinheiro particular', value: 'PRIVATE_SAILOR' },
            { label: 'Marinheiro Auxiliar de Convés', value: 'AUXILIARY_DECK_SAILOR' }
          ]">
        </p-dropdown>
      </div>

      <div class="form-control">
        <label for="active">{{ "Credenciado" | translate }}</label>
        <p-dropdown [(ngModel)]="sailorFilter.active" name="active" appendTo="body" [options]="[
            { label: 'Ambos', value: '' },
            { label: 'Ativo', value: true },
            { label: 'Inativo', value: false }
          ]">
        </p-dropdown>
      </div>
      <div class="buttons">
        <div class="form-button">
          <button (click)="clear()" class="easymarine danger" type="button">
            {{ "CLEAR" | translate }}
          </button>
        </div>
        <div class="form-button">
          <button class="easymarine primary" type="submit">
            {{ "FIND" | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <div class="table">
    <p-table #sailorDataTable [value]="sailorsTable" (onLazyLoad)="onChangePage($event)" [paginator]="true"
      [rows]="numberOfRows" [lazy]="true" [rowsPerPageOptions]="[10,20,30,50,100]" [rows]="paginationFilter.size"
      dataKey="id" sortMode="multiple" csvSeparator=";" [columns]="cols" [totalRecords]="totalRecords"
      styleClass="easy-table" [alwaysShowPaginator]="false" autoLayout="true"
      [globalFilterFields]="['B.status', 'B.federal_id', 'B.name', 'C.email', 'B.phone', 'B.types_sailor' ]">
      <ng-template pTemplate="caption">
        <!-- <div class="export-buttons">
          <button class="easymarine btn-csv-xls" label="Exportar Tabela" (click)="exportTable()"
            pTooltip="A exportação será de acordo com os filtros selecionados" tooltipPosition="top">
            <span>Exportar CSV</span>
            <i class="pi pi-file-excel"></i>
          </button>
        </div> -->
        <span style="font-weight: bold; margin-top: 5px">
          {{ "TOTAL-REGISTERS" | translate }}: {{ sailorsTable?.length }}
          {{ "OF" | translate }} {{ sailorDataTable?.totalRecords }}
        </span>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="'B.status'">
            {{ "App" | translate }}
            <p-sortIcon [field]="'B.status'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'B.federal_id'">
            {{ "FEDERAL-ID.GENERAL" | translate }}
            <p-sortIcon [field]="'B.federal_id'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'B.name'">
            {{ "NAME" | translate }}
            <p-sortIcon [field]="'B.name'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'C.email'">
            {{ "PERSONAL-EMAIL" | translate }}
            <p-sortIcon [field]="'C.email'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'phone'">
            {{ "CELLPHONE" | translate }}
            <p-sortIcon [field]="'B.phone'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'B.types_sailor'">
            {{ "TYPE" | translate }}
            <p-sortIcon [field]="'B.types_sailor'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'B.creation_origin'">
            {{ "ORIGIN" | translate }}
            <p-sortIcon [field]="'B.creation_origin'"></p-sortIcon>
          </th>
          <th class="text-center">{{ "BOND" | translate }}</th>
          <th class="text-center">{{ "ACCREDITED" | translate }}</th>
          <th class="text-center">{{ "ACTIONS" | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-sailor>
        <tr>
          <td style="text-align: center;">
            <span style="display: flex; align-items: center; justify-content: center;">
              <img src="assets/images/mobile_red.png" pTooltip="Inativo" *ngIf="sailor.status==0"
                style="height: 30px; width: auto;">
              <img src="assets/images/mobile_green.png" pTooltip="Ativo" *ngIf="sailor.status==1"
                style="height: 30px; width: auto;">
              <img src="assets/images/mobile_yellow.png" pTooltip="Aguardando documentação" *ngIf="sailor.status==2"
                style="height: 30px; width: auto;">
              <img src="assets/images/mobile_blue.png" pTooltip="Em análise" *ngIf="sailor.status==3"
                style="height: 30px; width: auto;">
            </span>
          </td>
          <td>{{ sailor.federalId }}</td>
          <td>{{ sailor.name }}</td>
          <td>{{ sailor.email }}</td>
          <td class="white-space-nowrap">{{ getMaskedPhone(sailor.phone) }}</td>
          <td style="text-align: center;">{{ getTextSailor(sailor.typesSailor) }}</td>
          <td style="text-align: center;">{{sailor.creationOrigin}}</td>
          <td style="text-align: center;">
            <div *ngIf="sailor.boatCustomerTooltipDTOList?.length > 0" pTooltip="{{
                getToolTipValue(sailor.boatCustomerTooltipDTOList)
              }}">
              {{sailor.boatCustomerTooltipDTOList?.length}}
            </div>
          </td>
          <td class="text-center">
            <span *ngIf="sailor.active" class="badge badge-pill success cursor-pointer"
              (click)="validateDeactivateSailor(sailor)" style="cursor: default;">
              {{ 'ACTIVE' | translate }}</span>
            <span *ngIf="!sailor.active" class="badge badge-pill danger cursor-pointer"
              (click)="activateSailor(sailor)">{{ 'INACTIVE' | translate }}</span>
          <td>
            <p-menu appendTo="body" #menu [model]="menuItems" [popup]="true" [showTransitionOptions]="'150ms'"
              [hideTransitionOptions]="'150ms'"></p-menu>
            <button (click)="menu.toggle($event); changeSelectedItem(sailor)" pButton type="button"
              icon="pi pi-ellipsis-v" class="p-button-text p-button-rounded p-button-lg"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-fieldset>
