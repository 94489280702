import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  Boat,
  Shipyard,
  Country,
  State,
  City,
  Customer,
  ShipyardModel,
  ServiceBoat,
  OrderItem,
  CustomerBoat,
  Marina,
  SlingConfig,
  User,
  Product,
  Vacancy,
  AccessoryBoat,
  Accessory,
} from 'src/app/models';
import {
  AccessoryType,
  SlingStatus,
  ShipType,
  Bow,
} from 'src/app/models/enums';
import { InsurerType } from 'src/app/models/enums/insurer-type';
import { NavigationArea } from 'src/app/models/navigation-area';
import { PrincingType } from 'src/app/models/pricing-type';
import { ProductCategoryType } from 'src/app/models/product-category-type';
import { AccessoryService } from 'src/app/services/accessory.service';
import { BoatWashService } from 'src/app/services/boat-wash.service';
import { BoatService } from 'src/app/services/boat.service';
import { CustomerService } from 'src/app/services/customer.service';
import { EngineTurnService } from 'src/app/services/engine-turn.service';
import { InsurerService } from 'src/app/services/insurer.service';
import { LocationService } from 'src/app/services/location.service';
import { MarinaOrderService } from 'src/app/services/marina-order.service';
import { ProductService } from 'src/app/services/product.service';
import { ShipyardService } from 'src/app/services/shipyard.service';
import { SlingService } from 'src/app/services/sling.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { VacancyService } from 'src/app/services/vacancy.service';
import { ValidatorsUtil } from 'src/app/services/validators.util';
import { BrStates } from 'src/app/utils/location/br_states';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import Swal from 'sweetalert2';
import { FuelService } from './fuel.service';
import moment from 'moment';
import { FormatUtil } from 'src/app/utils/format.util';
import { BoatAccessoriesComponent } from '../boat-accessories/boat-accessories.component';
import { BoatContractComponent } from '../boat-contract/boat-contract.component';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import { FormUtil } from 'src/app/utils/form.util';
import { CustomerType } from 'src/app/models/customer-type';
import { DocumentContractView } from 'src/app/models/dtos/document-contract-view';
import { ContractDocumentService } from 'src/app/services/contract-document.service';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { DocumentStatus } from 'src/app/models/enums/document-status';
import { VerifyContractsDialogComponent } from 'src/app/components/contracts/verify-contracts-dialog/verify-contracts-dialog.component';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { BoatHistoryDialogComponent } from './boat-history-dialog/boat-history-dialog.component';
import { ProductType } from '../../../../models/enums';
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';
import { MovementLocationType } from 'src/app/models/enums/movements/movement-location-type';
import { MovementLocation } from 'src/app/models/movement/movement-location';
import { MovementLocationService } from 'src/app/services/movements/movement-location.service';
import { getVacancyByMovementLocationValidator } from 'src/app/validators/boat/boat-form';
import { SelectItem } from 'primeng/api/selectitem';
import { BoatProfile } from '../../../../models/boat-profile';
import { ReajustmentHistoricComponent } from 'src/app/pages/stock/service-readjustment/list-price-free/reajustment-historic/reajustment-historic/reajustment-historic.component';
import { ServiceBoatReadjustmentDTO } from 'src/app/models/dtos/service-boat-readjustmentDTO';
import { MovementPlanService } from 'src/app/services/movements/movement-plan.service';
import { ShipyardNameDTO } from 'src/app/models/dtos/shipyard/shipyardNameDTO';
import { MovementGroup } from '../../../../models/movement/movement-group';
import { Fuel } from '../../../../models/fuel';
import { Observable, zip } from 'rxjs';
import { FranchiseGroupService } from 'src/app/services/franchise-group.service';
import { Router } from '@angular/router';
import { InsurerDpemType } from 'src/app/models/enums/insure-dpem-type';
import { DPEM } from 'src/app/models/dpem';

@Component({
  selector: 'app-boat-form',
  templateUrl: './boat-form.component.html',
  styleUrls: ['./boat-form.component.scss'],
})
export class BoatFormComponent implements OnInit {
  @ViewChild('upload', { static: false }) upload: FileUpload;

  blockChangeType = false;
  sailorList: UntypedFormGroup;
  engineList = [];
  boat: Boat = new Boat();
  boatForm: FormGroup;
  marinaOrders = [];
  public filterMarinaOrder: any = { origim: '', hasInvoice: 'false' };
  totalOrders = 0;
  totalValueOrders = 0;
  shipyards: { label: string; value: any }[];
  shipyardTypeSelected = false;
  loading = false;
  errorMessage: string;
  vacancies: { label: string; value: Vacancy }[];
  vacanciesByMovementLocations: { label: string; value: Vacancy }[];
  movementLocations: { label: string; value: MovementLocation }[];
  countries: any[];
  country: Country;
  states: any[];
  state: State;
  cities: any[];
  city: City;
  customers: Customer[];
  owners: { label: string; value: any }[];
  associateds: { label: string; value: any }[];
  filteredAssociateds: { label: string; value: any }[];
  services: any[];
  insurers: any[];
  shipyardModels: { label: string; value: any }[];
  marinaUsers: any[] = [];
  sumQuota: any = 0;
  invalidQuota = false;
  fuels: { label: string; value: any }[];
  engineTurnHistory: any[];
  boatWashHistory: any[];
  slingsHistory: any[];
  histories: any[];
  filteredServices: any;
  uploadDocumentationFileInProgress = false;
  uploadDocumentationDpemFileInProgress = false;
  shipTypeOptions: { label: string; value: any }[];
  navigationAreaOptions = [
    { label: 'Áreas abrigadas', value: NavigationArea.ShelteredArea },
    { label: 'Cabotagem', value: NavigationArea.Cabotage },
    { label: 'Mar aberto', value: NavigationArea.OpenSea },
    { label: 'Navegação oceânica', value: NavigationArea.OceanicNavigation },
    { label: 'Costeira', value: NavigationArea.Coastal },
  ];
  insurerTypes = [
    { label: 'Não verificado', value: InsurerType.NOT_VERIFIED },
    { label: 'Segurado', value: InsurerType.INSURED },
    { label: 'Não segurado', value: InsurerType.UNINSURED },
  ];
  accessoriesList: Accessory[] = [];
  optionalAccessoriesList: Accessory[] = [];
  pt = {
    firstDayOfWeek: 0,
    dayNames: [
      'Domingo',
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
    ],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    monthNamesShort: [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ],
    today: 'Hoje',
    clear: 'Limpar',
  };

  lastHundredYears: { label: string; value: any }[];

  selectTab = 0;
  documentContracts: DocumentContractView[] = [];
  documentContractsHistory: DocumentContractView[] = [];
  franchiseGroups = [];
  buttonClass = 'attachment';
  buttonClass1 = 'attachment';

  servicesBoatHistory: ServiceBoat[] = [];
  slingConfig: SlingConfig;

  isMovimentOperation: boolean;
  isWorkshop = false;
  isFranchise = false;

  movementGroupOptions: any[] = [];

  activeBoat = true;
  isDiscountPercentage: boolean = false;

  operatorSelected: User = null;
  currentMarina: Marina = null;

  boatProfiles: Array<SelectItem>;
  boatProfileSelected = BoatProfile.Recurrent;
  isEdit = false;
  constructor(
    public dialogRef: DynamicDialogRef,
    private customerService: CustomerService,
    private locationService: LocationService,
    private shipyardService: ShipyardService,
    private productService: ProductService,
    private boatService: BoatService,
    private insurerService: InsurerService,
    private vacancyService: VacancyService,
    private validatorsUtil: ValidatorsUtil,
    private messageUtil: MessageUtil,
    private dialog: DialogService,
    private spinner: NgxSpinnerService,
    private fuelService: FuelService,
    private marinaService: MarinaOrderService,
    private accessoryService: AccessoryService,
    private userService: UserService,
    private toasty: ToastService,
    private slingService: SlingService,
    private boatWashService: BoatWashService,
    private engineTurnService: EngineTurnService,
    private config: DynamicDialogConfig,
    private contractDocumentService: ContractDocumentService,
    private slingConfigService: SlingConfigService,
    private operationalConfigService: OperationalConfigService,
    private readonly movementLocationService: MovementLocationService,
    private movementPlanService: MovementPlanService,
    private franchiseGroupService: FranchiseGroupService,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.spinner.show();
    this.isEdit = this.config?.data?.id !== null;
    this.sailorList = new UntypedFormGroup({});
    this.currentMarina = StorageUtil.getMarina();

    this.shipTypeOptions = [
      {
        label: this.messageUtil.translateKey('MOTORBOAT'),
        value: ShipType.Motorboat,
      },
      {
        label: this.messageUtil.translateKey('SAILBOAT'),
        value: ShipType.Sailboat,
      },
      {
        label: this.messageUtil.translateKey('INFLATABLE-BOAT'),
        value: ShipType.InflatableBoat,
      },
      {
        label: this.messageUtil.translateKey('JETSKI'),
        value: ShipType.JetSki,
      },
      {
        label: this.messageUtil.translateKey('YATCH'),
        value: ShipType.Yatch,
      },
      {
        label: this.messageUtil.translateKey('CATAMARAN'),
        value: ShipType.Catamaran,
      },
      {
        label: this.messageUtil.translateKey('SCHOONER'),
        value: ShipType.Schooner,
      },
      {
        label: this.messageUtil.translateKey('TRAWLER'),
        value: ShipType.Trawler,
      },
      {
        label: this.messageUtil.translateKey('KAYAK'),
        value: ShipType.Kayak,
      },
      {
        label: this.messageUtil.translateKey('CANOE'),
        value: ShipType.Canoe,
      },
    ];
    if (this.router.url.includes('new-budget-boat')) {
      this.shipTypeOptions = [
        {
          label: 'Lancha',
          value: ShipType.Motorboat,
        },
        {
          label: 'Veleiro',
          value: ShipType.Sailboat,
        },
        {
          label: 'Bote inflável',
          value: ShipType.InflatableBoat,
        },
        {
          label: 'Jet ski',
          value: ShipType.JetSki,
        },
        {
          label: 'Iate',
          value: ShipType.Yatch,
        },
        {
          label: 'Catamarã',
          value: ShipType.Catamaran,
        },
        {
          label: 'Escuna',
          value: ShipType.Schooner,
        },
        {
          label: 'Trawler',
          value: ShipType.Trawler,
        },
        {
          label: 'Caiaque',
          value: ShipType.Kayak,
        },
        {
          label: 'Canoa',
          value: ShipType.Canoe,
        },
      ];
    }

    await Promise.all([
      this.findSlingConfig(),
      this.getOperationConfig(),
      this.getWorkshopConfig(),
      this.getFranchise(),
      this.getFranchiseGroups(),
    ]);
    this.getBoatProfiles();
    if (this.config?.data?.movementGroupOptions?.length !== 0) {
      this.movementGroupOptions = this.config?.data?.movementGroupOptions;
    }
    await this.loadVacancies();
    this.startForm();
    if (this.isEdit) {
      const boatId = this.config.data.id;
      this.getBoat(boatId).subscribe({
        next: () => {
          this.getServiceBoatHistory(boatId);
          this.checkIfBoatServicesHasAnyUseDicount();
          this.setBoatForm();
          if (this.isMovimentOperation) {
            this.addWithoutVacancyOption(this.vacancies);
            this.setVacancyByMovimentOperational(
              this.boatForm.value.movementLocation
            );
          }
          this.loadStates();
          this.getServiceBoatHistory(boatId);
          if (this.boat?.boatServices[0]) {
            this.isDiscountPercentage =
              this.boat.boatServices[0].useDiscountOnPercentage;
          } else {
            this.isDiscountPercentage = false;
          }
          this.receiveEngine(this.boat.boatEngines);
          this.loadAccessories().subscribe()
          this.spinner.hide();
        },
        error: (error) => {
          console.log(error);
          this.spinner.hide();
        },
      });
    } else {
      this.boatForm
        .get('marineRunMyEnginer')
        .patchValue(this.slingConfig.marineEngineTurnRun);
      this.boatForm
        .get('marineWashMyBoat')
        .patchValue(this.slingConfig.marineBoatWash);
      this.boatForm
        .get('timeBetweenWash')
        .patchValue(this.slingConfig.timeBetweenWash);
      this.boatForm
        .get('timeBetweenEngineRotation')
        .patchValue(this.slingConfig.timeBetweenEngineRotation);
      this.loadAccessories().subscribe()
      this.spinner.hide();
    }
    zip([
      this.loadShipyards(),
      this.loadFuels(),
      this.loadMovementLocations(),
      this.loadInsurers(),
      this.loadCustomers(),
      this.getMarinaUsers(),
      this.loadPeriodicServices()
    ]).subscribe();
    this.generateLastHundredYears();
  }

  sortBoatServices() {
    this.boat.boatServices.sort((a, b) => {
      if (
        a.service.name
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '') <
        b.service.name
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
      ) {
        return -1;
      }
      if (
        a.service.name
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '') >
        b.service.name
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
      ) {
        return 1;
      }
      return 0;
    });
  }
  private checkIfBoatServicesHasAnyUseDicount() {
    const hasUseDiscount = this.boat?.boatServices.some(
      (obj) => obj.useDiscountOnPercentage === true
    );
    if (
      hasUseDiscount !== undefined &&
      hasUseDiscount !== null &&
      hasUseDiscount
    ) {
      this.isDiscountPercentage = true;
    } else {
      this.isDiscountPercentage = false;
    }
  }

  async getFranchiseGroups() {
    this.franchiseGroupService.findAll().subscribe({
      next: (data) => {
        this.franchiseGroups = [];
        this.franchiseGroups.push({
          id: null,
          name: 'Selecione um grupo de Franquia',
        });
        for (let i = 0; i < data.length; i++) {
          this.franchiseGroups.push({ id: data[i].id, name: data[i].name });
        }
      },
    });
 }

  async findSlingConfig(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      this.slingConfigService.getSlingConfigToday().subscribe(
        async (slingConfig: SlingConfig) => {
          this.slingConfig = slingConfig;
          if (!slingConfig.marina?.acceptInflatable) {
            const i = this.shipTypeOptions.findIndex((o) => {
              return o.value == ShipType.InflatableBoat;
            });
            console.log(i, this.shipTypeOptions);
            this.shipTypeOptions.splice(i, 1);
          }

          if (!slingConfig?.marina?.acceptJetSki) {
            const i = this.shipTypeOptions.findIndex((o) => {
              return o.value == ShipType.JetSki;
            });

            this.shipTypeOptions.splice(i, 1);
          }

          if (!slingConfig.marina?.acceptMotorBoat) {
            const i = this.shipTypeOptions.findIndex((o) => {
              return o.value == ShipType.Motorboat;
            });

            this.shipTypeOptions.splice(i, 1);
          }

          if (!slingConfig.marina?.acceptSailBoat) {
            const i = this.shipTypeOptions.findIndex((o) => {
              return o.value == ShipType.Sailboat;
            });

            this.shipTypeOptions.splice(i, 1);
          }

          resolve();
        },
        async (error) => {
          console.log(error);
        }
      );
    });
  }

  async getOperationConfig() {
    this.isMovimentOperation =
      await this.operationalConfigService.isMovements();
  }

  async getWorkshopConfig() {
    this.isWorkshop = await this.operationalConfigService.isWorkshop();
  }

    // daniel
  async getFranchise() {
    this.isFranchise = await this.operationalConfigService.isFranchise();
  }

  getBoatProfiles() {
    this.boatProfiles = [
      { label: 'Recorrente', value: BoatProfile.Recurrent },
      { label: 'Eventual', value: BoatProfile.Eventual },
    ];
    if (this.isWorkshop) {
      this.boatProfiles.push({
        label: 'Oficina',
        value: BoatProfile.Workshop,
        disabled: false,
      });
    }
  }

  async getMovementPlanIncurse(boatId: number) {
    return new Promise<void>(async (resolve) => {
      this.movementPlanService
        .getMovementInCurse(boatId)
        .subscribe(async (movementPlan) => {
          this.blockChangeType = movementPlan;
        });
    });
  }

  getBoat(id: number): Observable<void> {
    return new Observable<void>((observer) => {
      this.boatService.getById(id).subscribe({
        next: (boat) => {
          this.transformDatesServiceBoat(boat.boatServices);
          this.boat = boat;
          this.sortBoatServices();
          this.verifyContract();
          this.boatForm.get('active').setValue(this.boat.active);
          this.boatForm.get('wetVacancy').setValue(this.boat.wetVacancy);
          this.activeBoat = this.boat.active;
          this.getMovementPlanIncurse(this.boat.id);
          this.findMarinaOrders();
          if (!this.isMovimentOperation) {
            this.getHistoricalData(this.config.data.id);
          }
          if (this.config.data.tabIndex) {
            this.selectTab = this.config.data.tabIndex;
          }
          if (boat.tie) {
            this.boatForm.get('tie').enable();
            if (boat.tie.expiration) {
              boat.tie.expiration = moment(boat.tie.expiration)
                .utc(true)
                .toDate();
            }
          }
          if (boat.dpem) {
            this.boatForm.get('dpem').enable();
            if (boat.dpem.policyDpemExpirationDate) {
              boat.dpem.policyDpemExpirationDate = moment(boat.dpem.policyDpemExpirationDate)
                .utc(true)
                .toDate();
            }
          }


          if (boat.insurerType === InsurerType.INSURED) {
            this.boatForm.get('boatInsurer').enable();
            this.boat.boatInsurer
              ? (this.boat.boatInsurer.policyExpirationDate = moment(
                  this.boat.boatInsurer.policyExpirationDate,
                  'YYYY-MM-DD'
                ).toDate())
              : undefined;
          }

            this.boat.dpem?.policyDpemExpirationDate
              ? (this.boat.dpem.policyDpemExpirationDate = moment(
                  this.boat.dpem.policyDpemExpirationDate,
                  'YYYY-MM-DD'
                ).toDate())
              : undefined;

          this.loadShipyardModels(
            this.boat.shipyardModel.shipyard.id
          ).subscribe({
            next: () => observer.next(),
            error: (e) => observer.error(e),
          });
        },
        error: (error) => {
          observer.error(error);
        },
      });
    });
  }

  getServiceBoatHistory(boatId: number): void {
    this.boatService.getServicesBoatHistory(boatId).subscribe({
      next: (data) => {
        this.servicesBoatHistory = data;
      },
      error: () => {
        this.servicesBoatHistory = [];
      },
    });
  }

  stopNullValue(event: any) {
    if (
      event.value === '' ||
      event.value === null ||
      event.value === undefined
    ) {
      event.setValue(0);
    }
  }

  transformDatesServiceBoat(serviceBoat: ServiceBoat[]): void {
    serviceBoat.forEach((bs) => {
      if (bs.contractStartDate != null) {
        bs.contractStartDate = moment(bs.contractStartDate).utc(true).toDate();
      }
      if (bs.contractEndDate != null) {
        bs.contractEndDate = moment(bs.contractEndDate).utc(true).toDate();
      }
    });
  }

  selectShipyard($event): void {
    this.boatForm.controls.shipyardModel.get('id').enable();
    this.shipyardModels = [];
    this.loadShipyardModels($event.value.id).subscribe();
  }

  shipyardType(shipType) {
    this.shipyardTypeSelected = true;
    this.shipyardModels = new Array();
    this.shipyardService.getShipyardByType(shipType.value).subscribe({
      next: (shipyardModelList: ShipyardNameDTO[]) => {
        this.shipyards = shipyardModelList.map((shipyard) => ({
          label: shipyard.name,
          value: shipyard,
        }));
      },
      error: (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
    });
  }

  updateServicesDiscount() {
    const servicesFormArray = this.boatForm.get('boatServices') as FormArray;
    if (this.isDiscountPercentage) {
      servicesFormArray.controls.forEach((service: FormControl) => {
        service.get('discount').patchValue(0);
        service.get('discount').disable();
        service.get('dueDateDiscount').patchValue(0);
        service.get('dueDateDiscount').disable();

        service.get('discountPercentage').patchValue(0);
        service.get('discountPercentage').enable();
        service.get('dueDateDiscountPercentage').patchValue(0);
        service.get('dueDateDiscountPercentage').enable();
        service.get('useDiscountOnPercentage').patchValue(true);
        service.get('useDiscountOnPercentage').enable();
      });
    } else {
      servicesFormArray.controls.forEach((service: FormControl) => {
        service.get('discount').patchValue(0);
        service.get('discount').enable();
        service.get('dueDateDiscount').patchValue(0);
        service.get('dueDateDiscount').enable();

        service.get('discountPercentage').patchValue(0);
        service.get('discountPercentage').disable();
        service.get('dueDateDiscountPercentage').patchValue(0);
        service.get('dueDateDiscountPercentage').disable();
        service.get('useDiscountOnPercentage').patchValue(false);
        service.get('useDiscountOnPercentage').disable();
      });
    }
  }

  calculateDiscountPercentage(e) {
    e.controls.discount.patchValue(
      (e.controls.discountPercentage.value / 100) * e.controls.value.value
    );
  }

  calculateDueDateDiscountPercentage(e) {
    e.controls.dueDateDiscount.patchValue(
      (e.controls.dueDateDiscountPercentage.value / 100) *
        e.controls.value.value
    );
  }

  onInsurerType(): void {
    if (this.boatForm.get('insurerType').value === InsurerType.INSURED) {
      this.boatForm.get('boatInsurer').enable();
      this.boatForm.get('boatInsurer').reset();
    } else {
      this.boatForm.get('boatInsurer').disable();
      this.boatForm.get('boatInsurer').reset();
    }
  }

  private isMovementLocationActiveToFixedVacancy(
    movementLocation: MovementLocation
  ): boolean {
    if (!movementLocation) {
      return false;
    }
    return (
      movementLocation.active &&
      (movementLocation?.type === MovementLocationType.WET_VACANCY ||
        movementLocation?.type === MovementLocationType.DRY_VACANCY)
    );
  }

  setVacancyByMovimentOperational(location: MovementLocation) {
    if (location && this.vacancies) {
      const vacancyByLocation = this.vacancies.filter(
        ({ value }) => value && value.movementLocation.id === location.id
      );
      if (vacancyByLocation.length > 0 && !location.vacancyControl) {
        this.addWithoutVacancyOption(vacancyByLocation);
      }
      this.vacanciesByMovementLocations = vacancyByLocation;
      const currentVacancy: Vacancy = this.boatForm.get('vacancy').value;
      if (
        currentVacancy === null &&
        this.vacanciesByMovementLocations.length > 0
      ) {
        this.boatForm.controls.vacancy.setValue(
          this.vacanciesByMovementLocations[0].value
        );
      }
      if (
        currentVacancy &&
        currentVacancy?.movementLocation?.id !== location.id
      ) {
        this.boatForm.controls.vacancy.setValue(null);
      }
      this.addSelectVacancyOption(vacancyByLocation);
    } else {
      const vacanciesOptions = [];
      this.addCurrentVacancyOption(vacanciesOptions);
      this.vacanciesByMovementLocations = vacanciesOptions;
    }
  }
  addWithoutVacancyOption(options: { label: string; value: Vacancy }[]) {
    options.unshift({ label: 'Sem vaga', value: null });
  }

  addSelectVacancyOption(options: { label: string; value: Vacancy }[]) {
    options.unshift({ label: 'Selecione', value: null });
  }

  addCurrentVacancyOption(options: { label: string; value: Vacancy }[]) {
    const { vacancy, movementLocation } = this.boatForm.value;
    if (vacancy && !movementLocation) {
      options.unshift({ label: vacancy.code, value: vacancy });
    }
  }

  async loadVacancies(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      if (this.isMovimentOperation) {
        this.vacancyService.getAllVacanciesFree().subscribe({
          next: (data) => {
            this.vacancies = data
              .filter((vacancy) =>
                this.isMovementLocationActiveToFixedVacancy(
                  vacancy.movementLocation
                )
              )
              .map((d) => ({ label: d.code, value: d }));
            if (
              this.boat.vacancy &&
              this.boat.vacancy.id &&
              !this.vacancies.find((v) => v.label === this.boat.vacancy.code)
            ) {
              this.vacancies.push({
                label: this.boat.vacancy.code,
                value: this.boat.vacancy,
              });
            }
            resolve();
          },
          error: (err) => {
            this.vacancies = null;
            resolve(err);
          },
        });
      } else {
        this.vacancyService.getAllToBoats().subscribe({
          next: (data) => {
            this.vacancies = data.map((d) => ({ label: d.code, value: d }));
            if (
              this.boat.vacancy &&
              this.boat.vacancy.id &&
              !this.vacancies.find((v) => v.label === this.boat.vacancy.code)
            ) {
              this.vacancies.push({
                label: this.boat.vacancy.code,
                value: this.boat.vacancy,
              });
            }
            this.vacancies.push({
              label: 'Sem vaga',
              value: null,
            });
            resolve();
          },
          error: (err) => {
            this.vacancies = null;
            resolve(err);
          },
        });
      }
    });
  }

  private loadMovementLocations(): Observable<void> {
    return new Observable((observer) => {
      this.movementLocationService.getAll().subscribe({
        next: (data) => {
          let locations: { label: string; value: any }[];
          locations = data
            .filter((location) =>
              this.isMovementLocationActiveToFixedVacancy(location)
            )
            .map((location: MovementLocation) => ({
              label: location.name,
              value: location,
            }));
          locations.unshift({ label: 'Sem local', value: null });
          this.movementLocations = locations;
          observer.next();
        },
        error: (err) => {
          this.movementLocations = null;
          observer.error(err);
        },
      });
    });
  }

  checkFederalIdField(index: string): void {
    if (this.validateFederalId(index)) {
      this.federalIdInUse(index);
    }
  }

  federalIdInUse(index: string): void {
    if (
      !(this.boatForm.get('associateds') as UntypedFormArray).controls[
        index
      ].get('id').value
    ) {
      this.customerService
        .getByFederalId(
          (this.boatForm.get('associateds') as UntypedFormArray).controls[
            index
          ].get('federalId').value
        )
        .subscribe((data) => {
          if (data !== null) {
            (this.boatForm.get('associateds') as UntypedFormArray).controls[
              index
            ]
              .get('federalId')
              .reset();
            this.messageUtil.generateMessage(
              'warning',
              'SUMMARY.WARNING',
              'FEDERAL-ID-ALREADY-EXISTS'
            );
          }
        });
    }
  }

  validateFederalId(index: string): boolean {
    let validFederalId = true;
    validFederalId =
      (this.boatForm.get('associateds') as UntypedFormArray).controls[
        index
      ].get('federalId').value &&
      this.validatorsUtil.cpfCnpjValidator(
        (this.boatForm.get('associateds') as UntypedFormArray).controls[
          index
        ].get('federalId').value
      );
    if (!validFederalId) {
      (this.boatForm.get('associateds') as UntypedFormArray).controls[index]
        .get('federalId')
        .reset();
      this.messageUtil.generateMessage(
        'warning',
        'SUMMARY.WARNING',
        'INVALID-FEDERAL-ID'
      );
    }
    return validFederalId;
  }

  getAssociatedId(index: string): number {
    const id = (this.boatForm.get('associateds') as UntypedFormArray).controls[
      index
    ].get('id').value;
    return id;
  }

  generateLastHundredYears(): void {
    const years: { label: string; value: number }[] = [];
    let lastYear: number = new Date().getFullYear() + 1;
    let i = 0;
    while (i < 100) {
      years.push({ label: lastYear.toString(), value: lastYear });
      lastYear--;
      i++;
    }
    this.lastHundredYears = years;
  }

  removeAccessory(accessoryBoat): void {
    const accessories = this.boatForm
      .get('boatAccessories')
      .value.filter((ba) => ba !== accessoryBoat);
    this.accessoriesList = this.accessoriesList.filter(
      (a) => a.urlPhoto !== accessoryBoat.accessory.urlPhoto
    );
    this.boatForm.get('boatAccessories').setValue(accessories);
  }

  setBoatHullSpecification($event): void {
    let shipyardModel: ShipyardModel = new ShipyardModel();
    this.shipyardService.getShipyardModelById($event.value).subscribe(
      (data) => {
        shipyardModel = data;
        this.boatForm.get('beam').setValue(shipyardModel.beam);
        this.boatForm.get('length').setValue(shipyardModel.length);
        this.boatForm
          .get('commercialLength')
          .setValue(shipyardModel.commercialLength);
        this.boatForm
          .get('shipyardModel')
          .get('name')
          .setValue(shipyardModel.name);
        this.boatForm.get('cabins').setValue(shipyardModel.cabins);
        this.boatForm.get('bathrooms').setValue(shipyardModel.bathrooms);
        this.boatForm.get('modelYear').setValue(shipyardModel.modelYear);
        this.boatForm
          .get('passengersDay')
          .setValue(shipyardModel.passengersDay);
        this.boatForm
          .get('passengersNight')
          .setValue(shipyardModel.passengersNight);
      },
      (error) => {
        shipyardModel = null;
        this.errorMessage = error;
      }
    );
  }

  loadInsurers(): Observable<void> {
    return new Observable<void>((observer) => {
      this.insurerService.getAll().subscribe({
        next: (data) => {
          this.insurers = data.map((d) => ({ label: d.name, value: d }));
          observer.next();
        },
        error: (err) => {
          observer.error(err);
        },
      });
    });
  }

  loadStates(): void {
    try {
      this.states = BrStates.statesList.map((d) => ({
        label: d.name,
        value: d.name,
      }));
      if (this.boatForm.get('tie').value.state) {
        this.loadCities(this.boatForm.get('tie').value.state);
      }
    } catch (error) {
      this.cities = new Array();
      this.states = new Array();
    }
  }

  loadCities(stateName: string): void {
    const state = BrStates.statesList.find((s) => s.name === stateName);
    this.locationService.getCities('BR', state.id).subscribe(
      (data) => {
        this.cities = data.map((d) => ({ label: d.name, value: d.name }));
      },
      () => {
        this.cities = new Array();
      }
    );
  }

  loadFuels(): Observable<void> {
    return new Observable<void>((observer) => {
      this.fuelService.findAll().subscribe({
        next: (data) => {
          this.fuels = data.map((a) => ({ label: a.name, value: a }));
          observer.next();
        },
        error: (error) => {
          this.fuels = null;
          this.errorMessage = error;
          observer.error(error);
        },
      });
    });
  }

  loadCustomers(): Observable<void> {
    return new Observable<void>((observer) => {
      this.customerService.getAll().subscribe({
        next: async (data) => {
          this.customers = data;
          await this.loadOwners();
          await this.loadAssociateds();
          this.filterAssociateds('');
          observer.next();
        },
        error: (error) => {
          this.owners = null;
          this.associateds = null;
          this.errorMessage = error;
          observer.error(error);
        },
      });
    });
  }

  loadPeriodicServices(): Observable<void> {
    return new Observable<void>((observer) => {
      this.productService
        .getByProductType(ProductType.PeriodicalService)
        .subscribe({
          next: (data) => {
            const filterData = data.filter((service) => service.active == true);
            this.services = filterData.map((d) => ({
              label: d.name,
              value: d,
            }));
            observer.next();
          },
          error: (error) => {
            this.services = null;
            this.errorMessage = error;
            observer.error(error);
          },
        });
    });
  }

  loadAccessories(): Observable<void> {
    return new Observable<void>((observer) => {
      this.accessoryService.getAll().subscribe({
        next: (data) => {
          const groups: UntypedFormGroup[] = [];
          for (let index = 0; index < data.length; index++) {
            this.accessoriesList = data;
            data = data.filter(
              (item) => item.accessoryType == AccessoryType.Optional
            );
            this.optionalAccessoriesList = data;
            const boatAccessorie = this.boat.boatAccessories.find(
              (ba) => ba.accessory.id === data[index].id
            );
            if (boatAccessorie !== undefined) {
              groups.push(
                new UntypedFormGroup({
                  accessory: new UntypedFormControl(boatAccessorie.accessory),
                  qty: new UntypedFormControl(boatAccessorie.quantity),
                  checked: new UntypedFormControl(true),
                })
              );
            } else {
              groups.push(
                new UntypedFormGroup({
                  accessory: new UntypedFormControl(),
                  qty: new UntypedFormControl(),
                  checked: new UntypedFormControl(false),
                })
              );
            }
          }
          this.boatForm.setControl('accessories', new UntypedFormArray(groups));
          observer.next();
        },
        error: (error) => {
          this.services = null;
          this.errorMessage = error;
          observer.error(error);
        },
      });
    });
  }

  loadShipyards(): Observable<void> {
    return new Observable((observer) => {
      this.shipyardService.getAll().subscribe({
        next: (data) => {
          this.shipyards = data
            .sort((a, b) => {
              if (a.name > b.name) {
                return 1;
              } else if (a.name < b.name) {
                return -1;
              }
              return 0;
            })
            .map((s) => ({ label: s.name, value: s }));
          observer.next();
        },
        error: (error) => {
          this.shipyards = [];
          this.errorMessage = error;
          observer.error();
        },
      });
    });
  }

  loadShipyardModels(shipyardId: number): Observable<void> {
    return new Observable((observer) => {
      this.shipyardService.getShipyardModelsByShipyardId(shipyardId).subscribe({
        next: (data) => {
          this.shipyardModels = [];
          this.shipyardModels = data.map((a) => ({
            label: a.name,
            value: a.id,
          }));
          observer.next();
        },
        error: (error) => {
          this.shipyardModels = [];
          this.errorMessage = error;
          observer.error();
        },
      });
    });
  }

  getNameDocumentFile(fileName: string): string {
    return fileName.substring(fileName.indexOf('_EASY_') + 6);
  }

  onInputCommercialLength(): void {
    const boatServicesControl = this.boatForm.get(
      'boatServices'
    ) as UntypedFormArray;
    let boatServicesValue;
    if (boatServicesControl) {
      boatServicesValue = boatServicesControl.value;
    }

    if (boatServicesValue) {
      boatServicesValue.forEach((bs: any) => {
        const serviceValue = this.calcServiceValue(bs.service);
        if (serviceValue && !bs.service.price.priceFree) {
          bs.value = serviceValue;
          if (bs.discountPercentage != null) {
            bs.discount = serviceValue * (bs.discountPercentage / 100);
          }
          if (bs.dueDateDiscountPercentage != null) {
            bs.dueDateDiscount =
              serviceValue * (bs.dueDateDiscountPercentage / 100);
          }
        }
        if (bs.dueDateDiscountPercentage == null) {
          bs.dueDateDiscountPercentage = 0;
        }
        if (bs.dueDateDiscount == null) {
          bs.dueDateDiscount = 0;
        }
        if (bs.discount == null) {
          bs.discount = 0;
        }
        if (bs.discountPercentage == null) {
          bs.discountPercentage = 0;
        }
        if (bs.useDiscountOnPercentage == null) {
          bs.useDiscountOnPercentage = false;
        }
      });
    }
    boatServicesControl.setValue(boatServicesValue);
  }

  isServiceExist(service): any {
    return (
      this.boatForm.get('boatServices') as UntypedFormArray
    ).controls.find((item) => item.value.service.id === service.id);
  }

  calcTotalService(service): number {
    const value = service.value == undefined ? null : service.value;
    const discount = service.discount == undefined ? null : service.discount;
    return value - discount;
  }

  calcTotalServiceDueDate(service): number {
    const value = service.value == undefined ? null : service.value;
    const discount = service.discount == undefined ? null : service.discount;
    const dueDateDiscount =
      service.dueDateDiscount == undefined ? null : service.dueDateDiscount;
    return value - discount - dueDateDiscount;
  }

  calcServiceValue(addService): number {
    switch (addService.pricingType) {
      case PrincingType.FixedPerRange:
        return Math.round(this.calcServicePricePerRange(
          this.boatForm.get('commercialLength').value,
          addService.price.priceRanges
        ) * 100)/100;
      case PrincingType.SimplePrice:
        return addService.price.value ? addService.price.value : 0;
      case PrincingType.PerRangeLength:
        return Math.round(this.calcServicePricePerLenght(
          this.boatForm.get('commercialLength').value,
          addService.price.priceRanges
        ) * 100)/100;
    }
  }

  private calcServicePricePerLenght(sizeBoat, priceRange): number {
    const range = priceRange.find(
      (range) =>
        sizeBoat >= range.startLengthRange && sizeBoat <= range.endLengthRange
    );
    if (range) {
      const val = (range.value * sizeBoat * 100) / 100;
      return val;
    }
  }

  private calcServicePricePerRange(sizeBoat, priceRange): number {
    const range = priceRange.find(
      (range) =>
        sizeBoat >= range.startLengthRange && sizeBoat <= range.endLengthRange
    );
    if (range) {
      return range.value;
    }
  }

  openAccessoriesDialog(): void {
    this.dialog
      .open(BoatAccessoriesComponent, {
        width: '600px',
        data: {
          boat: this.boatForm.value,
        },
        header: 'Cadastro de acessório',
      })
      .onClose.subscribe((data) => {
        if (data) {
          let accessories = this.boatForm.get('boatAccessories').value;
          if (!accessories) {
            accessories = [];
          }
          accessories.push(data);
          this.boatForm.get('boatAccessories').setValue(accessories);
        }
      });
  }

  getBoatAccessories(): any[] {
    const accessories = this.boatForm.get('boatAccessories').value;
    return accessories.filter(
      (ba) => ba.accessory.accessoryType === AccessoryType.Accessory
    );
  }

  calculateQuota(): void {
    this.sumQuota = (
      this.boatForm.get('owners') as UntypedFormArray
    ).controls.reduce((total, c) => {
      return parseFloat((total + c.value.quota).toFixed(2));
    }, 0);
    this.invalidQuota =
      (this.boatForm.get('owners') as UntypedFormArray).controls.length > 0 &&
      this.sumQuota !== 100;
  }

  save(): void {
    if (this.boatForm.get('active').value === false && this.activeBoat) {
      Swal.fire({
        title: 'Confirmar operação?',
        text: 'Deseja realmente desativar a embarcação?',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        showLoaderOnConfirm: true,
        reverseButtons: true,
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          this.save02();
        }
      });
    } else {
      this.save02();
    }
  }

  save02(): void {
    if (
      this.blockChangeType &&
      this.boatForm.controls.boatProfile.value != this.boat.boatProfile
    ) {
      this.messageUtil.generateMessage(
        'warning',
        'SUMMARY.WARNING',
        'A embarcação está com um plano ativo, finalize para alterar o perfil.'
      );
      return;
    }
    if (this.invalidQuota) {
      this.messageUtil.generateMessage(
        'warning',
        'SUMMARY.WARNING',
        'QUOTA-MUSTBE100-MESSAGE'
      );
      return;
    }

    if (
      this.boatForm.get('active').value === true &&
      this.isMovimentOperation &&
      this.boatForm.controls.boatProfile.value === 'RECURRENT' &&
      this.boatForm.get('movementLocation').value === null
    ) {
      FormUtil.touchAndSendFormGroup(this.boatForm);
      if (!this.boatForm.get('tie.file').valid) {
        this.buttonClass = 'attachment danger';
      } else {
        this.buttonClass = 'attachment';
      }
      Swal.fire('Alerta!', 'A Local da embarcação é obrigatória.', 'warning');
      return;
    }
    this.verifyCurrentLocation();

    if (this.boatForm.get('twoTimeEngine').value == null) {
      this.boat.twoTimeEngine = false;
      this.boatForm.get('twoTimeEngine').setValue(false);
    }

    if (!this.boatForm.valid) {
      FormUtil.touchAndSendFormGroup(this.boatForm);
      if (!this.boatForm.get('tie.file').valid) {
        this.buttonClass = 'attachment danger';
      } else {
        this.buttonClass = 'attachment';
      }
      Swal.fire(
        'Alerta!',
        'Existem campos inválidos ou não informados, verifique os dados.',
        'warning'
      );
      return;
    }

    // if (!this.boatForm.valid) {
    //   FormUtil.touchAndSendFormGroup(this.boatForm);
    //   if (!this.boatForm.get('dpem.file').valid) {
    //     this.buttonClass1 = 'attachment danger';
    //   } else {
    //     this.buttonClass1 = 'attachment';
    //   }
    //   Swal.fire(
    //     'Alerta!',
    //     'Existem campos inválidos ou não informados, verifique os dados.',
    //     'warning'
    //   );
    //   return;
    // }

    if (
      this.boatForm.controls.boatProfile.value === 'EVENTUAL' ||
      this.boatForm.controls.boatProfile.value === 'WORKSHOP'
    ) {
      this.boatForm.controls.movementLocation.setValue(null);
      this.boatForm.controls.vacancy.setValue(null);
    }
    this.boat = this.boatForm.getRawValue();
    this.boat.operator = this.operatorSelected;
    this.boat.boatCustomers = this.boatForm
      .get('owners')
      .value.concat(this.boatForm.get('associateds').value)
      .map((a) => {
        if (!a.customer.id) {
          a.customer.name = a.name;
          a.customer.email = a.email;
          a.customer.federalId = a.federalId;
          a.customer.phone = a.phone;
          a.customer.marina = new Marina();
          a.customer.marina.id = StorageUtil.getMarinaId();
          a.customer.billingDay = null;
          a.customer.birthday = null;
          a.customer.onlyAssociated = true;
        }
        return a as CustomerBoat;
      });
    if (this.slingConfig.hasSailor) {
      this.boat.boatSailors = this.sailorList.get('boatSailors').value;
    }
    if (this.engineList) {
      this.boat.boatEngines = this.engineList;
    }
    if (!Array.isArray(this.boat.boatEngines) && this.boat.boatEngines) {
      this.boat.boatEngines = [];
    }
    if (
      this.boatForm.get('tie').get('register').value == null &&
      this.boatForm.get('tie').get('boatNf').value == null
    ) {
      this.boat.tie = null;
    }
    // this.calculateQuota();
    this.validDiscount();
    this.spinner.show();
    if (this.boat.id) {
      // this.setBoatAccessories();
      this.boat.vacancy = !this.boat.vacancy ? null : this.boat.vacancy;
      this.contractAlert();
    } else {
      // this.setBoatAccessories();
      this.saveVerifiedBoat();
    }
  }

  verifyCurrentLocation(): void {
    if (
      this.isMovimentOperation &&
      this.boatForm.controls.boatProfile.value === 'RECURRENT' &&
      this.boatForm.get('active').value === true &&
      this.boat!.currentLocation !== null &&
      this.boatForm.controls.boatProfile.value != this.boat.boatProfile
    ) {
      let message;
      if (
        this.boat!.currentLocation &&
        this.boat!.currentLocation!.location!.id > 0
      ) {
        if (
          this.boat.currentLocation.location.id !==
            this.boatForm.value.movementLocation.id ||
          this.boat.currentLocation.vacancy !== this.boatForm.value.vacancy
        ) {
          message =
            'O local atual da embarcação ' +
            this.boat.name +
            ' é ' +
            this.boat.currentLocation.location.name;
          if (this.boat.currentLocation.vacancy !== null) {
            message += ' na vaga ' + this.boat.currentLocation.vacancy.code;
          }
          message += ', a próxima movimentação terá esse local como origem';
        }
        if (message != null) {
          alert(message);
        }
      }
    }
  }

  uploadFile(files): void {
    this.uploadDocumentationFileInProgress = true;
    this.boatService.uploadDocument(files).subscribe(
      (result) => {
        this.uploadDocumentationFileInProgress = false;
        this.boatForm.get('tie.file').setValue(result.url);
        this.buttonClass = 'attachment';
        this.upload.clear();
      },
      (err) => {
        this.uploadDocumentationFileInProgress = false;
        console.log(err);
      }
    );
  }

  uploadFileDpem(files): void {
    this.uploadDocumentationDpemFileInProgress = true;
    this.boatService.uploadDocumentDpem(files).subscribe(
      (result) => {
        this.uploadDocumentationDpemFileInProgress = false;
        this.boatForm.get('dpem.file').setValue(result.url);
        this.buttonClass1 = 'attachment';
        this.upload.clear();
      },
      (err) => {
        this.uploadDocumentationDpemFileInProgress = false;
        console.log(err);
      }
    );
  }

  validDiscount(): void {
    if (this.boatForm.get('boatServices').value) {
      this.boatForm.get('boatServices').value.forEach((bs) => {
        if (bs.discount == null) {
          bs.discount = 0;
        }
        if (bs.dueDateDiscount == null) {
          bs.dueDateDiscount = 0;
        }
      });
    }
  }

  viewDocumentation(file): void {
    window.open(file);
  }

  newDocumentation(): void {
    this.boatForm.get('tie').enable();
    this.boatForm.get('tie').reset();
    this.boatForm.get('tie.country').setValue('BR');
    this.loadStates();
  }

  removeDocumentation(): void {
    this.boatForm.get('tie').disable();
    this.boatForm.get('tie').reset();
    this.boatForm.get('tie').get('id').setValue(null);
    this.loadStates();
  }

  closeaDialog(): void {
    this.dialogRef.close();
  }

  openContract(path): void {
    this.dialog.open(BoatContractComponent, {
      width: '100%',
      height: '100%',
      dismissableMask: false,
      data: { path },
      header: 'Contrato',
    });
  }

  findMarinaOrders(): void {
    this.filterMarinaOrder.idBoat = this.boat.id;
    this.filterMarinaOrder.idCustomer = null;
    // this.filterMarinaOrder.origim = 'TUITION';
    this.filterMarinaOrder.hasInvoice = '';

    this.marinaService
      .getOrderMarina(this.filterMarinaOrder)
      .subscribe((data) => {
        this.marinaOrders = data;
        this.totalOrders = this.marinaOrders.reduce(
          (total, order) => total + order.value,
          0
        );
        this.totalValueOrders = 0;
        this.marinaOrders.forEach((order) => {
          this.totalValueOrders += order.orderQuotas.reduce(
            (total, oq) =>
              total + this.calculateQuotaValue(order.value, oq.quota),
            0
          );
        });
      });
  }

  calculateQuotaValue(value: number, quota: number): number {
    return (value * quota) / 100;
  }

  getMarinaUsers(): Observable<void> {
    return new Observable<void>((observer) => {
      this.userService.getMarinaResponsibleUsers().subscribe({
        next: (u) => {
          this.marinaUsers = [{ label: 'Sem responsável', value: null }].concat(
            u.map((x) => ({
              label: x.firstName + ' ' + x.lastName,
              value: x.id,
            }))
          );
          observer.next();
        },
        error: (error) => {
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
          observer.error(error);
        },
      });
    });
  }

  async getHistoricalData(id): Promise<void> {
    const promises = [
      this.getSlingHistory(id),
      this.getEngineTurnHistory(id),
      this.getBoatWashHistory(id),
    ];
    await Promise.all(promises);
    this.loadTimeline();
  }

  async getEngineTurnHistory(id): Promise<boolean> {
    return new Promise<any>((resolve) => {
      this.engineTurnService
        .getBoatEngineTurnHistory(id)
        .subscribe((history: any) => {
          this.engineTurnHistory = history;
          resolve(true);
        });
    });
  }

  async getBoatWashHistory(id): Promise<boolean> {
    return new Promise<any>((resolve) => {
      this.boatWashService.getBoatWashHistory(id).subscribe((history: any) => {
        this.boatWashHistory = history;
        resolve(true);
      });
    });
  }

  async getSlingHistory(id): Promise<boolean> {
    return new Promise<any>((resolve) => {
      this.slingService.getAllByBoat(id).subscribe((p) => {
        this.slingsHistory = p;
        resolve(true);
      });
    });
  }

  loadTimeline(): void {
    const histories: { data: moment.Moment; datetime: Date; type: string }[] =
      [];
    if (this.boatWashHistory) {
      this.boatWashHistory.map((history) => {
        histories.push({
          data: moment(history.washDate).hours(12).minutes(0).seconds(0),
          datetime: history.washDate,
          type: 'WASH',
        });
      });
    }
    if (this.engineTurnHistory) {
      this.engineTurnHistory.map((history) => {
        histories.push({
          data: moment(history.engineTurnDate).hours(12).minutes(0).seconds(0),
          datetime: history.engineTurnDate,
          type: 'ENGINE-TURN',
        });
      });
    }
    this.slingsHistory.map((history) => {
      if (history.slingStatus !== SlingStatus.CANCELED) {
        if (history.slingDownAt) {
          histories.push({
            data: moment(history.slingDownAt).hours(12).minutes(0).seconds(0),
            datetime: history.slingDownAt,
            type: 'SLING-DOWN',
          });
        }
        if (history.slingUpAt) {
          histories.push({
            data: moment(history.slingUpAt).hours(12).minutes(0).seconds(0),
            datetime: history.slingUpAt,
            type: 'SLING-UP',
          });
        }
        if (history.dateOwnFuelSupply) {
          histories.push({
            data: moment(history.dateOwnFuelSupply)
              .hours(12)
              .minutes(0)
              .seconds(0),
            datetime: history.dateOwnFuelSupply,
            type: 'OWN-FUEL-SUPPLY',
          });
        }
        if (history.marinaOrder.orderItems.length > 0) {
          const fuels = history.marinaOrder.orderItems.filter(
            (x: OrderItem) =>
              x.qtySupplied > 0 &&
              x.product.productCategory.productCategoryType ===
                ProductCategoryType.Fuel
            // && x.dateSupply
          );
          if (fuels.length > 0) {
            fuels.forEach((fuel: OrderItem) => {
              histories.push({
                data: moment(
                  fuel.dateSupply != null
                    ? fuel.dateSupply
                    : history.marinaOrder.orderDate
                )
                  .hours(12)
                  .minutes(0)
                  .seconds(0),
                datetime:
                  fuel.dateSupply != null
                    ? fuel.dateSupply
                    : history.marinaOrder.orderDate,
                type: 'FUEL-SUPPLY',
              });
            });
          }
        }
      }
    });
    histories.sort(
      (a, b) => b.data.toDate().getTime() - a.data.toDate().getTime()
    );
    this.histories = [];
    const dates = FormatUtil.groupByReduce(histories, 'data');
    const keys = Object.keys(dates);
    keys.forEach((key) => {
      dates[key].sort(
        (a, b) =>
          new Date(a.datetime).getTime() - new Date(b.datetime).getTime()
      );
      this.histories.push({
        date: new Date(key),
        data: dates[key],
      });
    });
  }

  isNewMonth(oldData, newData): boolean {
    if (new Date(oldData).getMonth() !== new Date(newData).getMonth()) {
      return true;
    }
    return false;
  }

  startForm(): void {
    this.boatForm = new FormGroup({
      id: new FormControl<number | null>(null),
      marina: new FormControl<Marina>(StorageUtil.getMarina()),
      name: new FormControl<string | null>(
        null,
        Validators.compose([Validators.required, Validators.maxLength(50)])
      ),
      boatProfile: new FormControl<BoatProfile | null>(
        BoatProfile.Recurrent,
        Validators.required
      ),
      movementGroup: new FormControl<MovementGroup | null>(
        { value: null, disabled: !this.isMovimentOperation },
        [Validators.required]
      ),
      vacancy: new FormControl<Vacancy | null>(null),
      wetVacancy: new FormControl<boolean | null>(null),
      movementLocation: new FormControl<MovementLocation | null>({
        value: null,
        disabled: !this.isMovimentOperation,
      }),
      maintenance: new FormControl<boolean>(false, Validators.required),
      availableOnApp: new FormControl<boolean>(
        this.boat.id ? this.boat.availableOnApp : true,
        Validators.required
      ),
      active: new FormControl<boolean>(true, Validators.required),
      independentTanks: new FormControl<boolean>(false, Validators.required),
      owners: new FormArray<FormControl<Customer>>([]),
      boatCustomers: new FormControl<Array<CustomerBoat>>([]),
        // daniel
      franchiseGroup: new FormControl<any | null>(null),
      associateds: new UntypedFormArray([]),
      boatEngines: new UntypedFormArray([]),
      shipyardModel: new FormGroup(
        {
          shipyard: new FormControl<Shipyard | null>(null, Validators.required),
          id: new FormControl<number | null>(
            { value: null, disabled: true },
            Validators.required
          ),
          name: new FormControl<string | null>(null, Validators.required),
          shipType: new FormControl<ShipType | null>(null, Validators.required),
        },
        Validators.required
      ),
      beam: new FormControl<number | null>(null),
      draft: new FormControl<number | null>(null),
      length: new FormControl<number | null>(null),
      commercialLength: new FormControl<number | null>(
        null,
        Validators.required
      ),
      manufactureYear: new FormControl<number | null>(
        null,
        Validators.required
      ),
      modelYear: new FormControl<number | null>(null, Validators.required),
      mainColor: new FormControl<string | null>(null, Validators.required),
      crew: new FormControl<number | null>(null, Validators.required),
      chassiHull: new FormControl<string | null>(null),
      passengersDay: new FormControl<number | null>(null, Validators.required),
      passengersNight: new FormControl<number | null>(null),
      cabins: new FormControl<number | null>(null),
      bathrooms: new FormControl<number | null>(null),
      bowType: new FormControl<Bow | null>(null, Validators.required),
      flyBridge: new FormControl<boolean>(false, Validators.required),
      fuel: new FormControl<Fuel | null>(null, Validators.required),
      twoTimeEngine: new FormControl<boolean>(false, Validators.required),
      marineRunMyEnginer: new FormControl<boolean>(false),
      marineWashMyBoat: new FormControl<boolean>(false),
      timeBetweenWash: new FormControl<number>(0),
      timeBetweenEngineRotation: new FormControl<number>(0),
      tie: new UntypedFormGroup({
        id: new UntypedFormControl(
          null,
          this.boat.id && this.boat.tie ? Validators.required : null
        ),
        register: new UntypedFormControl(null),
        country: new UntypedFormControl('BR'),
        state: new UntypedFormControl(null),
        city: new UntypedFormControl(null),
        expiration: new UntypedFormControl(null),
        navigationArea: new UntypedFormControl(null),
        boatNf: new UntypedFormControl(null),
        file: new UntypedFormControl(null),
      }),
      insurerType: new FormControl<InsurerType | null>(null),
      boatInsurer: new UntypedFormGroup({
        policyNumber: new UntypedFormControl(null, Validators.required),
        policyExpirationDate: new UntypedFormControl(null, Validators.required),
        insurer: new UntypedFormControl(null, Validators.required),
      }),
      dpem: new UntypedFormGroup({
        file: new UntypedFormControl(null),
        policyDpemNumber: new UntypedFormControl(null),
        policyDpemExpirationDate: new UntypedFormControl(null),
      }),
      reviewed: new FormControl<boolean>(false, Validators.required),
      operator: new FormControl<User | null>(null),
      boatAccessories: new FormControl<Array<AccessoryBoat>>([]),
      service: new UntypedFormGroup({
        service: new UntypedFormControl(null),
        billingPeriodicity: new UntypedFormControl('Monthly'),
        executionPeriodicity: new UntypedFormControl('Monthly'),
        contractStartDate: new UntypedFormControl(null),
      }),
      boatServices: new UntypedFormArray([]),
      accessories: new UntypedFormArray([]),
      apprehend: new FormControl<boolean>(false),
      fullTank: new FormControl<boolean>(false),
      showAllCustomerData: new FormControl<boolean>(true),
    });
    this.boatForm.get('tie').disable();
    this.boatForm.get('boatInsurer').disable();
    if (this.isMovimentOperation) {
      this.updateMovementLocationAndVacancy();
    }
    if (this.boatForm.controls.boatProfile.value !== 'EVENTUAL') {
      this.boatForm.controls.movementLocation.enable();
      this.boatForm.controls.vacancy.enable();
    }
    this.boatForm.controls.boatProfile.valueChanges.subscribe((data) => {
      this.boatProfileSelected = data;
      if (
        this.boatProfileSelected === 'EVENTUAL' ||
        this.boatProfileSelected === 'WORKSHOP'
      ) {
        this.boatForm.controls.movementLocation.disable();
        this.boatForm.controls.vacancy.disable();
      } else {
        this.boatForm.controls.movementLocation.enable();
        this.boatForm.controls.vacancy.enable();
      }
      this.loadMovementLocations().subscribe();
    });
  }

  updateMovementLocationAndVacancy(): void {
    const { vacancy, movementLocation } = this.boatForm.controls;
    vacancy.setValidators([
      getVacancyByMovementLocationValidator(this.boatForm),
    ]);
    movementLocation.valueChanges.subscribe((data: MovementLocation) => {
      if (data && this.vacancies) {
        this.setVacancyByMovimentOperational(data);
      }
    });
  }

  cleanFormControl(formControl: AbstractControl): void {
    const value = formControl.value;
    formControl.reset();
    if (formControl) {
      // tslint:disable-next-line: no-string-literal
      Object.keys(formControl['controls']).forEach((key) => {
        formControl
          .get(key)
          .setValue(typeof value[key] === 'boolean' ? false : null);
      });
    }
    formControl.updateValueAndValidity();
  }

  selectOwner(rowIndex: string, $event): void {
    const customer = this.customers.find(
      (c) => c.id === $event.value
    ) as Customer;
    (this.boatForm.get('owners') as UntypedFormArray).controls[
      rowIndex
    ].patchValue(customer);
    (this.boatForm.get('owners') as UntypedFormArray).controls[rowIndex]
      .get('customer')
      .setValue(customer);
    this.loadCustomers().subscribe();
    this.calculateQuota();
  }

  selectAssociated(index: string, $event): void {
    const customer = $event.value
      ? this.customers.find((c) => c.id === $event.value.id)
      : null;
    if (customer) {
      (this.boatForm.get('associateds') as UntypedFormArray).controls[
        index
      ].patchValue(customer);
      (this.boatForm.get('associateds') as UntypedFormArray).controls[index]
        .get('customer')
        .setValue(customer);
    } else {
      (this.boatForm.get('associateds') as UntypedFormArray).controls[
        index
      ].reset({
        viewHistory: true,
      });
      (this.boatForm.get('associateds') as UntypedFormArray).controls[index]
        .get('customer')
        .setValue(new Customer());
      this.filteredAssociateds.push({
        label: $event.label,
        value: $event.label,
      });
      (this.boatForm.get('associateds') as UntypedFormArray).controls[index]
        .get('name')
        .setValue($event.label.replace('+ ', ''));
    }
    this.loadOwners();
    this.loadAssociateds();
  }

  getCustomerLabel(customer: Customer): string {
    return (
      customer.name +
      ' - ' +
      customer.federalId +
      (!customer.active
        ? ' - ' + this.messageUtil.translateKey('INACTIVE')
        : '')
    );
  }

  fillQuota(event, boatCustomer): void {
    boatCustomer.quota = Number.parseInt(event.srcElement.value, 10);
    this.calculateQuota();
  }

  getBoatOwners(): any[] {
    return (
      this.boatForm &&
      this.boatForm.get('owners') &&
      (this.boatForm.get('owners') as UntypedFormArray).controls.map(
        (control) => control.value
      )
    );
  }

  getBoatAssociateds(): any[] {
    return (
      this.boatForm &&
      this.boatForm.get('associateds') &&
      (this.boatForm.get('associateds') as UntypedFormArray).controls.map(
        (control) => control.value
      )
    );
  }

  getBoatOwnersDropdown(): { label: string; value: any }[] {
    return this.getBoatOwners()
      .filter((b) => b.id)
      .map((b) => ({ label: b.name, value: b.id }));
  }

  getErrorTooltip(control: AbstractControl): string {
    let tooltip = '';
    if (control && control.touched && control.dirty && control.errors) {
      Object.keys(control.errors).forEach((key) => {
        switch (key) {
          case 'required':
            tooltip += this.messageUtil.translateKey('REQUIRED-FIELD');
            tooltip += '\n';
            break;
          case 'federalIdInUse':
            tooltip += this.messageUtil.translateKey(
              'FEDERAL-ID-ALREADY-EXISTS'
            );
            tooltip += '\n';
            break;
          case 'invalidFederalId':
            tooltip += this.messageUtil.translateKey('INVALID-FEDERAL-ID');
            tooltip += '\n';
            break;
          default:
            break;
        }
      });
    }

    return tooltip;
  }

  sendFormControl(formControl: AbstractControl): void {
    formControl.markAsDirty();
    formControl.markAsTouched();
    // tslint:disable-next-line: no-string-literal
    if (formControl['controls']) {
      // tslint:disable-next-line: no-string-literal
      Object.keys(formControl['controls']).forEach((key) => {
        formControl.get(key).markAsTouched();
        formControl.get(key).markAsDirty();
      });
    }
  }

  validateFormGroup(formGroup): boolean {
    let valid = true;
    if (formGroup && formGroup.value) {
      Object.keys(formGroup.controls).forEach((key) => {
        if (
          formGroup.get(key).value === undefined &&
          formGroup.get(key).value === null
        ) {
          formGroup.get(key).setErrors({ required: true });
          formGroup.get(key).markAsDirty();
          formGroup.get(key).markAsTouched();
          valid = false;
        }
      });
    }
    if (!valid) {
      Swal.fire(
        'Alerta!',
        'Por favor, preencha os campos obrigatórios do proprietário',
        'warning'
      );
    }
    return valid;
  }

  disableControls(group: UntypedFormGroup): void {
    if (group.controls) {
      Object.keys(group.controls).forEach((key) => {
        group.get(key).disable();
      });
    }
  }

  checkAccessory(accessory, $event): void {
    accessory.checked = $event.checked;
    let accessories = this.boatForm.get('boatAccessories').value as any[];
    if (!accessories) {
      accessories = [];
    }
    if ($event.checked) {
      accessories.push({ accessory, qty: 0 });
    } else {
      const acc = accessories.find((a) => a.accessory.id === accessory.id);
      const index = accessories.indexOf(acc);
      accessories.splice(index, 1);
    }
    this.boatForm.get('boatAccessories').setValue(accessories);
  }

  updateQty($event, accessory): void {
    const accessories = this.boatForm.get('boatAccessories').value;
    accessories.forEach((ac) => {
      if (ac.accessory.id === accessory.id) {
        ac.quantity = Number.parseInt($event.target.value, 10);
      }
    });
    this.boatForm.get('boatAccessories').setValue(accessories);
  }

  validateBoatService(): void {
    if (!this.validateFormGroup(this.boatForm.get('service'))) {
      Swal.fire(
        'Alerta!',
        'Por favor, preencha os campos obrigatórios do serviço',
        'warning'
      );
      return;
    }
    if (
      this.boatForm.get('service.service').value.productType ===
      ProductType.PeriodicalService
    ) {
      const newService = this.boatForm.get('service.service').value;
      const serviceValue = this.calcServiceValue(newService);
      if (!this.validNewService(serviceValue, newService)) {
        this.boatForm.get('service').reset();
        this.messageUtil.generateMessage(
          'warning',
          'SUMMARY.WARNING',
          'MSG.RANGE-NOTFOUND'
        );
        return;
      }
      const serviceBoat: ServiceBoat = this.boatForm.get('service').value;
      serviceBoat.value = serviceValue;
      serviceBoat.active = true;
      serviceBoat.discount = 0;
      serviceBoat.discountPercentage = 0;
      serviceBoat.dueDateDiscount = 0;
      serviceBoat.dueDateDiscountPercentage = 0;
      this.associateServiceBoat(serviceBoat);
    }
  }

  private validNewService(serviceValue, service: Product): boolean {
    return (
      serviceValue ||
      (service.pricingType === 'SimplePrice' &&
        this.slingConfig.periodicalServicePriceFree)
    );
  }

  associateServiceBoat(serviceBoat: ServiceBoat): void {
    let services = (
      this.boatForm.get('boatServices') as UntypedFormArray
    ).controls.filter((boatService) => boatService.value.active);
    if (!services) {
      services = [];
    }
    services.push(
      new UntypedFormGroup({
        service: new UntypedFormControl(
          serviceBoat.service,
          Validators.required
        ),
        billingPeriodicity: new UntypedFormControl(
          serviceBoat.billingPeriodicity
            ? serviceBoat.billingPeriodicity
            : 'Monthly'
        ),
        executionPeriodicity: new UntypedFormControl(
          serviceBoat.executionPeriodicity
            ? serviceBoat.executionPeriodicity
            : 'Monthly'
        ),
        contractStartDate: new UntypedFormControl(
          serviceBoat.contractStartDate,
          Validators.required
        ),
        dueDateDiscount: new FormControl<number>({
          value: serviceBoat.dueDateDiscount ?? 0,
          disabled: this.isDiscountPercentage,
        }),
        dueDateDiscountPercentage: new FormControl<number>({
          value: serviceBoat?.dueDateDiscountPercentage ?? 0,
          disabled: !this.isDiscountPercentage,
        }),
        contractEndDate: new UntypedFormControl(serviceBoat.contractEndDate),
        lastReadjustment: new UntypedFormControl(serviceBoat.lastReadjustment),
        discount: new FormControl<number>({
          value: serviceBoat.discount ?? 0,
          disabled: this.isDiscountPercentage,
        }),
        discountPercentage: new FormControl<number>({
          value: serviceBoat?.discountPercentage ?? 0,
          disabled: !this.isDiscountPercentage,
        }),
        useDiscountOnPercentage: new FormControl<boolean>(
          serviceBoat?.useDiscountOnPercentage
        ),
        observation: new FormControl<string>(serviceBoat.observation),
        filePath: new FormControl<string>(serviceBoat.filePath),
        active: new FormControl<boolean>(serviceBoat.active),
        id: new FormControl<number | null>(serviceBoat.id),
        value: new FormControl<number | null>(
          serviceBoat.value,
          Validators.required
        ),
      })
    );
    this.boatForm.setControl('boatServices', new UntypedFormArray(services));
    this.boatForm.get('service').reset();
  }

  setBoatForm(): void {
    Object.keys(this.boatForm.controls).forEach((key) => {
      if (
        Object.prototype.hasOwnProperty.call(this.boat, key) &&
        this.boatForm.get(key) &&
        this.boatForm.get(key).enabled
      ) {
        switch (key) {
          case 'boatCustomers':
            this.boat[key]
              .filter((b) => b.type === CustomerType.Owner)
              .forEach((b) => {
                this.addCustomer(b);
              });
            this.boat[key]
              .filter((b) => b.type !== CustomerType.Owner)
              .forEach((b) => {
                this.addAssociated(b);
              });
            break;
          case 'boatServices':
            this.boat[key].forEach((s) => {
              this.associateServiceBoat(s);
            });
            break;
          case 'boatInsurer':
            if (this.boat[key] !== null) {
              this.boatForm.get(key).patchValue(this.boat[key]);
            } else {
              this.onInsurerType();
              return;
            }
            break;
          case 'operator':
            if (this.boat[key] !== null) {
              this.boatForm.get(key).patchValue(this.boat[key].id);
              this.getMarinaUserSelected();
            }
            break;
          case 'vacancy':
            if (this.boat.vacancy !== null && this.boat.vacancy.id != null) {
              const vacancyActive = this.vacancies.find(
                (item) => item?.value?.id == this.boat.vacancy.id
              );
              if (vacancyActive == undefined) {
                this.vacancies.push({
                  label: this.boat.vacancy.code,
                  value: this.boat.vacancy,
                });
              }
              this.boatForm.get(key).patchValue(this.boat[key]);
            }
            break;
          case 'movementGroup':
            if (this.boat.movementGroup?.id != null) {
              const movGroup = this.movementGroupOptions?.find(
                (item) => item.value?.id == this.boat.movementGroup.id
              );
              if (movGroup != undefined) {
                this.boatForm.get('movementGroup').patchValue(movGroup.value);
                this.boatForm.get('movementGroup').setValue(movGroup.value);
              }
            }
            break;
          case 'franchiseGroup':
            if (this.boat.franchiseGroup?.id != null) {
              const franchiseGroup = this.franchiseGroups?.find(
                (item) => item.id == this.boat.franchiseGroup.id
              );
              if (this.boat.franchiseGroup != undefined) {
                this.boatForm.get('franchiseGroup').patchValue(franchiseGroup);
                this.boatForm.get('franchiseGroup').setValue(franchiseGroup);
              }
            }

            break;
          case 'shipyardModel':
            this.boatForm
              .get('shipyardModel')
              .get('shipyard')
              .patchValue(this.boat.shipyardModel.shipyard);
            this.boatForm
              .get('shipyardModel')
              .get('id')
              .patchValue(this.boat.shipyardModel.id);
            this.boatForm
              .get('shipyardModel')
              .get('shipType')
              .patchValue(this.boat.shipyardModel.shipType);
            this.boatForm
              .get('shipyardModel')
              .get('name')
              .patchValue(this.boat.shipyardModel.name);
            this.boatForm
              .get('shipyardModel')
              .patchValue(this.boat.shipyardModel);
            break;
          default:
            this.boatForm.get(key).patchValue(this.boat[key]);
        }
      }
    });
  }

  isFormSent(form: UntypedFormGroup): { [key: string]: boolean } | null {
    let obj = null;
    Object.keys(form.value).forEach((key) => {
      if (form.value[key]) {
        obj = { formNotSent: true };
      }
    });
    return obj;
  }

  addCustomer(cb?: CustomerBoat): void {
    if (!cb) {
      cb = new CustomerBoat();
    }
    const controls = [
      ...(this.boatForm.get('owners') as UntypedFormArray).controls,
    ];
    controls.push(
      new UntypedFormGroup({
        id: new UntypedFormControl(cb.customer.id, Validators.required),
        name: new UntypedFormControl(cb.customer.name, Validators.required),
        federalId: new UntypedFormControl(
          cb.customer.federalId,
          Validators.required
        ),
        sharedInvoiceCustomerApp: new UntypedFormControl(
          cb.sharedInvoiceCustomerApp ? true : false,
          Validators.required
        ),
        viewHistory: new UntypedFormControl(
          cb.viewHistory,
          Validators.required
        ),
        email: new UntypedFormControl(cb.customer.email, Validators.required),
        phone: new UntypedFormControl(cb.customer.phone, Validators.required),
        quota: new UntypedFormControl(this.getQuota(cb), Validators.required),
        customer: new UntypedFormControl(cb.customer, Validators.required),
        type: new UntypedFormControl(CustomerType.Owner),
      })
    );
    this.boatForm.setControl('owners', new UntypedFormArray([...controls]));
  }

  addAssociated(cb?: CustomerBoat): void {
    if (!cb) {
      cb = new CustomerBoat();
    }
    const controls = [
      ...(this.boatForm.get('associateds') as UntypedFormArray).controls,
    ];
    controls.push(
      new UntypedFormGroup({
        id: new UntypedFormControl(cb.customer.id),
        name: new UntypedFormControl(cb.customer.name, Validators.required),
        federalId: new UntypedFormControl(
          cb.customer.federalId,
          Validators.required
        ),
        viewHistory: new UntypedFormControl(
          cb.viewHistory,
          Validators.required
        ),
        email: new UntypedFormControl(cb.customer.email, Validators.required),
        phone: new UntypedFormControl(cb.customer.phone, Validators.required),
        associatedOwner: new UntypedFormControl(
          cb.associatedOwner ? cb.associatedOwner.id : null,
          Validators.required
        ),
        customer: new UntypedFormControl(cb.customer),
        type: new UntypedFormControl(CustomerType.User),
      })
    );
    this.boatForm.setControl(
      'associateds',
      new UntypedFormArray([...controls])
    );
  }

  deleteOwnerBoat(index: number): void {
    const associateds = (this.boatForm.get('associateds') as UntypedFormArray)
      .controls;
    if (
      associateds.length > 0 &&
      associateds.filter((c) => c.value).length > 0 &&
      associateds.find(
        (c) =>
          c.value.associatedOwner ===
          (this.boatForm.get('owners') as UntypedFormArray).controls[index]
            .value.id
      )
    ) {
      this.messageUtil.generateMessage(
        'warning',
        'SUMMARY.WARNING',
        'MSG.AUTHORIZED-BOAT-ASSOCIATED'
      );
      return;
    }
    const controls = [
      ...(this.boatForm.get('owners') as UntypedFormArray).controls,
    ];
    controls.splice(index, 1);
    this.boatForm.setControl('owners', new UntypedFormArray([...controls]));
    this.loadCustomers().subscribe();
    this.calculateQuota();
  }

  deleteAssociatedBoat(index: number): void {
    const controls = [
      ...(this.boatForm.get('associateds') as UntypedFormArray).controls,
    ];
    controls.splice(index, 1);
    this.boatForm.setControl(
      'associateds',
      new UntypedFormArray([...controls])
    );
    this.loadCustomers().subscribe();
  }

  deleteBoatService(index: number): void {
    const boatService = this.listBoatServicesActive()[index];
    if (boatService.value.id) {
      if (this.verifyDeleteExistingBoatService(boatService.getRawValue())) {
        boatService.get('active').patchValue(false);
      }
    } else {
      const services = this.boatForm.get('boatServices') as UntypedFormArray;
      const indexInForm = services.controls.findIndex(
        (bs) => bs.value === boatService.getRawValue()
      );
      services.removeAt(indexInForm);
    }
  }

  verifyDeleteExistingBoatService(boatService): boolean {
    if (!boatService.contractEndDate) {
      this.messageUtil.generateMessage(
        'warning',
        'SUMMARY.WARNING',
        'MSG.DELETE-SERVICE-WITHOUT-END-DATE'
      );
      return false;
    } else if (
      moment(boatService.contractEndDate).isBefore(
        moment(boatService.contractStartDate)
      )
    ) {
      this.messageUtil.generateMessage(
        'warning',
        'SUMMARY.WARNING',
        'MSG.END-DATE-BEFORE-START-DATE'
      );
      return false;
    }
    return true;
  }

  hasBoatService(): boolean {
    return (
      this.boatForm?.get('boatServices') &&
      (this.boatForm.get('boatServices') as UntypedFormArray).controls.filter(
        (boatService) => boatService.value.active
      ).length > 0
    );
  }

  listBoatServicesActive(): AbstractControl<any>[] {
    return (
      this.boatForm.get('boatServices') as UntypedFormArray
    ).controls.filter((boatService) => boatService.value.active);
  }

  filterAssociateds(val: any): void {
    this.filteredAssociateds = val
      ? this.associateds.filter(
          (c) =>
            !c.value ||
            c.value.name.toUpperCase().includes(val.query.toUpperCase()) ||
            c.value.federalId.toUpperCase().includes(val.query.toUpperCase())
        )
      : this.associateds;
  }

  onKeyUp($event): void {
    if ($event.srcElement.value) {
      this.associateds[this.associateds.length - 1].label =
        '+ ' + $event.srcElement.value;
    }
  }

  async loadOwners(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      const owners = this.customers.filter(
        (owner) =>
          (owner.commercialAddress || owner.residentialAddress) &&
          !this.getBoatOwners().find((b) => b.id === owner.id) &&
          !this.getBoatAssociateds().find((b) => b.id === owner.id)
      );
      this.owners = owners.map((o) => ({
        label: this.getCustomerLabel(o),
        value: o.id,
      }));

      resolve();
    });
  }

  async loadAssociateds(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      const owners = this.customers.filter(
        (owner) => !this.getBoatOwners().find((b) => b.id === owner.id)
      );
      this.associateds = owners
        .filter((d) => !this.getBoatAssociateds().find((b) => b.id === d.id))
        .map((a) => ({ label: a.name, value: a }))
        .concat([{ label: '+', value: null }]);
      resolve();
    });
  }

  getQuota(cb?: CustomerBoat): number {
    if (cb && cb.customer.id) {
      return cb.quota;
    } else {
      return (this.boatForm.get('owners') as UntypedFormArray).controls
        .length >= 1
        ? 0
        : 100;
    }
  }

  verifyContract(): void {
    if (!this.boat.id || !this.slingConfig.hasContracts) {
      return;
    }

    const pagination: PaginationFilter = {
      size: 100,
      page: 0,
      order: 'DESC',
      sort: 'createdAt',
    };

    this.contractDocumentService
      .getDocuments({ boatId: this.boat.id, serviceActive: true }, pagination)
      .subscribe(
        (data) => {
          this.documentContracts = [];
          this.documentContractsHistory = [];
          data.content.map((d) => {
            if (d.status === DocumentStatus.CANCELED || d.hasNewer > 0) {
              this.documentContractsHistory.push(d);
            } else {
              this.documentContracts.push(d);
            }
          });
        },
        (error) => console.log(error)
      );
  }

  contractAlert(): void {
    this.spinner.hide();
    if (this.documentContracts.length > 0) {
      Swal.fire({
        title: this.messageUtil.translateKey('SUMMARY.WARNING'),
        icon: 'warning',
        text: 'Este registro possui contratos. Verifique se eles serão afetados.',
        showCancelButton: true,
        width: 600,
        showDenyButton: true,
        showConfirmButton: true,
        focusDeny: true,
        reverseButtons: true,
        denyButtonText: `Gerenciar contratos`,
        confirmButtonText: 'Salvar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.saveVerifiedBoat();
        } else if (result.isDenied) {
          this.managerContract();
        }
      });
    } else {
      this.saveVerifiedBoat();
    }
  }

  managerContract(): void {
    this.dialog.open(VerifyContractsDialogComponent, {
      data: {
        documents: this.documentContracts,
      },
      header: 'Gerenciar contratos',
    });
  }

  activateBoat(): void {
    Swal.fire({
      title: this.messageUtil.translateKey('CONFIRMATION'),
      text: this.messageUtil.translateKey('BOAT-STATUS-WILL-CHANGE'),
      icon: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((result) => {
      if (result.value) {
        this.boat.active = true;
        this.toasty.success(
          'Embarcação ativada com sucesso. Insira o serviço desejado.'
        );
      }
    });
  }

  saveVerifiedBoat(): void {
    this.spinner.show();
    this.boatService.update(this.boat).subscribe(
      () => {
        this.toasty.success('Embarcação alterada com sucesso.');
        if (this.router.url.includes('new-budget-boat')) {
          window.parent.postMessage({ type: 'success' }, '*');
        }
        this.dialogRef.close();
      },
      (error) => {
        let errorMsg = error.error?.data?.exception?.message
        if (!errorMsg){
          errorMsg = "Erro desconhecido";
        }
        this.spinner.hide();
        Swal.fire('Falha ao editar!', errorMsg, 'error');
      },
      () => this.spinner.hide()
    );
  }

  exportHistoryPDF() {
    this.dialog.open(BoatHistoryDialogComponent, {
      height: '250px',
      width: '450px',
      dismissableMask: false,
      data: {
        boatId: this.boat.id,
      },
      header: 'Exportação PDF',
    });
  }

  isEditValueByService(serviceBoat: ServiceBoat): boolean {
    return (
      serviceBoat.service.pricingType === PrincingType.SimplePrice.toString() &&
      serviceBoat.service.price.priceFree
    );
  }

  totalServicesValue(): number {
    return this.listBoatServicesActive().reduce((total, s) => {
      const roundedValue = Number(s.value.value.toFixed(2));
      return total + roundedValue;
    }, 0);
  }

  totalServicesDiscount(): number {
    return this.listBoatServicesActive().reduce((total, s) => {
      let discount = s.value.discount;
      if (discount == undefined) discount = 0;
      const roundedValue = Number(discount.toFixed(2));
      return total + roundedValue;
    }, 0);
  }

  totalServicesDueDateDiscount(): number {
    return this.listBoatServicesActive().reduce((total, s) => {
      let dueDateDiscount = s.value.dueDateDiscount;
      if (dueDateDiscount == undefined) dueDateDiscount = 0;
      const roundedValue = Number(dueDateDiscount.toFixed(2));
      return total + roundedValue;
    }, 0);
  }

  totalServicesTotal(): number {
    return this.listBoatServicesActive()
      .map((s) => this.calcTotalService(s.getRawValue()))
      .reduce((total, totalService) => {
        const roundedValue = Number(totalService.toFixed(2));
        return total + roundedValue;
      }, 0);
  }

  totalServicesTotalDueDate(): number {
    return this.listBoatServicesActive()
      .map((s) => this.calcTotalServiceDueDate(s.getRawValue()))
      .reduce((total, totalService) => {
        const roundedValue = Number(totalService.toFixed(2));
        return total + roundedValue;
      }, 0);
  }

  changeCommercialLengthToSuggested() {
    this.boatForm
      .get('commercialLength')
      .setValue(this.getCommercialLengthSuggested());
  }

  getCommercialLengthSuggested() {
    return this.boatForm.get('length').value
      ? (this.boatForm.get('length').value * 3.28).toFixed(2)
      : null;
  }

  openHistoric(serviceSelect: any): void {
    const serviceBoat: ServiceBoatReadjustmentDTO =
      new ServiceBoatReadjustmentDTO();
    serviceBoat.serviceBoatId = serviceSelect.id;
    const dialog = this.dialog.open(ReajustmentHistoricComponent, {
      dismissableMask: false,
      width: '75%',
      data: {
        serviceBoat,
      },
      header: 'Histórico de Reajustes',
    });

    dialog.onClose.subscribe();
  }

  receiveSailor(sailors: any) {
    this.sailorList = sailors.sailorList;
  }

  receiveEngine(engines: any) {
    if (engines !== null && engines.length > 0) {
      this.engineList = engines;
    }
  }

  getMarinaUserSelected() {
    this.userService
      .getById(this.boatForm.get('operator').value)
      .subscribe((operator) => (this.operatorSelected = operator));
  }
}
