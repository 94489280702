<div class="dialog-form">

  <div style="padding-top: 15px;">
      <p-fieldset>
          <p-header>
              <div class="article-title">Integração com as Catracas. Esta tela pode ser fechada.</div>
          </p-header>

          <div class="table">
              <p-table [value]="points" [alwaysShowPaginator]="false" autoLayout="true"
              lazy="false"
                  [paginator]="false" styleClass="easy-table">

                  <ng-template pTemplate="header">
                      <tr>
                          <th >
                              Catraca

                          </th>
                          <th>
                              Status
                          </th>
                          <th>
                              Re-enviar
                          </th>

                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-ponto>
                      <tr>
                          <td>
                              {{ponto?.catraca?.name}}
                          </td>
                          <td>
                              <i
                                *ngIf="ponto?.status == null"
                                class="pi pi-spin pi-spinner"
                                style="color: navy; font-size: 1.2rem;"
                              ></i>
                              <i
                                *ngIf="ponto?.status == true"
                                class="pi pi-check"
                                style="color: green; font-size: 1.2rem;"
                              ></i>
                              <i
                                *ngIf="ponto?.status == false"
                                class="pi pi-times"
                                style="color: red; font-size: 1.2rem;"
                              ></i>
                          </td>
                          <td>
                              <button pButton type="button" label="Reenviar" (click)="reenviar(ponto)" class="ui-button-danger"></button>
                          </td>

                      </tr>
                  </ng-template>
              </p-table>
          </div>
      </p-fieldset>
  </div>

</div>
