<div
  class="dialog-form"
  #calendarWrapper
  id="calendarWrapper"
  stop-click-propagation
>
  <form (submit)="save()" [formGroup]="boatForm" *ngIf="boatForm">
    <p-tabView [activeIndex]="selectTab" class="boat-forms">
      <p-tabPanel header="{{ 'GENERAL-DATA' | translate }}">
        <p-fieldset>
          <p-header class="article-title">
            <div class="article-title">{{ "GENERAL-DATA" | translate }}</div>
          </p-header>
          <div class="form-group general">
            <div
              class="form-control"
              pTooltip="Id do barco"
              tooltipPosition="bottom"
              *ngIf="boat?.id"
              style="max-width: 70px"
            >
              <label>Id</label>
              <input
                pInputText
                type="text"
                name="id"
                formControlName="id"
                readonly
              />
            </div>
            <div
              class="form-control"
              pTooltip="Nome da embarcação"
              tooltipPosition="bottom"
              style="max-width: 580px"
            >
              <label>{{ "NAME" | translate }} *</label>
              <input
                pInputText
                type="text"
                name="name"
                formControlName="name"
                [pTooltip]="getErrorTooltip(boatForm?.get('name'))"
                tooltipPosition="bottom"
              />
            </div>
            <div
              class="form-control"
              pTooltip="Perfil de embarcação"
              tooltipPosition="bottom"
              style="max-width: 160px"
            >
              <label>{{ "SHIP-ATT.PROFILE" | translate }}*</label>
              <p-dropdown
                [options]="boatProfiles"
                [placeholder]="Selecione"
                name="boatProfile"
                optionLabel="label"
                optionValue="value"
                formControlName="boatProfile"
              >
              </p-dropdown>
            </div>
            <div
              *ngIf="
                !isMovimentOperation && boatProfileSelected === 'RECURRENT'
              "
              class="form-control"
              pTooltip="Vaga onde a embarcação fica guardada"
              tooltipPosition="bottom"
            >
              <label>{{ "VACANCY" | translate }} </label>
              <p-dropdown
                appendTo="body"
                [filter]="true"
                [options]="vacancies"
                formControlName="vacancy"
                name="boatVacancy"
                [pTooltip]="getErrorTooltip(boatForm?.get('vacancy'))"
                optionValue="value"
                optionLabel="label"
                tooltipPosition="bottom"
              >
              </p-dropdown>
            </div>
            <div
              *ngIf="
                !isMovimentOperation && boatProfileSelected === 'RECURRENT'
              "
              class="form-control"
              pTooltip="Tipo de local onde a embarcação fica guardada"
              tooltipPosition="bottom"
            >
              <label>{{ "Vaga molhada" | translate }} </label>
              <p-inputSwitch
                formControlName="wetVacancy"
                name="wetVacancy"
                pTooltip="A embaração fica em uma vaga molhada?"
                tooltipPosition="bottom"
              >
              </p-inputSwitch>
            </div>
            <div
              *ngIf="this.isFranchise"
              pTooltip="Grupo de franquia"
              tooltipPosition="bottom"
              class="form-control"
            >
              <label>{{ "Grupo de Franquia" | translate }} </label>
              <p-dropdown
                [options]="franchiseGroups"
                name="franchiseGroup"
                optionLabel="name"
                formControlName="franchiseGroup"
                required
              ></p-dropdown>
            </div>
          </div>

          <div class="form-group">
            <div
              class="form-control"
              *ngIf="boatProfileSelected === 'RECURRENT' && isMovimentOperation"
              pTooltip="Vaga onde a embarcação fica guardada"
              tooltipPosition="bottom"
            >
              <label>{{ "LOCATION" | translate }} *</label>
              <p-dropdown
                appendTo="body"
                [filter]="true"
                [options]="movementLocations"
                formControlName="movementLocation"
                optionValue="value"
                name="boatLocation"
                [pTooltip]="getErrorTooltip(boatForm?.get('movementLocation'))"
                tooltipPosition="bottom"
                optionValue="value"
              >
              </p-dropdown>
            </div>
            <div
              class="form-control"
              *ngIf="boatProfileSelected === 'RECURRENT' && isMovimentOperation"
              pTooltip="Vaga onde a embarcação fica guardada"
              tooltipPosition="bottom"
            >
              <label>{{ "VACANCY" | translate }} *</label>
              <p-dropdown
                appendTo="body"
                [filter]="true"
                [options]="vacanciesByMovementLocations"
                formControlName="vacancy"
                name="boatVacancy"
                [pTooltip]="getErrorTooltip(boatForm?.get('vacancy'))"
                tooltipPosition="bottom"
              >
              </p-dropdown>
            </div>
            <div class="form-control" *ngIf="isMovimentOperation">
              <label for="movementGroup"
                >{{ "Grupo de movimentação" | translate }} *</label
              >
              <p-dropdown
                [options]="movementGroupOptions"
                optionLabel="label"
                optionValue="value"
                [placeholder]="'Grupo de movimentação' | translate"
                formControlName="movementGroup"
              ></p-dropdown>
            </div>
          </div>
        </p-fieldset>
        <br />
        <p-fieldset>
          <p-header class="article-title">
            <div class="article-title">{{ "OWNERS" | translate }}</div>
          </p-header>
          <div class="table" *ngIf="boatForm" formArrayName="owners">
            <p-table
              [value]="boatForm.get('owners').controls"
              [rows]="5"
              [paginator]="true"
              autoLayout="true"
              alwaysShowPaginator="false"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th>{{ "NAME" | translate }}</th>
                  <th>{{ "FEDERAL-ID.GENERAL" | translate }}</th>
                  <th>{{ "EMAIL" | translate }}</th>
                  <th>{{ "CELLPHONE" | translate }}</th>
                  <th>{{ "SHARE-INVOICE" | translate }}</th>
                  <th>{{ "VIEW-HISTORY" | translate }}</th>
                  <th>{{ "QUOTA" | translate }}</th>
                  <th>
                    <button
                      class="fab-button primary"
                      type="button"
                      (click)="addCustomer()"
                      pTooltip="Adicionar cliente como proprietário do barco"
                      tooltipPosition="bottom"
                    >
                      <i class="pi pi-plus"></i>
                    </button>
                  </th>
                </tr>
              </ng-template>
              <ng-template
                pTemplate="body"
                let-boatCustomer
                let-rowIndex="rowIndex"
              >
                <tr [formGroupName]="rowIndex">
                  <td>
                    <p-dropdown
                      appendTo="body"
                      [options]="owners"
                      name="owner"
                      formControlName="id"
                      placeholder="Selecione..."
                      (onChange)="selectOwner(rowIndex, $event)"
                      [filter]="true"
                      [pTooltip]="getErrorTooltip(boatForm?.get('owner.name'))"
                      tooltipPosition="bottom"
                      *ngIf="
                        !boatForm.get('owners').controls[rowIndex].get('id')
                          .value
                      "
                    >
                    </p-dropdown>
                    <span
                      *ngIf="
                        boatForm.get('owners').controls[rowIndex].get('id')
                          .value
                      "
                    >
                      {{ boatCustomer?.value?.name }}
                    </span>
                    <a
                      *ngIf="
                        boatForm.get('owners').controls[rowIndex].get('id')
                          .value
                      "
                      (click)="closeaDialog()"
                      [routerLink]="['/app/forms/customer']"
                      [queryParams]="{
                        customerId: boatForm
                          .get('owners')
                          .controls[rowIndex].get('id').value
                      }"
                    >
                      <i class="pi pi-eye"></i>
                    </a>
                  </td>
                  <td>
                    {{ boatCustomer?.value?.federalId }}
                  </td>
                  <td>
                    {{ boatCustomer?.value?.email }}
                  </td>
                  <td>
                    {{ boatCustomer?.value?.phone }}
                  </td>
                  <td class="text-center">
                    <p-inputSwitch
                      formControlName="sharedInvoiceCustomerApp"
                      name="sharedInvoiceCustomerApp"
                      pTooltip="Compartilhar a fatura deste cliente com os demais proprietários?"
                      tooltipPosition="bottom"
                    >
                    </p-inputSwitch>
                  </td>
                  <td class="text-center" *ngIf="!isMovimentOperation">
                    <p-inputSwitch
                      formControlName="viewHistory"
                      name="sharedInvoiceCustomerApp"
                      pTooltip="Permitir visualização do histórico da embarcação?"
                      tooltipPosition="bottom"
                    >
                    </p-inputSwitch>
                  </td>
                  <td>
                    <input
                      pInputText
                      type="number"
                      min="0"
                      placeholder=""
                      max="100"
                      formControlName="quota"
                      name="quota"
                      (blur)="calculateQuota()"
                    />
                    %
                  </td>
                  <td>
                    <div
                      class="table-action"
                      (click)="deleteOwnerBoat(rowIndex)"
                      pTooltip="Remover cliente proprietário da embarcação"
                    >
                      <i class="pi pi-trash"></i>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <div *ngIf="invalidQuota" class="alert alert-danger">
              {{ "QUOTA-MUSTBE100-MESSAGE" | translate }}
            </div>
          </div>
        </p-fieldset>
        <br />
        <p-fieldset>
          <p-header class="article-title">
            <div class="article-title">{{ "AUTHORIZED" | translate }}</div>
          </p-header>
          <div class="table" formArrayName="associateds">
            <p-table
              [value]="boatForm.get('associateds').controls"
              [rows]="5"
              [paginator]="true"
              autoLayout="true"
              alwaysShowPaginator="false"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th>{{ "NAME" | translate }}</th>
                  <th></th>
                  <th>{{ "FEDERAL-ID.GENERAL" | translate }}</th>
                  <th>{{ "EMAIL" | translate }}</th>
                  <th>{{ "CELLPHONE" | translate }}</th>
                  <th>{{ "VIEW-HISTORY" | translate }}</th>
                  <th>{{ "ASSOCIATED-OWNER" | translate }}</th>
                  <th>
                    <button
                      class="fab-button primary"
                      type="button"
                      (click)="addAssociated()"
                      pTooltip="Adicionar cliente como autorizado do barco"
                      tooltipPosition="bottom"
                    >
                      <i class="pi pi-plus"></i>
                    </button>
                  </th>
                </tr>
              </ng-template>
              <ng-template
                pTemplate="body"
                let-boatCustomer
                let-rowIndex="rowIndex"
              >
                <tr [formGroupName]="rowIndex">
                  <td>
                    <p-dropdown
                      appendTo="body"
                      [options]="filteredAssociateds"
                      name="name"
                      formControlName="name"
                      placeholder="Selecione..."
                      (onChange)="selectAssociated(rowIndex, $event)"
                      [filter]="true"
                      tooltipPosition="bottom"
                      *ngIf="
                        !boatForm
                          .get('associateds')
                          .controls[rowIndex].get('id').value
                      "
                    >
                    </p-dropdown>
                    <span
                      *ngIf="
                        boatForm.get('associateds').controls[rowIndex].get('id')
                          .value
                      "
                    >
                      {{ boatCustomer?.value?.name }}
                    </span>
                  </td>
                  <td>
                    <a
                      (click)="closeaDialog()"
                      *ngIf="getAssociatedId(rowIndex)"
                      [routerLink]="['/app/forms/customer']"
                      [queryParams]="{ customerId: getAssociatedId(rowIndex) }"
                    >
                      <i class="pi pi-eye"></i>
                    </a>
                  </td>
                  <td>
                    {{ boatCustomer?.value?.federalId }}
                  </td>
                  <td>
                    {{ boatCustomer?.value?.email }}
                  </td>
                  <td>
                    {{ boatCustomer?.value?.phone }}
                  </td>
                  <td class="text-center">
                    <p-inputSwitch
                      formControlName="viewHistory"
                      name="sharedInvoiceCustomerApp"
                      pTooltip="Permitir visualização do histórico da embarcação?"
                      tooltipPosition="bottom"
                    >
                    </p-inputSwitch>
                  </td>
                  <td>
                    <p-dropdown
                      appendTo="body"
                      [options]="getBoatOwnersDropdown()"
                      name="associatedOwner"
                      placeholder="Selecione..."
                      formControlName="associatedOwner"
                    >
                    </p-dropdown>
                  </td>
                  <td>
                    <div
                      class="table-action"
                      (click)="deleteAssociatedBoat(rowIndex)"
                      pTooltip="Remover cliente autorizado da embarcação"
                    >
                      <i class="pi pi-trash"></i>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-fieldset>
        <br />
        <p-fieldset *ngIf="slingConfig?.hasSailor === true">
          <p-header class="article-title">
            <div class="article-title">{{ "SAILORS" | translate }}</div>
          </p-header>
          <app-boat-sailors-table
            [boat]="boat"
            (finishEvent)="receiveSailor($event)"
          ></app-boat-sailors-table>
        </p-fieldset>
        <br />
        <p-fieldset class="hull">
          <p-header class="article-title">
            <div class="article-title">{{ "HULL" | translate }}</div>
          </p-header>
          <div class="form-group">
            <ng-container formGroupName="shipyardModel">
              <div class="form-control">
                <label for="shipType">{{ "TYPE" | translate }} *</label>
                <p-dropdown
                  [options]="shipTypeOptions"
                  name="shipType"
                  formControlName="shipType"
                  (onChange)="shipyardType($event)"
                  [filter]="true"
                  appendTo="body"
                  placeholder="Selecione..."
                  pTooltip="Tipo da embarcação"
                  tooltipPosition="bottom"
                >
                </p-dropdown>
              </div>
              <div class="form-control">
                <label for="shipyard">{{ "SHIPYARD" | translate }} *</label>
                <p-dropdown
                  [options]="shipyards"
                  name="shipyard"
                  (onChange)="selectShipyard($event)"
                  optionLabel="label"
                  optionValue="value"
                  [filter]="true"
                  placeholder="Selecione..."
                  pTooltip="Fabricante da embarcação"
                  appendTo="body"
                  tooltipPosition="bottom"
                  formControlName="shipyard"
                ></p-dropdown>
              </div>

              <div class="form-control">
                <label for="shipyardModel">{{ "MODEL" | translate }} *</label>
                <p-dropdown
                  [options]="shipyardModels"
                  name="shipyardModel"
                  formControlName="id"
                  [filter]="true"
                  placeholder="Selecione..."
                  [pTooltip]="
                    shipyardSelected
                      ? 'Modelo da embarcação'
                      : 'Selecione o Tipo* e Estaleiro* da embarcação'
                  "
                  (onChange)="setBoatHullSpecification($event)"
                  appendTo="body"
                  optionLabel="label"
                  optionValue="value"
                  tooltipPosition="bottom"
                >
                </p-dropdown>
              </div>
            </ng-container>
            <div class="form-control">
              <label for="beam">{{ "SHIP-ATT.BEAM" | translate }}</label>
              <p-inputNumber
                name="beam"
                formControlName="beam"
                mode="decimal"
                [minFractionDigits]="2"
                [maxFractionDigits]="2"
                pTooltip="Medida de um bordo a outro da embarcação em metros"
                tooltipPosition="bottom"
              >
              </p-inputNumber>
            </div>
            <div class="form-control">
              <label for="draft">{{ "SHIP-ATT.DRAFT" | translate }}</label>
              <p-inputNumber
                name="draft"
                formControlName="draft"
                mode="decimal"
                [minFractionDigits]="2"
                [maxFractionDigits]="2"
                pTooltip="Distância da lâmina de água até a quilha do navio"
                tooltipPosition="bottom"
              >
              </p-inputNumber>
            </div>
            <div class="form-control">
              <label for="length">{{ "SHIP-ATT.LENGTH" | translate }}</label>
              <p-inputNumber
                name="length"
                formControlName="length"
                mode="decimal"
                [minFractionDigits]="2"
                [maxFractionDigits]="2"
                pTooltip="Comprimento da embarcação em metros"
                tooltipPosition="bottom"
              >
              </p-inputNumber>
            </div>
          </div>
          <div class="form-group">
            <div class="form-control">
              <div class="label-commercial-length">
                <label for="commercialLength"
                  >{{ "SHIP-ATT.COMMERCIAL-LENGTH" | translate }} *</label
                >
                <a
                  class="btn"
                  (click)="changeCommercialLengthToSuggested()"
                  *ngIf="getCommercialLengthSuggested()"
                >
                  <label
                    style="color: rgb(38, 199, 38); cursor: pointer"
                    pTooltip="Cálculo utilizado: Comprimento * 3,28"
                    tooltipPosition="bottom"
                  >
                    {{ getCommercialLengthSuggested() | number : "1.2-2" }}
                  </label>
                </a>
              </div>
              <p-inputNumber
                name="commercialLength"
                formControlName="commercialLength"
                mode="decimal"
                [minFractionDigits]="2"
                [maxFractionDigits]="2"
                pTooltip="Tamanho comercial da embarcação em pés"
                tooltipPosition="bottom"
                (onInput)="onInputCommercialLength()"
              >
              </p-inputNumber>
            </div>
            <div class="form-control">
              <label for="manufactureYear"
                >{{ "SHIP-ATT.MANUFACTURE-YEAR" | translate }} *</label
              >
              <p-dropdown
                [options]="lastHundredYears"
                name="manufactureYear"
                appendTo="body"
                formControlName="manufactureYear"
                pTooltip="Ano de fabricação da embarcação"
                tooltipPosition="bottom"
                placeholder="Selecione..."
              >
              </p-dropdown>
            </div>
            <div class="form-control">
              <label for="modelYear"
                >{{ "SHIP-ATT.MODEL-YEAR" | translate }} *</label
              >
              <p-dropdown
                [options]="lastHundredYears"
                name="modelYear"
                appendTo="body"
                formControlName="modelYear"
                pTooltip="Ano do modelo da embarcação"
                tooltipPosition="bottom"
                placeholder="Selecione..."
              >
              </p-dropdown>
            </div>
            <div class="form-control">
              <label for="mainColor"
                >{{ "SHIP-ATT.MAIN-COLOR" | translate }} *</label
              >
              <input
                pInputText
                name="mainColor"
                formControlName="mainColor"
                pTooltip="Cor principal da embarcação"
                tooltipPosition="bottom"
              />
            </div>
            <div class="form-control">
              <label for="crew">{{ "Nº Tripulantes" | translate }} *</label>
              <input
                pInputText
                name="crew"
                formControlName="crew"
                type="number"
                pTooltip="Quantidade máxima de tripulantes (operadores) da embarcação"
                [min]="1"
                tooltipPosition="bottom"
              />
            </div>
            <div class="form-control">
              <label for="passengersDay"
                >{{ "Nº Passageiros" | translate }} *</label
              >
              <input
                pInputText
                name="passengersDay"
                formControlName="passengersDay"
                type="number"
                [min]="1"
                pTooltip="Quantidade máxima de passageiros permitidos na embarcação (não inclui tripulantes)"
                tooltipPosition="bottom"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="form-control">
              <label for="passengersNight">{{
                "SHIP-ATT.PASSENGERS-NIGHT" | translate
              }}</label>
              <input
                pInputText
                name="passengersNight"
                formControlName="passengersNight"
                type="number"
                pTooltip="Quantidade máxima de passageiros permitidos ao navegar à noite com a embarcação"
                tooltipPosition="bottom"
              />
            </div>
            <div class="form-control">
              <label for="cabins">{{ "SHIP-ATT.CABINS" | translate }}</label>
              <input
                pInputText
                name="cabins"
                formControlName="cabins"
                type="number"
                pTooltip="Quantidade de cabines da embarcação"
                tooltipPosition="bottom"
              />
            </div>
            <div class="form-control">
              <label for="cabins">{{ "CHASSIS" | translate }}</label>
              <p-inputMask
                type="text"
                mask="**-************"
                name="chassiHull"
                formControlName="chassiHull"
                tooltipPosition="bottom"
                pTooltip="Números de identificação do casco (Cód. País - nº série). Ex.: BR-XXXXXXXXXXXX"
              >
              </p-inputMask>
            </div>
            <div class="form-control">
              <label for="bathrooms">{{
                "SHIP-ATT.BATHROOMS" | translate
              }}</label>
              <input
                pInputText
                name="bathrooms"
                formControlName="bathrooms"
                type="number"
                pTooltip="Quantidade de banheiros da embarcação"
                tooltipPosition="bottom"
              />
            </div>
            <div class="form-control">
              <label for="bowType"
                >{{ "SHIP-ATT.BOW-TYPE" | translate }} *</label
              >
              <p-dropdown
                appendTo="body"
                [options]="[
                  { label: 'Aberto', value: 'Open' },
                  { label: 'Fechado', value: 'Close' }
                ]"
                name="bowType"
                formControlName="bowType"
                pTooltip="Tipo de proa da embarcação"
                placeholder="Selecione..."
                tooltipPosition="bottom"
              >
              </p-dropdown>
            </div>
            <div class="form-control">
              <label for="fuel">{{ "FUEL" | translate }} *</label>
              <p-dropdown
                [options]="fuels"
                name="fuel"
                formControlName="fuel"
                placeholder="Selecione..."
                pTooltip="Combustível utilizado para abastecimento da embarcação"
                appendTo="body"
                tooltipPosition="bottom"
              >
              </p-dropdown>
            </div>
            <div class="form-control switch">
              <label for="flyBridge">{{
                "SHIP-ATT.FLY-BRIDGE" | translate
              }}</label>
              <p-inputSwitch
                formControlName="flyBridge"
                name="flyBridge"
                pTooltip="Informa se a embarcação possui área aberta acima do cockpit utilizada para pilotar"
                tooltipPosition="bottom"
              >
              </p-inputSwitch>
            </div>
            <div class="form-control switch">
              <label for="twoTimeEngine">{{
                "Motor dois tempos" | translate
              }}</label>
              <p-inputSwitch
                formControlName="twoTimeEngine"
                name="twoTimeEngine"
                pTooltip="Informa se a lancha possui motor dois tempos"
                tooltipPosition="bottom"
              >
              </p-inputSwitch>
            </div>
          </div>
        </p-fieldset>
        <br />
        <p-fieldset *ngIf="slingConfig?.hasSailor === true">
          <p-header class="article-title">
            <div class="article-title">{{ "ENGINES" | translate }}</div>
          </p-header>
          <app-boat-engines-table
            [boat]="boat"
            (finishEngineEvent)="receiveEngine($event)"
          ></app-boat-engines-table>
        </p-fieldset>
        <br />
        <p-fieldset class="full">
          <p-header class="article-title">
            <div class="article-title">{{ "DOCUMENTATION" | translate }}</div>
            <div class="header-buttons">
              <button
                class="fab-button primary"
                pTooltip="Adicionar documento da embarcação"
                tooltipPosition="bottom"
                (click)="newDocumentation()"
                type="button"
                *ngIf="!boatForm.get('tie').enabled"
              >
                <i class="pi pi-plus"></i>
              </button>
              <button
                class="fab-button primary"
                pTooltip="Remover documento da embarcação"
                tooltipPosition="bottom"
                (click)="removeDocumentation()"
                type="button"
                *ngIf="boatForm.get('tie').enabled"
              >
                <i class="pi pi-trash"></i>
              </button>
            </div>
          </p-header>

          <ng-container formGroupName="tie" *ngIf="boatForm.get('tie').enabled">
            <div class="form-group">
              <div class="form-control">
                <label for="register">{{ "REGISTER" | translate }}</label>
                <input
                  pInputText
                  type="text"
                  name="register"
                  formControlName="register"
                  pTooltip="Número de registro da embarcação"
                  tooltipPosition="bottom"
                />
              </div>
              <div class="form-control">
                <label for="boatNf">{{ "Nota Fiscal" | translate }}</label>
                <input
                  pInputText
                  type="text"
                  name="boatNf"
                  formControlName="boatNf"
                  pTooltip="Nota fiscal da embarcação"
                  tooltipPosition="bottom"
                />
              </div>
              <div class="form-control">
                <label for="expiration">{{ "Validade" | translate }}</label>
                <p-calendar
                  dateFormat="dd/mm/yy"
                  name="expiration"
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  [yearRange]="'2000:2055'"
                  rPCalendarMask
                  formControlName="expiration"
                  [showIcon]="true"
                  i18n
                  pTooltip="Date de expiração do documento da embarcação"
                  tooltipPosition="bottom"
                  appendTo="body"
                >
                </p-calendar>
              </div>
              <div class="form-control">
                <label for="state">{{ "STATE" | translate }}</label>
                <p-dropdown
                  placeholder="Selecione..."
                  [filter]="true"
                  [options]="states"
                  pTooltip="UF do estado de expedição do documento da embarcação"
                  tooltipPosition="bottom"
                  formControlName="state"
                  name="state"
                  (onChange)="loadCities($event.value)"
                  appendTo="body"
                >
                </p-dropdown>
              </div>
              <div class="form-control">
                <label for="city">{{ "CITY" | translate }}</label>
                <p-dropdown
                  placeholder="Selecione..."
                  [filter]="true"
                  [options]="cities"
                  pTooltip="Cidade de expedição do documento da embarcação"
                  tooltipPosition="bottom"
                  optionValue="value"
                  optionLabel="label"
                  formControlName="city"
                  name="city"
                  appendTo="body"
                >
                </p-dropdown>
              </div>
              <div class="form-control">
                <label for="city">{{ "NAVIGATION-AREA" | translate }}</label>
                <p-dropdown
                  placeholder="Selecione..."
                  [options]="navigationAreaOptions"
                  formControlName="navigationArea"
                  appendTo="body"
                  pTooltip="Área de navegação permitida para esta embarcação"
                  tooltipPosition="bottom"
                  name="navigationArea"
                >
                </p-dropdown>
              </div>
            </div>
            <br />
            <div class="form-control">
              <p-fileUpload
                name="attachment"
                mode="basic"
                maxFileSize="100000000"
                [class]="buttonClass"
                chooseLabel="Anexar arquivo"
                accept=".png,.jpg,.jpeg,.pdf"
                class="btn-upload"
                (uploadHandler)="uploadFile($event.files)"
                [auto]="true"
                customUpload="true"
                #upload
                pTooltip="Anexar arquivo do documento da embarcação"
                tooltipPosition="bottom"
              >
              </p-fileUpload>
              <p class="btn-subtitle">
                Arquivos em .PNG, .JPG, .JPEG e .PDF com até 100MB
              </p>
              <p-progressSpinner
                [hidden]="!uploadDocumentationFileInProgress"
                [style]="{ width: '25px', height: '25px' }"
              >
              </p-progressSpinner>

              <img
                width="200"
                *ngIf="
                  boatForm?.get('tie.file').value &&
                  !boatForm.get('tie.file').value?.includes('.pdf')
                "
                [src]="boatForm?.get('tie.file').value"
                title="Documento da embarcação"
                (click)="viewDocumentation(boatForm.get('tie.file').value)"
                name="boatServiceButton"
              />

              <ng-container
                *ngIf="
                  boatForm?.get('tie.file').value &&
                  boatForm?.get('tie.file').value?.includes('.pdf')
                "
              >
                <a [href]="boatForm.get('tie.file').value" target="_blank">
                  <i class="pi pi-file-pdf" style="font-size: 50px"></i>
                </a>
                <a [href]="boatForm.get('tie.file').value" target="_blank">
                  {{ getNameDocumentFile(boatForm.get("tie.file").value) }}
                </a>
              </ng-container>
            </div>
          </ng-container>
        </p-fieldset>
        <br />
        <p-fieldset>
          <p-header class="article-title">
            <div class="article-title">{{ "Seguro" | translate }}</div>
          </p-header>
          <div class="form-group">
            <div class="form-control">
              <label for="insurerType">Segurado</label>
              <p-dropdown
                placeholder="Selecione..."
                [options]="insurerTypes"
                appendTo="body"
                formControlName="insurerType"
                name="insurerType"
                (onChange)="onInsurerType()"
              >
              </p-dropdown>
            </div>
            <ng-container
              formGroupName="boatInsurer"
              *ngIf="boatForm?.get('insurerType').value === 'INSURED'"
            >
              <div class="form-control">
                <label for="policyNumber">N° Apólice</label>
                <input
                  pInputText
                  type="text"
                  name="policyNumber"
                  formControlName="policyNumber"
                  pTooltip="Número da apólice do seguro da embarcação"
                  tooltipPosition="bottom"
                />
              </div>
              <div class="form-control">
                <label for="policyExpirationDate">Vencimento da Apólice*</label>
                <p-calendar
                  dateFormat="dd/mm/yy"
                  name="policyExpirationDate"
                  formControlName="policyExpirationDate"
                  [showIcon]="true"
                  i18n
                  required
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  [yearRange]="'2000:2055'"
                  rPCalendarMask
                  pTooltip="Data de vencimento da apólice do seguro da embarcação"
                  tooltipPosition="bottom"
                  appendTo="body"
                >
                </p-calendar>
              </div>
              <div class="form-control">
                <label for="insurer">Seguradora*</label>
                <p-dropdown
                  placeholder="Selecione..."
                  [filter]="true"
                  [options]="insurers"
                  formControlName="insurer"
                  name="insurer"
                  appendTo="body"
                >
                  <ng-template let-item pTemplate="selectedItem">
                    <img
                      src="{{ item.value.logoFile }}"
                      style="width: 16px; vertical-align: middle"
                    />
                    <span style="vertical-align: middle">{{ item.label }}</span>
                  </ng-template>
                  <ng-template let-insurer pTemplate="item">
                    <div
                      class="ui-helper-clearfix"
                      style="position: relative; height: 25px"
                    >
                      <img
                        src="{{ insurer.value.logoFile }}"
                        style="
                          width: 24px;
                          position: absolute;
                          top: 1px;
                          left: 5px;
                        "
                      />
                      <div
                        style="font-size: 14px; float: right; margin-top: 4px"
                      >
                        {{ insurer.label }}
                      </div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </ng-container>
          </div>
        </p-fieldset>
        <br />
        <p-fieldset>
          <p-header class="article-title">
            <div class="article-title">{{ "Seguro DPEM" | translate }}</div>
          </p-header>
          <div class="form-group">
            <div  class="dpem-container">
              <ng-container
              formGroupName="dpem"
            >
            <div class="form-control">
              <label>Documento seguro DPEM *</label>
              <div class="form-control">
                <p-fileUpload
                  name="attachment"
                  mode="basic"
                  maxFileSize="100000000"
                  [class]="buttonClass1"
                  chooseLabel="Anexar arquivo"
                  accept=".png,.jpg,.jpeg,.pdf"
                  class="btn-upload"
                  (uploadHandler)="uploadFileDpem($event.files)"
                  [auto]="true"
                  customUpload="true"
                  #upload
                  
                  pTooltip="Anexar arquivo do seguro DPEM"
                  tooltipPosition="bottom"
                >
                </p-fileUpload>
                <p class="btn-subtitle">
                  Arquivos em .PNG, .JPG, .JPEG e .PDF com até 100MB
                </p>
                <p-progressSpinner
                  [hidden]="!uploadDocumentationFileInProgress"
                  [style]="{ width: '25px', height: '25px' }"
                >
                </p-progressSpinner>
  
                <img
                  width="200"
                  *ngIf="
                    boatForm?.get('dpem.file').value &&
                    !boatForm.get('dpem.file').value?.includes('.pdf')
                  "
                  [src]="boatForm?.get('dpem.file').value"
                  title="Documento de seguro DPEM"
                  (click)="viewDocumentation(boatForm.get('dpem.file').value)"
                  name="boatServiceButton"
                />
  
                <ng-container
                  *ngIf="
                    boatForm?.get('dpem.file').value &&
                    boatForm?.get('dpem.file').value?.includes('.pdf')
                  "
                >
                  <a [href]="boatForm.get('dpem.file').value" target="_blank">
                    <i class="pi pi-file-pdf" style="font-size: 50px"></i>
                  </a>
                  <a [href]="boatForm.get('dpem.file').value" target="_blank">
                    {{ getNameDocumentFile(boatForm.get("dpem.file").value) }}
                  </a>
                </ng-container>
              </div>
            </div>
              <div class="form-control">
                <label>N° Apólice DPEM *</label>
                <input
                  pInputText
                  type="text"
                  name="dpemNumber"
                  formControlName="policyDpemNumber"
                  pTooltip="Número da apólice do seguro DPEM"
                  tooltipPosition="bottom"
                />
              </div>
              <div class="form-control">
                <label for="dpemExpirationDate">Vencimento da Apólice DPEM *</label>
                <p-calendar
                  dateFormat="dd/mm/yy"
                  name="policyDpemExpirationDate"
                  formControlName="policyDpemExpirationDate"
                  [showIcon]="true"
                  i18n
                  
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  [yearRange]="'2000:2055'"
                  rPCalendarMask
                  pTooltip="Data de vencimento da apólice do seguro DPEM"
                  tooltipPosition="bottom"
                  appendTo="body"
                >
                </p-calendar>
              </div>
            </ng-container>
            </div>
       </div>
        </p-fieldset>
        <br />
        <p-fieldset>
          <p-header class="article-title">
            <div class="article-title">{{ "Operador" | translate }}</div>
          </p-header>
          <div class="form-group">
            <div class="form-control">
              <label for="operator">Operador responsável</label>
              <p-dropdown
                placeholder="Selecione..."
                [options]="marinaUsers"
                appendTo="body"
                formControlName="operator"
                (onChange)="getMarinaUserSelected()"
                name="label"
                [filter]="true"
                optionValue="value"
                optionLabel="label"
              >
              </p-dropdown>
            </div>
          </div>
        </p-fieldset>
      </p-tabPanel>

      <p-tabPanel header="{{ 'BOAT-REGISTRATION' | translate }}">
        <div class="boat-registration">
          <div class="sections flex-column">
            <div class="form-control">
              <label for="active">{{ "SHIP-ATT.ACTIVE" | translate }}</label>
              <p-inputSwitch
                formControlName="active"
                name="active"
                pTooltip="Informa se o barco está ou não ativo."
                tooltipPosition="bottom"
              >
              </p-inputSwitch>
            </div>
            <div class="form-control mt-4">
              <label for="apprehend">{{
                "SHIP-ATT.APPREHEND" | translate
              }}</label>
              <p-inputSwitch
                formControlName="apprehend"
                name="apprehend"
                pTooltip="Informa se o barco está com impedimento"
                tooltipPosition="bottom"
              >
              </p-inputSwitch>
            </div>
          </div>
          <div class="sections">
            <div class="form-control">
              <label for="independentTanks">{{
                "INDEPENDENT-TANKS" | translate
              }}</label>
              <p-inputSwitch
                formControlName="independentTanks"
                name="independentTanks"
                pTooltip="Informa se o barco possui dois motores"
                tooltipPosition="bottom"
              >
              </p-inputSwitch>
            </div>
          </div>
          <div
            class="sections flex-column"
            [ngStyle]="{
              'flex-direction': !isMovimentOperation ? 'column' : 'row'
            }"
          >
            <div class="form-control">
              <label for="maintenance">{{
                "SHIP-ATT.MAINTENANCE" | translate
              }}</label>
              <p-inputSwitch
                formControlName="maintenance"
                name="maintenance"
                pTooltip="Informa se o barco está em manutenção"
                tooltipPosition="bottom"
              >
              </p-inputSwitch>
            </div>
            <div class="form-control mt-4">
              <label>{{ "SLING-APP" | translate }}</label>
              <p-inputSwitch
                formControlName="availableOnApp"
                name="availableOnApp"
                pTooltip="Permitir que o cliente solicite lingadas pelo aplicativo para esse barco"
                tooltipPosition="bottom"
              >
              </p-inputSwitch>
            </div>
          </div>
          <div class="boatWashEngineTurnSection flex-row">
            <div class="sections flex-column">
              <div class="form-control" *ngIf="slingConfig.marineBoatWash">
                <label for="marineWashMyBoat">{{
                  "SHIP-ATT.MARINEWASHBOAT" | translate
                }}</label>
                <p-inputSwitch
                  formControlName="marineWashMyBoat"
                  name="marineWashMyBoat"
                  pTooltip="Informa se a marina deve lavar o barco"
                  tooltipPosition="bottom"
                >
                </p-inputSwitch>
              </div>
              <div
                class="form-control mt-4"
                *ngIf="slingConfig.marineEngineTurnRun"
              >
                <label for="marineRunMyEnginer">{{
                  "SHIP-ATT.MARINEENGINETURN" | translate
                }}</label>
                <p-inputSwitch
                  formControlName="marineRunMyEnginer"
                  name="marineRunMyEnginer"
                  pTooltip="Informa se a marina deve girar o motor"
                  tooltipPosition="bottom"
                >
                </p-inputSwitch>
              </div>
            </div>
            <div class="sections flex-column">
              <div
                class="form-control"
                tooltipPosition="bottom"
                *ngIf="
                  slingConfig.marineBoatWash &&
                  boatForm.get('marineWashMyBoat').value
                "
                pTooltip="Tempo em dias entre agendamentos de lavação"
              >
                <label for="timeBetweenWash">Tempo entre lavações (dias)</label>
                <input
                  pInputText
                  type="number"
                  min="1"
                  name="timeBetweenWash"
                  formControlName="timeBetweenWash"
                />
              </div>
              <div
                class="form-control mt-4"
                tooltipPosition="bottom"
                pTooltip="Tempo em dias entre agendamentos de giro de motor"
                *ngIf="
                  slingConfig.marineEngineTurnRun &&
                  boatForm.get('marineRunMyEnginer').value
                "
              >
                <label for="timeBetweenEngineRotation"
                  >Tempo entre giro de motor (dias)</label
                >
                <input
                  pInputText
                  type="number"
                  name="timeBetweenEngineRotation"
                  formControlName="timeBetweenEngineRotation"
                  min="1"
                />
              </div>
            </div>
          </div>
          <div>
            <div class="sections flex-column">
              <div class="form-control">
                <label for="fullTank">{{
                  "SHIP-ATT.FULLTANK" | translate
                }}</label>
                <p-inputSwitch
                  formControlName="fullTank"
                  name="fullTank"
                  pTooltip="Informa se na subida o barco deve ser abastecido totalmente"
                  tooltipPosition="bottom"
                ></p-inputSwitch>
              </div>
              <div class="form-control mt-4">
                <label for="showAllCustomerData">{{
                  "SHOW-ALL-CUSTOMER-DATA" | translate
                }}</label>
                <p-inputSwitch
                  name="showAllCustomerData"
                  formControlName="showAllCustomerData"
                  tooltipPosition="bottom"
                  pTooltip="Ao desabilitar essa configuração, os dados dos proprietários e autorizados da embarcação ficarão ocultos no aplicativo do cliente"
                ></p-inputSwitch>
              </div>
            </div>
          </div>
        </div>
      </p-tabPanel>

      <p-tabPanel header="{{ 'INVENTORY' | translate }}">
        <p-fieldset>
          <p-header class="article-title">
            <div class="article-title">{{ "OPTIONALS" | translate }}</div>
          </p-header>

          <div class="form-group">
            <ul class="accessories" formArrayName="accessories">
              <li *ngFor="let accessory of optionalAccessoriesList; index as i">
                <div
                  *ngIf="
                    boatForm?.get('accessories').controls &&
                    accessory.accessoryType === 'Optional'
                  "
                  [formGroupName]="i"
                >
                  <p-checkbox
                    name="accessory_{{ accessory.id }}"
                    value="accessory"
                    formControlName="checked"
                    label="{{ accessory.name }}"
                    binary="true"
                    (onChange)="checkAccessory(accessory, $event)"
                  >
                  </p-checkbox>
                  <ng-container
                    *ngIf="
                      boatForm?.get('accessories').controls &&
                      boatForm.get('accessories').controls[i].value?.checked
                    "
                  >
                    <input
                      type="number"
                      name="accessoryQty{{ accessory.id }}"
                      min="1"
                      max="9999"
                      formControlName="qty"
                      style="
                        height: 25px;
                        width: 50px;
                        margin-left: 5px;
                        text-align: center;
                      "
                      (blur)="updateQty($event, accessory)"
                    />
                  </ng-container>
                </div>
              </li>
            </ul>
          </div>
        </p-fieldset>
        <br />
        <p-fieldset class="full">
          <p-header class="article-title">
            <div class="article-title">{{ "ACCESSORIES" | translate }}</div>
            <div class="header-buttons">
              <button
                class="fab-button primary"
                pTooltip="Adicionar acessório"
                tooltipPosition="bottom"
                (click)="openAccessoriesDialog()"
                type="button"
              >
                <i class="pi pi-plus"></i>
              </button>
            </div>
          </p-header>
          <div class="form-group accessories">
            <div
              class="form-control"
              *ngFor="let accessoryBoat of getBoatAccessories()"
            >
              <div
                class="text-center accessoryBoat-card-image"
                (click)="openDialogSeparatedSale(product)"
              >
                <img
                  src="{{ accessoryBoat?.accessory?.urlPhoto }}"
                  class="imagem"
                />
              </div>
              <div class="accessoryBoat-card-description">
                {{ accessoryBoat?.accessory?.name }}
              </div>
              <div class="accessoryBoat-card-description">
                {{ accessoryBoat?.quantity }}
              </div>
              <button
                class="fab-button danger"
                type="button"
                (click)="removeAccessory(accessoryBoat)"
                pTooltip="Remover acessório"
                tooltipPosition="bottom"
              >
                <i class="pi pi-trash"></i>
              </button>
            </div>
          </div>
        </p-fieldset>
      </p-tabPanel>
      <p-tabPanel header="{{ 'SERVICES' | translate }}">
        <p-fieldset>
          <p-header class="article-title">
            <div class="article-title">{{ "SERVICES" | translate }}</div>
          </p-header>
          <div
            class="alert alert-warning text-center"
            *ngIf="boat?.id && !boat.active"
          >
            Não é possível adicionar serviços a embarcações inativas.
            <span
              (click)="activateBoat()"
              class="activateBoat"
              pTooltip="A embarcação será ativada"
              tooltipPosition="top"
            >
              <strong>Ativar</strong></span
            >
            embarcação {{ boat.name }}?
          </div>
          <div
            class="form-group"
            formGroupName="service"
            style="margin-bottom: 15px"
            *ngIf="boat?.id == null || boat.active"
          >
            <div class="form-control">
              <label for="service">{{ "ADD-SERVICE" | translate }}</label>
              <p-dropdown
                placeholder="Selecione..."
                [filter]="true"
                [options]="services"
                appendTo="body"
                formControlName="service"
                name="service"
              >
              </p-dropdown>
            </div>
            <div class="form-control">
              <label for="service">{{
                "Data de inicio do serviço" | translate
              }}</label>
              <p-calendar
                dateFormat="dd/mm/yy"
                name="contractStartDate"
                [monthNavigator]="true"
                [yearNavigator]="true"
                [yearRange]="'2000:2055'"
                rPCalendarMask
                appendTo="body"
                formControlName="contractStartDate"
                [showIcon]="true"
                i18n
                [monthNavigator]="true"
                [yearNavigator]="true"
                [yearRange]="'2000:2055'"
              >
              </p-calendar>
            </div>
            <div class="buttons" style="justify-content: flex-start !important">
              <button
                class="fab-button primary"
                matTooltip="Add service"
                type="button"
                (click)="validateBoatService()"
                name="boatServiceButton"
              >
                <i class="pi pi-plus"></i>
              </button>
            </div>
          </div>
          <div class="table" formArrayName="boatServices">
            <p-table
              *ngIf="hasBoatService()"
              [value]="listBoatServicesActive()"
              autoLayout="true"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th>{{ "SERVICE" | translate }}</th>
                  <th style="min-width: 115px">Inicio serviço</th>
                  <th style="min-width: 115px">Fim serviço</th>
                  <th>{{ "OBSERVATION" | translate }}</th>
                  <th>{{ "VALUE" | translate }}</th>
                  <th>{{ "DISCOUNT-PERC" | translate }}</th>
                  <th>{{ "DISCOUNT" | translate }}</th>
                  <th>{{ "DUE-DATE-DISCOUNT-PERC" | translate }}</th>
                  <th>{{ "DUE-DATE-DISCOUNT" | translate }}</th>
                  <th>{{ "TOTAL" | translate }}</th>
                  <th>{{ "TOTAL-DUE-DATE" | translate }}</th>
                  <th colspan="3" class="text-center">
                    <label for="service">{{
                      "Usar descontos em porcentagens" | translate
                    }}</label>
                    <p-inputSwitch
                      [(ngModel)]="isDiscountPercentage"
                      [ngModelOptions]="{ standalone: true }"
                      (onChange)="updateServicesDiscount()"
                      pTooltip="Utilizar descontos em porcentagens?"
                      tooltipPosition="bottom"
                    >
                    </p-inputSwitch>
                  </th>
                </tr>
              </ng-template>
              <ng-template
                pTemplate="body"
                let-boatService
                let-rowIndex="rowIndex"
              >
                <tr [formGroupName]="rowIndex">
                  <td>{{ boatService.value.service.name }}</td>
                  <td>
                    <p-calendar
                      dateFormat="dd/mm/yy"
                      name="contractStartDate"
                      rPCalendarMask
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      [yearRange]="'2015:2030'"
                      i18n
                      appendTo="body"
                      formControlName="contractStartDate"
                      appendTo="body"
                    >
                    </p-calendar>
                  </td>
                  <td>
                    <p-calendar
                      dateFormat="dd/mm/yy"
                      name="contractEndDate"
                      formControlName="contractEndDate"
                      rPCalendarMask
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      [yearRange]="'2019:2030'"
                      i18n
                      appendTo="body"
                    >
                    </p-calendar>
                  </td>
                  <td>
                    <input
                      pInputText
                      name="observation"
                      formControlName="observation"
                    />
                  </td>
                  <td
                    class="text-right"
                    *ngIf="!isEditValueByService(boatService.value)"
                  >
                    {{ boatService.value.value | number : "1.2-2" }}
                  </td>
                  <td
                    class="text-right"
                    *ngIf="isEditValueByService(boatService.value)"
                  >
                    <p-inputNumber
                      name="value"
                      formControlName="value"
                      mode="decimal"
                      [minFractionDigits]="2"
                      [maxFractionDigits]="2"
                      (onInput)="
                        calculateDueDateDiscountPercentage(boatService);
                        calculateDiscountPercentage(boatService)
                      "
                    >
                    </p-inputNumber>
                  </td>
                  <td class="text-right">
                    <p-inputNumber
                      name="discountPercentage"
                      formControlName="discountPercentage"
                      mode="decimal"
                      class="medium"
                      [minFractionDigits]="2"
                      [maxFractionDigits]="2"
                      (onInput)="
                        stopNullValue(boatService.get('discountPercentage'));
                        calculateDiscountPercentage(boatService)
                      "
                    >
                    </p-inputNumber>
                  </td>
                  <td class="text-right">
                    <p-inputNumber
                      name="discount"
                      formControlName="discount"
                      mode="decimal"
                      class="medium"
                      [minFractionDigits]="2"
                      (onBlur)="stopNullValue(boatService.get('discount'))"
                      [maxFractionDigits]="2"
                    >
                    </p-inputNumber>
                  </td>
                  <td class="text-right">
                    <p-inputNumber
                      name="dueDateDiscountPercentage"
                      formControlName="dueDateDiscountPercentage"
                      class="medium"
                      tooltipPosition="top"
                      (onBlur)="
                        stopNullValue(
                          boatService.get('dueDateDiscountPercentage')
                        )
                      "
                      mode="decimal"
                      [minFractionDigits]="2"
                      [maxFractionDigits]="2"
                      (onInput)="
                        calculateDueDateDiscountPercentage(boatService)
                      "
                    >
                    </p-inputNumber>
                  </td>
                  <td class="text-right">
                    <p-inputNumber
                      name="dueDateDiscount"
                      formControlName="dueDateDiscount"
                      class="medium"
                      pTooltip="Desconto no boleto"
                      tooltipPosition="top"
                      (onBlur)="
                        stopNullValue(boatService.get('dueDateDiscount'))
                      "
                      mode="decimal"
                      [minFractionDigits]="2"
                      [maxFractionDigits]="2"
                    >
                    </p-inputNumber>
                  </td>
                  <td class="text-right">
                    {{
                      calcTotalService(boatService.getRawValue())
                        | number : "1.2-2"
                    }}
                  </td>
                  <td class="text-right">
                    {{
                      calcTotalServiceDueDate(boatService.getRawValue())
                        | number : "1.2-2"
                    }}
                  </td>
                  <td>
                    <a
                      *ngIf="boatService.value.filePath"
                      target="_blank"
                      pTooltip="Baixar contrato assinado"
                      tooltipPosition="top"
                      [href]="boatService.value.filePath"
                      style="color: black"
                    >
                      <i class="pi pi-file-pdf"></i>
                    </a>
                  </td>
                  <td class="text-center">
                    <div
                      class="table-action"
                      pTooltip="Deletar"
                      tooltipPosition="top"
                      (click)="deleteBoatService(rowIndex)"
                    >
                      <i class="pi pi-trash"></i>
                    </div>
                  </td>
                  <td class="text-center">
                    <div
                      class="table-action"
                      pTooltip="Visualizar Histórico"
                      tooltipPosition="top"
                      (click)="openHistoric(boatService.value)"
                    >
                      <i class="pi pi-eye"></i>
                    </div>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr *ngIf="listBoatServicesActive().length > 1">
                  <td></td>
                  <td style="min-width: 115px"></td>
                  <td style="min-width: 115px"></td>
                  <td>Total</td>
                  <td class="text-right padding-td">
                    {{ totalServicesValue() | number : "1.2-2" }}
                  </td>
                  <td></td>
                  <td class="text-right padding-td">
                    {{ totalServicesDiscount() | number : "1.2-2" }}
                  </td>
                  <td></td>
                  <td class="text-right padding-td">
                    {{ totalServicesDueDateDiscount() | number : "1.2-2" }}
                  </td>
                  <td class="text-right padding-td">
                    {{ totalServicesTotal() | number : "1.2-2" }}
                  </td>
                  <td class="text-right padding-td">
                    {{ totalServicesTotalDueDate() | number : "1.2-2" }}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-fieldset>
        <br />
        <app-service-boat-history
          [servicesBoatHistory]="servicesBoatHistory"
        ></app-service-boat-history>
        <br />
        <p-fieldset *ngIf="boat?.id" toggleable="true" collapsed="false">
          <p-header class="article-title">
            <div class="article-title">
              {{ "Pedidos de mensalidade" | translate }}
            </div>
          </p-header>
          <div class="table">
            <p-table
              [value]="marinaOrders"
              [paginator]="true"
              rows="12"
              resetPageOnSort="true"
              [pageLinks]="3"
              [rowsPerPageOptions]="[10, 20, 30, 50, 100]"
              autoLayout="true"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th class="text-right">
                    {{ totalOrders | number : "1.2-2" }}
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th class="text-right">
                    {{ totalValueOrders | number : "1.2-2" }}
                  </th>
                  <th></th>
                </tr>

                <tr>
                  <th [pSortableColumn]="'orderNumber'">
                    Ped
                    <p-sortIcon [field]="'orderNumber'"></p-sortIcon>
                  </th>
                  <th class="text-center" [pSortableColumn]="'orderDate'">
                    Data
                    <p-sortIcon [field]="'orderDate'"></p-sortIcon>
                  </th>
                  <th
                    class="text-center"
                    [pSortableColumn]="'dateReferenceTuition'"
                  >
                    Ref.
                    <p-sortIcon [field]="'dateReferenceTuition'"></p-sortIcon>
                  </th>
                  <th class="text-center" [pSortableColumn]="'origin'">
                    Orig.
                    <p-sortIcon [field]="'origin'"></p-sortIcon>
                  </th>
                  <th class="text-center" [pSortableColumn]="'value'">
                    Total
                    <p-sortIcon [field]="'value'"></p-sortIcon>
                  </th>
                  <th class="text-center" [pSortableColumn]="'boat.name'">
                    Embarcação
                    <p-sortIcon [field]="'boat.name'"></p-sortIcon>
                  </th>
                  <th class="text-center">Cliente</th>
                  <th>Fatura</th>
                  <th>Rateio</th>
                  <th>Valor</th>
                  <th class="text-center" [pSortableColumn]="'orderStatus'">
                    Status
                    <p-sortIcon [field]="'orderStatus'"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-order>
                <tr>
                  <td>{{ order.orderNumber }}</td>
                  <td class="text-center">
                    {{ order.orderDate | date : "dd/MM/yy" }}
                  </td>
                  <td class="text-center">
                    {{ order.dateReferenceTuition | date : "dd/MM/yy" }}
                  </td>
                  <td class="text-center">
                    <div [ngSwitch]="order.origin">
                      <div *ngSwitchCase="'DETACHED'">
                        <fa-icon
                          [icon]="'shopping-basket'"
                          class="table"
                        ></fa-icon>
                      </div>
                      <div *ngSwitchCase="'SLING'">
                        <fa-icon [icon]="'ship'" class="table"></fa-icon>
                      </div>
                      <div *ngSwitchCase="'TUITION'">
                        <fa-icon [icon]="'university'" class="table"></fa-icon>
                      </div>
                    </div>
                  </td>
                  <td class="text-right colorValue">
                    {{ order.value | number : "1.2-2" }}
                  </td>
                  <td>{{ order.boat.name }}</td>
                  <td colspan="4">
                    <p-table class="primeSubTable" [value]="order.orderQuotas">
                      <ng-template pTemplate="body" let-orderQuota>
                        <tr>
                          <td>{{ orderQuota.customer.name }}</td>
                          <td class="text-center">
                            <span
                              class="{{
                                orderQuota.invoiceNumber != null &&
                                orderQuota.statusInvoice === 'CANCELED'
                                  ? 'canceledOrder'
                                  : orderQuota.invoiceNumber != null
                                  ? 'paidOrder'
                                  : 'paidOrderDisabled'
                              }}"
                              (click)="
                                orderQuota.invoiceNumber != null &&
                                  extract(orderQuota.idInvoice)
                              "
                            >
                              {{
                                orderQuota.invoiceNumber != null
                                  ? orderQuota.invoiceNumber
                                  : "----"
                              }}
                            </span>
                          </td>
                          <td class="text-right colorValue">
                            {{ orderQuota.quota | number : "1.0-0" }}%
                          </td>
                          <td class="text-right colorValue">
                            {{
                              calculateQuotaValue(order.value, orderQuota.quota)
                                | number : "1.2-2"
                            }}
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </td>
                  <td class="text-center">
                    <div class="centralize" style="padding-top: 10px">
                      <p>
                        <i
                          class="pi pi-circle-on"
                          [ngClass]="{
                            canceled: order.orderStatus === 'Canceled',
                            'partially-attempted':
                              order.orderStatus === 'PartiallyAttempted',
                            attempted: order.orderStatus === 'Attempted',
                            ordered: order.orderStatus === 'Ordered'
                          }"
                        >
                        </i>
                      </p>
                    </div>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="10">Nenhum pedido de mensalidade encontrado</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-fieldset>
      </p-tabPanel>
      <p-tabPanel
        header="{{ 'CONTRACTS' | translate }}"
        *ngIf="slingConfig && slingConfig.hasContracts"
      >
        <p-fieldset>
          <p-header class="article-title">
            <div class="article-title">
              {{ "CONTRACTS" | translate }} Ativos
            </div>
          </p-header>
          <app-table-verify-contracts
            [documents]="documentContracts"
          ></app-table-verify-contracts>
        </p-fieldset>
        <br />
        <p-fieldset toggleable="true" collapsed="false">
          <p-header class="article-title">
            <div class="article-title">{{ "HISTORY" | translate }}</div>
          </p-header>
          <app-table-verify-contracts
            [documents]="documentContractsHistory"
          ></app-table-verify-contracts>
        </p-fieldset>
      </p-tabPanel>
      <p-tabPanel
        header="{{ 'HISTORY' | translate }}"
        *ngIf="boat?.id && !isMovimentOperation"
      >
        <p-fieldset formGroupName="service" class="history">
          <p-header class="article-title">
            <div class="article-title">{{ "HISTORY" | translate }}</div>
          </p-header>
          <div class="history-content-top">
            <div class="subtitles">
              <fieldset>
                <legend>Legenda</legend>
                <div class="badges">
                  <div class="badge routine"></div>
                  {{ "BOAT-HISTORY-ROUTINE" | translate }}
                  <div class="badge water"></div>
                  {{ "BOAT-HISTORY-WATER" | translate }}
                </div>
              </fieldset>
            </div>
            <button
              type="button"
              (click)="exportHistoryPDF()"
              class="easymarine danger"
            >
              {{ "EXPORT-PDF" | translate }}
            </button>
          </div>
          <div class="table">
            <ul style="column-count: 1; list-style-type: none" class="timeline">
              <li
                *ngFor="let history of histories; let i = index"
                [ngClass]="{
                  'li-month':
                    i === 0 || isNewMonth(histories[i - 1].date, history.date),
                  'before-li-month':
                    i + 1 < histories.length &&
                    isNewMonth(histories[i].date, histories[i + 1].date)
                }"
              >
                <div
                  class="month"
                  *ngIf="
                    i === 0 || isNewMonth(histories[i - 1].date, history.date)
                  "
                >
                  {{ history.date | date : "MMM/yy" | uppercase }}
                </div>
                <div class="history-item">
                  <div class="day timeline-thumb">
                    <span>{{ history.date | date : "dd" }}</span>
                  </div>
                  <div class="timeline-item">
                    <div class="timeline-stats">
                      <div *ngFor="let data of history.data" class="icon">
                        <img
                          *ngIf="data.type === 'WASH'"
                          [src]="
                            data.isSea
                              ? 'assets/images/tint_water.svg'
                              : 'assets/images/tint.svg'
                          "
                        />
                        <img
                          *ngIf="data.type === 'ENGINE-TURN'"
                          [src]="
                            data.isSea
                              ? 'assets/images/helice_water.svg'
                              : 'assets/images/helice.png'
                          "
                        />
                        <img
                          *ngIf="data.type === 'SLING-UP'"
                          src="assets/images/history-up.png"
                        />
                        <img
                          *ngIf="data.type === 'SLING-DOWN'"
                          src="assets/images/history-down.png"
                        />
                        <img
                          *ngIf="data.type === 'OWN-FUEL-SUPPLY'"
                          src="assets/images/fuel-own.png"
                        />
                        <img
                          *ngIf="data.type === 'FUEL-SUPPLY'"
                          src="assets/images/fuel-fill-success.png"
                        />
                      </div>
                      &nbsp;
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </p-fieldset>
      </p-tabPanel>
    </p-tabView>
    <div class="form-group align-center">
      <div class="form-control mt-4">
        {{ "REQUIRED-FIELDS" | translate }}
      </div>
      <div class="buttons">
        <button
          type="button"
          (click)="closeaDialog()"
          class="easymarine danger"
        >
          {{ "CANCEL" | translate }}
        </button>
        <button class="easymarine primary" type="submit">
          {{ "SAVE" | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
