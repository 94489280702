import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MarinaOrder, OrderItem, Sling, SlingConfig } from 'src/app/models';
import { BoatStatus, SlingStatus } from 'src/app/models/enums';
import { SlingCheckComponent } from 'src/app/pages/operational/sling-check/sling-check.component';
import { BoatWashService } from 'src/app/services/boat-wash.service';
import { EngineTurnService } from 'src/app/services/engine-turn.service';
import { MarinaOrderService } from 'src/app/services/marina-order.service';
import { OrderItemService } from 'src/app/services/order-item.service';
import { SlingService } from 'src/app/services/sling.service';
import { ToastService } from 'src/app/services/toast.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { OrderUtil } from 'src/app/utils/order.util';
import { SlingUtil } from 'src/app/utils/sling.util';
import Swal from 'sweetalert2';
import { SlingOperationComponent } from '../sling-operation/sling-operation.component';
import { SlingOrderDetailsComponent } from '../sling-order-details/sling-order-details.component';
import moment from 'moment';
import { OrderStatus } from 'src/app/models/order-status';
import { ProductCategoryType } from 'src/app/models/product-category-type';
import { OptionWashAndTurnEngine } from 'src/app/models/enums/option-wash-and-turn-engine';
import { BoatWash } from 'src/app/models/boatWash';
import { EngineTurn } from 'src/app/models/engine-turn';
import { SlingFuelCheckComponent } from '../sling-fuel-check/sling-fuel-check.component';
import { SlingConsumableCheckComponent } from '../sling-consumable-check/sling-consumable-check.component';
import { SlingAccessoryCheckComponent } from '../sling-accessory-check/sling-accessory-check.component';
import { DialogService } from 'primeng/dynamicdialog';
import { WebSocketService } from 'src/app/services/web-socket.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-sling-water-tab',
  templateUrl: './sling-water-tab.component.html',
  styleUrls: ['./sling-water-tab.component.scss']
})
export class SlingWaterTabComponent implements OnInit {

  @Input()
  isMobile = false;

  @Input()
  slingConfig: SlingConfig;

  slingsMooredSailingCheck: Sling[] = new Array();
  referenceTime: any;
  slingCheckComponent: SlingCheckComponent;

  slingSubscription: Subscription;

  constructor(
    public slingUtil: SlingUtil,
    private slingService: SlingService,
    private messageUtil: MessageUtil,
    public orderUtil: OrderUtil,
    public dialog: DialogService,
    private toasty: ToastService,
    private orderItemService: OrderItemService,
    private boatWashService: BoatWashService,
    private engineTurnService: EngineTurnService,
    private spinner: NgxSpinnerService,
    private marinaOrderService: MarinaOrderService,
    private router: Router,
    private webSocketService: WebSocketService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.spinner.show();
    await this.fillSlingTable();
    this.spinner.hide();
    this.slingSubscription = this.webSocketService.getMessageSling().subscribe(async (message) => {
      this.spinner.show();
      await this.fillSlingTable();
      this.spinner.hide();
    });
  }

  ngOnDestroy(): void {
    this.webSocketService.unsubscribeSling(this.slingSubscription);
  }

  async fillSlingTable(): Promise<void> {
    this.slingsMooredSailingCheck = new Array();
    const filter = { slingStatus: ['WATER'], boatStatus: ['MOORED', 'SAILING', 'PREPARED'], orderBy: 'orderReturn' };
    // this.slingService.findSlingByFilter(filter).subscribe(
    //   async (data) => {console.log(data);}
    // );
    return new Promise<void>(
      async (resolve) => {
        this.slingService.findSlingByFilterV2(filter).subscribe(
          async (data) => {
            let aux = [];
            aux = aux.concat(data.filter((x) => x.finishedOnWater === true)).sort((a, b) => {
              if (a.boat.name > b.boat.name) {
                return 1;
              } else if (a.boat.name < b.boat.name) {
                return -1;
              } else {
                return 0;
              }
            });
            aux = aux.concat(data.filter((x) => x.finishedOnWater === false));
            this.slingsMooredSailingCheck = aux;
            const list = this.slingsMooredSailingCheck.map((x) => x.boat.id);
            if (list.length > 0) {
              await this.findSlingsNextDay(list);
            }
            resolve();
          }
        );
      }
    );
  }

  notEqualDate(slingCheck: Sling): boolean {
    if (new Date(slingCheck.navigationPlan.returnTime).setHours(0, 0, 0, 0) !==
      (new Date()).setHours(0, 0, 0, 0)) {
      return true;
    }
    return false;
  }

  handleActions(sling: Sling): void {
    const isReady = this.isReadyToSling(sling);
    if (isReady) {
      this.confirmModal(sling, false);
    } else {
      Swal.fire({
        title: 'Alguns itens não foram atendidos. Deseja continuar?',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          if (this.slingConfig.manageDeck) {
            this.confirmModal(sling, true);
          } else {
            this.openDialogSling(sling);
          }
        }
      });
    }
  }

  openDialogSling(sling: Sling): void {
    Swal.fire({
      title: 'Confirmar operação?',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      cancelButtonColor: '#d33',
      allowOutsideClick: false,
      preConfirm: () => {
        this.updateSling(sling);
      }
    });
  }

  loadMarinaOrders(slingCheck): void {
    this.spinner.show();
    this.marinaOrderService.getDebitsCustomersToDate(new Date().getTime(),
      slingCheck.boat.boatCustomers
        .map((bc) => bc.customer)
        .map((c) => c.id).join()).subscribe(
          (data) => {
            this.spinner.hide();
            this.openSingleRequest(slingCheck, data);
          },
          (error) => {
            this.spinner.hide();
            const exception = error.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
          }
        );
  }

  private openSingleRequest(slingCheck, orders): void {
    if (orders.length > 0) {
      this.dialog.open(SlingOrderDetailsComponent, {
        width: '70%',
        header: 'Embarcação: ' + slingCheck.boat.name + ' - ' + moment().format('DD/MM/YY'),
        data: {
          sling: slingCheck,
          customers: slingCheck.boat.boatCustomers.map((bc) => bc.customer),
          orders
        }
      });
    } else {
      this.router.navigate(
        ['/single-order'],
        {
          queryParams:
          {
            customerId: slingCheck.navigationPlan.responsible.id,
            boatId: slingCheck.boat.id,
            slingId: slingCheck.id
          }
        }
      );
    }
  }

  private confirmModal(sling: Sling, notAttempted: boolean): void {
    this.dialog.open(SlingOperationComponent, {
      header: 'Confirmação - ' + sling.boat.name + ' - ' + sling.navigationPlan.responsible.name + '',
      data: {
        sling,
        origin: sling.boatStatus === BoatStatus.SAILING ? 'SlingSailing' : 'SlingCheckMoored',
        manageDeck: this.slingConfig.manageDeck,
        isFinishOnWater: this.slingConfig.finishOnWater
      }
    }).onClose.subscribe(async (result: any) => {
      if (result && result !== '') {
        if (result.slingStatus === SlingStatus.PENDING_UP) {
          this.insertInQueueUp(sling);
        } else if (result.boatStatus === BoatStatus.STORED) {
          if (sling.boat.fullTank){
           let marinaOrderFuelOrder = await this.openDialogFuelOrder(sling, true);
           if(marinaOrderFuelOrder !== null){
             marinaOrderFuelOrder = marinaOrderFuelOrder as MarinaOrder;
             for (let i = 0; i < sling.marinaOrders.length; i++) {
               if(sling.marinaOrders[i].id === marinaOrderFuelOrder.id){
                sling.marinaOrders[i].value = marinaOrderFuelOrder.value;
               }
             }
           }
           sling.marinaOrder.orderStatus = OrderStatus.Attempted;
           sling.marinaOrders[0].orderStatus = OrderStatus.Attempted;
          }
          sling.boatStatus = result.boatStatus;
          sling = this.updateValuesStored(sling, notAttempted);
          this.updateSling(sling);
        } else {
          if (result.finishedOnWater) {
            sling.finishedOnWater = result.finishedOnWater;
          }
          if (result.boatStatus) {
            sling.boatStatus = result.boatStatus;
          }
          if (result.slingStatus) {
            sling.slingStatus = result.slingStatus;
          }
          this.updateSling(sling);
        }
      }
    });
  }

  private finishedConfirmModal(sling: Sling, notAttempted: boolean): void {
    this.dialog.open(SlingOperationComponent, {
      header: 'Confirmação - ' + sling.boat.name + ' - ' + sling.navigationPlan.responsible.name + '',
      data: {
        sling,
        origin: 'SlingFinishedOnWater',
        manageDeck: this.slingConfig.manageDeck
      }
    }).onClose.subscribe((result: any) => {
      if (result !== '') {
        if (result.boatStatus === BoatStatus.STORED || result.boatStatus === BoatStatus.MOORED) {
          sling.boatStatus = result.boatStatus;
          if (result.boatStatus === BoatStatus.STORED) {
            sling = this.updateValuesStored(sling, notAttempted);
          }
          this.updateSling(sling);
        } else if (result.slingStatus === SlingStatus.PENDING_UP) {
          this.insertInQueueUp(sling);
        }
      }
    });
  }

  private updateValuesStored(sling: Sling, notAttempted: boolean): Sling {
    sling.slingUpAt = moment().format('YYYY-MM-DDTHH:mm');
    sling.slingStatus = SlingStatus.OK;
    sling.boatStatus = BoatStatus.STORED;
    if (notAttempted) {
      sling.marinaOrders.forEach((mo) => {
        if (mo.value == null) {
          mo.value = 0;
        }
        mo.orderItems
          .filter((oi) => oi.qtySupplied == null)
          .forEach((oi) => {
            oi.qtySupplied = 0;
            oi.itemStatus = true;
            if (oi.fuelMeasurementType === 'Measured') {
              oi.itemValue = oi.product.price.value;
            }
          });
      });
      sling.slingAccessories
        .filter((sa) => sa.attended == null)
        .forEach((sa) => sa.attended = false);
    }
    return sling;
  }

  private isReadyToSling(sling: Sling): boolean {
    const orderItems: OrderItem[] = sling.marinaOrder.orderItems;
    if (sling.marinaOrder.orderStatus === OrderStatus.Canceled ||
      !this.checkPendingConsumables(orderItems) && !this.checkPendingFuel(orderItems) &&
      sling.slingAccessories.find((sa) => sa.attended == null) === undefined && sling.prepare) {
      return true;
    }

    return false;
  }

  checkPendingConsumables(orderItems: OrderItem[]): boolean {
    const consumables = orderItems.filter((orderItem) =>
      orderItem.product.productCategory.productCategoryType !== ProductCategoryType.Fuel && !orderItem.itemStatus);
    return consumables.length > 0;
  }

  checkPendingFuel(orderItems: OrderItem[]): boolean {
    const fuels = orderItems.filter((orderItem) =>
      orderItem.product.productCategory.productCategoryType === ProductCategoryType.Fuel && !orderItem.itemStatus);
    return fuels.length > 0;
  }

  private updateSling(sling: Sling): void {
 //   this.slingService.update(sling).subscribe(
      this.slingService.updateOnly(sling).subscribe(
      () => {
        this.toasty.success(this.messageUtil.translateKey('MSG.SLING-SUCCESS'));
        this.fillSlingTable();
        if (sling.slingStatus === SlingStatus.OK) {
          this.verifyWashAndTurnEngine(sling);
        }
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  private insertInQueueUp(sling: Sling): void {
    this.slingService.insertInQueueUp(sling).subscribe(
      () => {
        this.toasty.success(this.messageUtil.translateKey('MSG.SLING-SUCCESS'));
        this.fillSlingTable();
      },
      async (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  private verifyWashAndTurnEngine(sling): void {
    if (this.slingConfig.autoScheduleUpdateWash) {
      if (this.slingConfig.washRunOption === OptionWashAndTurnEngine.AutoRegistration) {
        this.saveWash(sling, false);
      } else {
        this.saveWash(sling, true);
      }
    }

    if (this.slingConfig.autoScheduleUpdateEngineTurn) {
      if (this.slingConfig.engineTurnRunOption === OptionWashAndTurnEngine.AutoRegistration) {
        this.saveTurnEngine(sling, false);
      } else {
        this.saveTurnEngine(sling, true);
      }
    }
  }

  private saveWash(sling: Sling, action: any): void {
    this.createBoatWashFastRegistration(sling, action);
  }

  private saveTurnEngine(sling: Sling, action: any): void {
    this.createEngineTurnFastRegistration(sling, action);
  }

  private createBoatWashFastRegistration(sling, isActive): void {
    const boatWash = new BoatWash();
    boatWash.boatId = sling.boat.id;
    boatWash.isActive = isActive;
    boatWash.isSea = true;
    this.boatWashService.createFastRegistrarion(boatWash).subscribe(
      () => null,
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  private createEngineTurnFastRegistration(sling, isActive): void {
    const engineTurn = new EngineTurn();
    engineTurn.boatId = sling.boat.id;
    engineTurn.isActive = isActive;
    engineTurn.isSea = true;
    this.engineTurnService.createFastRegistrarion(engineTurn).subscribe(
      () => null,
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  boatFinished(sling: Sling): void {
    const isReady = this.isReadyToSling(sling);
    if (isReady) {
      this.finishedConfirmModal(sling, false);
    } else {
      Swal.fire({
        title: 'Alguns itens não foram atendidos. Deseja continuar?',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          if (sling.marinaOrder.value == null) {
            sling.marinaOrder.value = 0;
          }
          sling.marinaOrders.forEach((mo) => {
            mo.orderItems
              .filter((oi) => oi.qtySupplied == null)
              .forEach((oi) => {
                oi.qtySupplied = 0;
                oi.itemStatus = true;
                if (oi.fuelMeasurementType === 'Measured') {
                  oi.itemValue = oi.product.price.value;
                }
              });
          });
          sling.slingAccessories
            .filter((sa) => sa.attended == null)
            .forEach((sa) => sa.attended = false);
          if (this.slingConfig.manageDeck) {
            this.finishedConfirmModal(sling, true);
          } else {
            this.openDialogSling(sling);
          }
        }
      });
    }
  }

  async findSlingsNextDay(boatIds: number[]): Promise<void> {
    return new Promise<void>(
      async (resolve) => {
        this.slingService.findBoatIdsWithSlingsNextDay(boatIds).subscribe(async (ids) => {
          if (ids) {
            // tslint:disable-next-line: prefer-for-of
            for (let index = 0; index < ids.length; index++) {
              const id = ids[index];
              const sling = this.slingsMooredSailingCheck.filter((x) => x.boat.id === id)[0];
              if (sling) {
                sling.slingNextDay = true;
              }
            }
          }
          resolve();
        });
      }
    );
  }

  async openDialogFuelOrder(sling: Sling, fullTankOperation: boolean): Promise<MarinaOrder | void> {
    return new Promise((resolve) => {
      let fuel: OrderItem;
      if (!sling.boat.fullTank || !fullTankOperation){
        fuel = sling.marinaOrder.orderItems.filter(
          (x) => x.product.productCategory.productCategoryType === ProductCategoryType.Fuel
        )[0];
      }
      this.dialog.open(SlingFuelCheckComponent, {
        data: {
          slingId: sling.id,
          fuelOrder: fuel,
          referenceTime: this.referenceTime,
          fullTankCheck: sling.boat.fullTank && fullTankOperation
        }
      }).onClose.subscribe((result) => {
        if (result) {
          Swal.fire({
            title: 'Confirmar operação?',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            showLoaderOnConfirm: true,
            reverseButtons: true,
            cancelButtonColor: '#d33',
            allowOutsideClick: false,
            preConfirm: () => {
              if (sling.boat.fullTank && fullTankOperation){
                fuel = result;
              }else{
                fuel.qtySupplied = result;
              }
              fuel.itemStatus = true;
              fuel.itemValue = fuel.product.price.value;
              fuel.dateSupply = moment().format('YYYY-MM-DDTHH:mm:ss');
              fuel.marinaOrder = sling.marinaOrder;
              fuel.marinaOrder.sling = null;
              fuel.marinaOrder.orderItems = [];
              if (sling.boat.fullTank && fullTankOperation){
                this.orderItemService.saveCheckingFullTank(new Array(fuel)).subscribe({
                  next: (order) =>{
                    this.finalizeSaveChecking(ProductCategoryType.Fuel)
                    resolve(order);
                  },error: (err) => {
                    console.log(err)
                  }
                });
              }else{
                this.updateOrderItem(new Array(fuel), ProductCategoryType.Fuel);
                resolve();
              }
            }
          });
        }
        if (result === false){
          resolve();
        }
      });
    });
  }

  updateOrderItem(orderItems: OrderItem[], category: ProductCategoryType): void {
      this.orderItemService.saveChecking(orderItems).subscribe((order) => {
        this.finalizeSaveChecking(category);
      });
  }

  private finalizeSaveChecking(category: ProductCategoryType): void {
    switch (category) {
      case ProductCategoryType.Consumable:
        this.toasty.success('Produto fornecido com sucesso');
        break;
      case ProductCategoryType.Fuel:
        this.toasty.success('Combustível fornecido com sucesso');
        break;
      default:
        this.toasty.success('Acessório fornecido com sucesso');
        break;
    }
    this.fillSlingTable();
  }

  openDialogConsumableOrder(sling: Sling): void {
    this.dialog.open(SlingConsumableCheckComponent, {
      data: {
        slingId: sling.id,
        referenceTime: this.referenceTime
      },
      header: this.messageUtil.translateKey('CONSUMABLES')
    }).onClose.subscribe((result) => {
      if (result) {
        const consumables: OrderItem[] = new Array();
        this.validItems(result, result.marinaOrder.orderItems, consumables);
      } else {
        this.fillSlingTable();
      }
    });
  }

  validItems(slingOrder, orderItems: OrderItem[], consumables: OrderItem[]): boolean {
    let typeErrorQty = '';
    for (const consumable of orderItems) {
      if (consumable.qtySupplied == null) {
        this.messageUtil.generateMessage('error', 'SUMMARY.ERROR', 'Informar a quantidade');
        return false;
      }
      if (consumable.qtySupplied > consumable.qtyRequested) {
        typeErrorQty = 'more';
      } else if (consumable.qtySupplied < consumable.qtyRequested) {
        typeErrorQty = 'less';
      }
      consumable.itemStatus = true;
      if (!consumable.product.price.priceFree) {
        consumable.itemValue = consumable.product.price.value;
      }
      consumable.marinaOrder = new MarinaOrder();
      consumable.marinaOrder.id = slingOrder.marinaOrder.id;
      consumables.push(consumable);
    }
    if (typeErrorQty === '') {
      this.saveConsumables(consumables);
    } else {
      this.confirmDifferentConsumable(typeErrorQty, consumables);
    }
  }

  confirmDifferentConsumable(typeErrorQty, consumables): void {
    const titleMsg = 'Quantidade diferente';
    let textMsg = '';
    if (typeErrorQty === 'more') {
      textMsg = 'A quantidade fornecida é superior a solicitada. Deseja continuar?';
    } else {
      textMsg = 'A quantidade fornecida é inferior a solicitada. Deseja continuar?';
    }
    Swal.fire({
      title: titleMsg,
      text: textMsg,
      icon: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar'
    }).then((response) => {
      if (response.value) {
        this.saveConsumables(consumables);
      }
    });
  }

  saveConsumables(consumables: OrderItem[]): void {
    Swal.fire({
      title: 'Confirmar operação?',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      cancelButtonColor: '#d33',
      allowOutsideClick: false,
      preConfirm: () => {
        consumables.forEach((c: OrderItem) => {
          c.dateSupply = moment().format('YYYY-MM-DDTHH:mm:ss');
        });
        this.updateOrderItem(consumables, ProductCategoryType.Consumable);
      }
    });
  }

  openDialogSlingAccessories(sling: Sling): void {
    this.dialog.open(SlingAccessoryCheckComponent, {
      data: {
        slingId: sling.id,
        referenceTime: this.referenceTime
      },
      header: this.messageUtil.translateKey('ACCESSORIES')
    }).onClose.subscribe(() => {
      this.fillSlingTable();
    });
  }

  hasFuel(sling: Sling): boolean {
    const fuels = sling.marinaOrder.orderItems.filter(
      (orderItem) => orderItem.product.productCategory.productCategoryType === ProductCategoryType.Fuel
    );
    return sling.marinaOrder.orderStatus !== OrderStatus.Canceled && fuels.length > 0;
  }

  hasConsumables(sling: Sling): boolean {
    const consumables = sling.marinaOrder.orderItems.filter(
      (orderItem) => orderItem.product.productCategory.productCategoryType !== ProductCategoryType.Fuel
    );
    return sling.marinaOrder.orderStatus !== OrderStatus.Canceled && consumables.length > 0;
  }

  hasAcessories(sling: Sling): boolean {
    return sling.marinaOrder.orderStatus !== OrderStatus.Canceled && sling.slingAccessories.length > 0;
  }

  getFuel(orderItems: OrderItem[]): OrderItem {
    const fuels = orderItems.filter((orderItem) => orderItem.product.productCategory.productCategoryType === ProductCategoryType.Fuel);
    if (fuels.length > 0) {
      return fuels[0];
    }
    return new OrderItem();
  }

  isNumber(value: any): boolean {
    return typeof value === 'number';
  }

  countConsumables(orderItems: OrderItem[]): number {
    const consumables = orderItems.filter(
      (orderItem) => orderItem.product.productCategory.productCategoryType !== ProductCategoryType.Fuel
    );
    return consumables.length;
  }

  checkPendingAcessories(sling: Sling): boolean {
    const acessories = sling.slingAccessories.filter((x) => !x.attended);
    return acessories.length > 0;
  }

  hasBoatSailor(sling: Sling): boolean {
    return !!sling.boatSailor;
  }

  getImageSailorResponsable(): string {
    return '/assets/images/sailorOnSlingBlack.png';
  }

  hasSailorConfig(): boolean{
    return this.slingConfig.hasSailor;
  }

}
