import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Sling } from '../models';
import { ProductCategoryType } from '../models/enums/product-category-type';
import { RequestOrigin } from '../models/enums';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class SlingService {

    constructor(private http: HttpClient) {

    }

    getAll(): Observable<Sling[]> {
        return this.http.get(
            environment.apiHost + '/secure/slings/').pipe(map((response: any) => response.data.slings as Sling[]));
    }

    findSlingByFilter(filter): Observable<any> {
        return this.http.post(
            environment.apiHost + '/secure/slings/sling-by-filter/', filter).pipe(map((response: any) => response.data.slings));
    }

    findSlingByFilterV2(filter): Observable<any> {
      return this.http.post(
          environment.apiHost + '/api-sling/secure/sling/querys/find-sling-by-filter', filter).pipe(map((response: any) => response));
  }

    getSlingQueue(filterDateMilis: number): Observable<Sling[]> {
        return this.http.get(
            environment.apiHost + '/secure/sling/queue/' + filterDateMilis).pipe(map((response: any) => response.data.slings as Sling[]));
    }

    getSlingPendingDown(filterDateMilis: number): Observable<Sling[]> {
        return this.http.get(
            environment.apiHost + '/secure/sling/pendingDown/' + filterDateMilis).pipe(
                map((response: any) => response.data.slings as Sling[])
            );
    }

    insertInQueueUp(sling: Sling): Observable<any> {
        return this.http.put(
            environment.apiHost + '/secure/sling/insert-in-queue', sling).pipe(map((response: any) => response.data));
    }

    getAvailablePeriods(filterDateMilis: number): Observable<Date[]> {
        return this.http.get(
            environment.apiHost + '/secure/sling/get-available-periods/' + filterDateMilis
        ).pipe(map((response: any) => response.data.periods as Date[]));
    }

    getAvailablePeriodsSlingUP(filterDateMilis: number): Observable<Date[]> {
        return this.http.get(
            environment.apiHost + '/secure/sling/get-available-periods-sling-up/' + filterDateMilis
        ).pipe(map((response: any) => response.data.periods as Date[]));
    }

    getWithOrderedItemsForTodayByCategory(productCategoryType: ProductCategoryType): Observable<Sling[]> {
        return this.http.get(
            environment.apiHost + '/secure/sling/order/today-ordered-items-category/' + productCategoryType
        ).pipe(map((response: any) => response.data.slingOrders as Sling[]));
    }

    getWithSlingsForTodayToAccessories(): Observable<Sling[]> {
        return this.http.get(
            environment.apiHost + '/secure/sling/accessories-today/')
            .pipe(map((response: any) => response.data.slingOrders as Sling[]));
    }

    getById(id: number): Observable<Sling> {
        return this.http.get(
            environment.apiHost + '/secure/sling/' + id).pipe(map((response: any) => response.data.sling));
    }

    save(sling: Sling): Observable<Sling> {
        const marinaOrderCanceled = sling.marinaOrders.filter((marinaOrder) => marinaOrder.orderStatus === 'Canceled');
        const marinaOrders = [];
        if (sling.marinaOrder) {
            marinaOrders.push(sling.marinaOrder);
        }
        if (marinaOrderCanceled.length > 0) {
            marinaOrders.push(marinaOrderCanceled[0]);
        }

        sling.marinaOrders = marinaOrders;

        if (!sling.id) {
            sling.slingOrigin = RequestOrigin.EasyWeb;
            return this.http.post(
                environment.apiHost + '/secure/sling/', sling).pipe(map((response: any) => response.data.sling));
        }

        return this.update(sling);
    }

    checkBookingTime(sling: Sling): Observable<Sling> {
        sling.slingOrigin = RequestOrigin.EasyWeb;
        return this.http.post(
            environment.apiHost + '/secure/sling/checkBookingTime', sling).pipe(map((response: any) => response.data.sling));
    }

    update(sling: Sling): Observable<Sling> {
        return this.http.put(
            environment.apiHost + '/secure/sling/', sling).pipe(map((response: any) => response.data.sling));
    }

    updateOnly(sling: Sling): Observable<Sling> {

      let senObj = {id:sling.id, slingStatus: sling.slingStatus ,boatStatus:sling.boatStatus,
        boat:{id:sling.boat.id, name:sling.boat.name},
        navigationPlan:{id:sling.navigationPlan.id,
            responsible:{id:sling.navigationPlan.responsible.id, name:sling.navigationPlan.responsible.name},
        },

      }
        return this.http.put(
            environment.apiHost + '/secure/sling/update-sling-only', senObj).pipe(map((response: any) => response.data.sling));
    }
    confirmSling(sling: Sling): Observable<any> {
        const executionForecast = new Date(sling.executionForecast);
        return this.http.put(
            environment.apiHost + `/secure/sling/confirmSling/${sling.id}`, executionForecast.getTime()
        ).pipe(map((response: any) => response.data));
    }

    cancelSling(id: number): Observable<any> {
        return this.http.put(`${environment.apiHost}/secure/sling/${id}/cancel-sling`, null).pipe(map((response: any) => response.data));
    }

    prepare(sling): Observable<Sling> {
        // sling.slingOrigin = 'Marina';
        return this.http.post(
            environment.apiHost + '/secure/sling/prepare/', sling).pipe(map((response: any) => response.data.sling));
    }

    getNumberOfSlingDifferentSlip(): Observable<any> {
        return this.http.get(
            environment.apiHost + '/secure/slings/number-sling-different-slip').pipe(map((response: any) => response.data));
    }

    confirmOwnFuelSupply(sling: Sling): Observable<any> {
        const executionForecast = new Date(sling.executionForecast);
        return this.http.put(
            environment.apiHost + `/secure/sling/confirmOwnFuelSupply/${sling.id}`, executionForecast.getTime()
        ).pipe(map((response: any) => response.data));
    }

    findBoatIdsWithSlingsNextDay(boatIds: number[]): Observable<any> {
        return this.http.get(
            environment.apiHost + '/secure/slings/until-next-day/' + boatIds.toString()).pipe(map((response: any) => response.data.slings));
    }

    findBoatIdsWithSlingFinishedOnWater(): Observable<any> {
        return this.http.get(
            environment.apiHost + '/secure/slings/finished-on-water').pipe(map((response: any) => response.data.slings));
    }

    getAllByBoat(boatId): Observable<Sling[]> {
        return this.http.get(
            environment.apiHost + `/secure/slings/boat/${boatId}`)
            .pipe(map((response: any) => response.data.slings as Sling[]));
    }
}
