import { AccessPointService } from './../../../../services/access-controler/access-point.service';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Customer } from 'src/app/models';

@Component({
  selector: 'app-customer-catracas',
  templateUrl: './customer-catracas.component.html',
  styleUrls: ['./customer-catracas.component.scss'],
})
export class CustomerCatracasComponent implements OnInit {
  customer: any;

  points: any[] = [];
  constructor(
    public dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private AccessPointService: AccessPointService
  ) {}

  ngOnInit(): void {
    if (this.config.data) {
      this.customer = this.config.data.customer;
      this.customer.catracas.forEach((catraca) => {
        this.points.push({ catraca: catraca, status: null });
      });
      this.integrar();
    }
  }

  integrar() {
    this.points.forEach((point) => {
      this.AccessPointService.ingetracaoCatraca({
        accessPoint: point.catraca,
        userId: this.customer?.id,
        typeRegister: 'customer',
      }).subscribe((data) => {
        point.status = data;
      });
    });
  }

  fechar() {
    this.dialogRef.close(this.points);
  }

  reenviar(point) {
    point.status = null;
    this.AccessPointService.ingetracaoCatraca({
      accessPoint: point.catraca,
      userId: this.customer?.id,
      typeRegister: 'customer',
    }).subscribe((data) => {
      point.status = data;
    });
  }
}
