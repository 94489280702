<p-fieldset>
  <p-header class="article-title">
      <span class="article-title">{{'Relatório de Situação Financeira' | translate}}</span>
      <div class="header-buttons">
        <button class="fab-button primary" (click)="exportTablePdf()" label="Exportar valores"
            pTooltip="Exportar Valores" tooltipPosition="left">
            <i class="pi pi-file"></i>
        </button>
        <button class="fab-button primary" appFiltersButton pTooltip="Exibir/Ocultar Filtros"
            tooltipPosition="left">
            <i class="pi pi-filter"></i>
        </button>


    </div>
  </p-header>

  <form class="filters" (submit)="search()" appFilters>
      <div class="filter-row">
          <div class="form-control">
              <label>{{'ACCESS-GROUP' | translate}}</label>
              <p-multiSelect [options]=" accessgroupOption"
                  appendTo="body" [(ngModel)]="filter.grupos" [title]="'ACCESS-GROUP' | translate" [ngModelOptions]="{standalone: true}"
                  [filter]=false selectedItemsLabel="{0} itens selecionados">
              </p-multiSelect>
          </div>
          <div class="form-control">
              <label for="startDate">{{'período de' | translate}}</label>
              <p-calendar dateFormat="dd/mm/yy" name="startDate" rPCalendarMask appendTo="body"
                  [(ngModel)]="filter.starDate" [showIcon]="true" i18n [monthNavigator]="true" [ngModelOptions]="{standalone: true}"
                  [yearNavigator]="true" [yearRange]="'2019:2030'">
              </p-calendar>
          </div>
          <div class="form-control">
              <label for="endDate">{{'até' | translate}}</label>
              <p-calendar dateFormat="dd/mm/yy" name="endDate" rPCalendarMask [(ngModel)]="filter.endDate"
                  appendTo="body" [showIcon]="true" i18n [monthNavigator]="true" [yearNavigator]="true" [ngModelOptions]="{standalone: true}"
                  [yearRange]="'2019:2030'">
              </p-calendar>
          </div>

          <div class="buttons">
              <div class="form-button">
                  <button (click)="clear()" class="easymarine danger" type="button">{{ 'CLEAR' | translate }}</button>
              </div>
              <div class="form-button">
                  <button class="easymarine primary" type="submit">{{ 'FIND' | translate }}</button>
              </div>
          </div>
      </div>
  </form>

  <div class="table">
      <p-table #tt [value]="report" styleClass="easy-table"
          autoLayout="true"  [lazy]="true">
          <ng-template pTemplate="caption">
              <div style="display: flex; width: 100%; justify-content: space-between;">

                  <span style="font-weight: bold">{{ 'TOTAL-REGISTERS' | translate }}:
                      {{report?.length}}</span>
              </div>
          </ng-template>
          <ng-template pTemplate="header">

             <tr>
                  <th>{{ 'Matrícula' | translate }}</th>
                  <th>{{ 'NAME' | translate }}</th>
                  <th>{{ 'ACCESS-GROUP' | translate }}</th>
                  <th>{{ 'Período' | translate }} Atual</th>
                  <th>{{ 'Qte' | translate }}</th>
                  <th class="text-right">{{ 'Valor' | translate }}</th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-register>
              <tr>
                  <td >{{register.matricula}}</td>
                  <td>{{register.nome}}</td>
                  <td>{{register.categoria}}</td>
                  <td>{{register.periodo}}</td>
                  <td>{{register.qte}}</td>
                  <td class="text-right">{{register.valor | currency : 'BRL':true:'1.2-2'}}</td>
              </tr>
          </ng-template>
      </p-table>
  </div>
</p-fieldset>

