<div class="dialog-form">
    <div class="form-group" [ngSwitch]="product.pricingType">
        <div class="form-control" *ngSwitchCase="'FixedPerRange'">
            <span class="p-float-label">
                <label for="product.price.value">Escolha o Preço</label>
                <p-dropdown [options]="priceRanges" [(ngModel)]="product.price.value"
                placeholder="Selecione...">
                </p-dropdown>
            </span>
        </div>
        <div class="form-control" *ngSwitchCase="'PerRangeLength'">
            <span class="p-float-label">
                <label for="product.price.value">Escolha Embarcação</label>
                <p-dropdown [options]="priceRanges" [(ngModel)]="product.price.value"
                    placeholder="Selecione..." [filter]="true" filterBy="label">
                </p-dropdown>
            </span>
        </div>
        <div class="form-control" *ngSwitchDefault>
            <span class="p-float-label">
                <label for="product.price.value">Preço unitário</label>
                <p-inputNumber mode="decimal" [(ngModel)]="product.price.value" name="product.price.value"
                    [minFractionDigits]="numberDecimals()" [maxFractionDigits]="numberDecimals()" [disabled]="!product.price.priceFree">
                </p-inputNumber>
            </span>
        </div>
        <div class="form-control">
            <span class="p-float-label">
                <label for="product.quantity">Quantidade</label>
                <p-inputNumber *ngIf="product.initialsMeasurement != 'LT'
                    && product.initialsMeasurement != 'KG'; else kgOrLt"
                    [(ngModel)]="product.quantity" name="product.quantity"/>
                <ng-template #kgOrLt>
                    <p-inputNumber mode="decimal" [(ngModel)]="product.quantity" name="product.quantity"
                        [minFractionDigits]="3" [maxFractionDigits]="3"
                        (onInput)="updateValues(product, 'quantity', $event)"/>
                </ng-template>
            </span>
        </div>
        <div class="form-control" *ngIf="product.enableReferenceDate && (product.productType === 'Service' || product.productType === 'PeriodicalService')">
            <span class="p-float-label">
            <label for="product.referenceDate">Referência</label>
            <p-inputMask mask="99/9999" [(ngModel)]="referenceDate"></p-inputMask>
            </span>
        </div>
    </div>
    <div class="form-group body">
        <div class="form-control image">
            <img *ngIf="product.file; else noimage" src="{{product.file}}" class="image delete-image" />
            <ng-template #noimage>
                <img src="assets\images\sem_imagem.jpg" class="image delete-image" />
            </ng-template>
        </div>
        <div class="form-control quantities inscreases">
            <div class="button-column">
                <button class="white-button" (click)="increaseQuantity()">
                    <i class="pi pi-plus"></i>
                </button>
                <button class="white-button" (click)="decreaseQuantity()">
                    <i class="pi pi-minus"></i>
                </button>
            </div>
        </div>
        <div class="form-control quantities">
            <div class="button-column">
                <button class="fab-button primary" (click)="concatenateQuantity(5)">+ 5</button>
                <br>
                <button class="fab-button primary" (click)="concatenateQuantity(50)">+ 50</button>
            </div>
            <div class="button-column">
                <button class="fab-button primary" (click)="concatenateQuantity(10)">+ 10</button>
                <br>
                <button class="fab-button danger" (click)="clear()">Zerar</button>
            </div>
        </div>
    </div>
    <div class="buttons">
        <button class="danger easymarine" (click)="dialogRef.close()">{{ 'CANCEL' | translate }}</button>
        <button class="primary easymarine" (click)="confirm()">{{ 'CONFIRM' | translate }}</button>
    </div>
</div>
