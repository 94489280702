import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';
import { Conta } from 'src/app/models';
import { CollectorDTO } from 'src/app/models/dtos/access-controller/collectorDTO';
import { PaymentsByCollectorDTO } from 'src/app/models/dtos/access-controller/paymentsByCollectorDTO';
import { ReceivedByCollectorFilter } from 'src/app/models/dtos/received-by-collector-filter';
import { FinancesService } from 'src/app/services/finances.service';
import { ReceivedByCollectorServiceService } from 'src/app/services/received-by-collector-service.service';
import { FormatUtil } from 'src/app/utils/format.util';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-received-by-collector',
  templateUrl: './received-by-collector.component.html',
  styleUrls: ['./received-by-collector.component.scss']
})
export class ReceivedByCollectorComponent {
hasCompany: boolean = false;
payments: PaymentsByCollectorDTO[] = [];
displayPayments: PaymentsByCollectorDTO[] = [];
receivedByCollectorFilter: ReceivedByCollectorFilter = new ReceivedByCollectorFilter();
collectorsOptions: { label: string; value: any; }[] = [
  {label: "Todos", value: null}
];
accountOptions: { label: string; value: any; }[] = [
  {label: "Todas", value: null}
];
rangePaymentDates: Date[] = [];
globalFilter: string = '';

constructor(
  private receivedByCollectorService: ReceivedByCollectorServiceService,
  private financesService: FinancesService,
  private dialog: DialogService,
  private messageUtil: MessageUtil,
  private spinner: NgxSpinnerService,
) {}

  ngOnInit() {
    this.initializeRangePaymentDates();
    this.initializeCollectorOptions();
    this.initializeAccountOptions();
    this.getAll();
  }

  getAll() {
    this.spinner.show();
    this.getPaymentsByFilter();
    this.getCollectors();
    this.getAccounts();
    this.spinner.hide();
  }

  getPaymentsByFilter() {
    this.receivedByCollectorService.getPaymentsByFilter(this.receivedByCollectorFilter).subscribe({
      next: (value) => {
        this.payments = [...value];
        this.displayPayments = value;
        },
        error: error => {
          let message = error.error.message.split('problem: ')[1];
          this.messageUtil.generateMessage(
            'error',
            'Erro',
            message
          );
        }
    });
  }

  getCollectors() {
    this.receivedByCollectorService.getCollectors().subscribe({
      next: (value) => {
        this.collectorsOptions = [
          { label: 'Todos', value: null },
          ...value.map((collector: CollectorDTO) => ({
            label: collector.name,
            value: collector,
          })),
        ];
        if (this.collectorsOptions.length > 2) {
          this.hasCompany = true;
        }
      },
      error: error => {
        let message = error.error.message.split('problem: ')[1];
        this.messageUtil.generateMessage(
          'error',
          'Erro',
          message
        );
      }
    });
  }

  getAccounts() {
    this.financesService.findContasAtivas().subscribe({
      next: (value) => {
        this.accountOptions = [
          { label: 'Todas', value: null },
          ...value.map((account: Conta) => ({
            label: account.nome,
            value: account.idConta,
          })),
        ];
      },
      error: error => {
        let message = error.error.message.split('problem: ')[1];
        this.messageUtil.generateMessage(
          'error',
          'Erro',
          message
        );
      }
    });
  }
  
  clear() {
    this.initializeRangePaymentDates();
    this.initializeCollectorOptions();
    this.initializeAccountOptions();
  }

  onChangePaymentDate() {
    if (this.rangePaymentDates && this.rangePaymentDates.length > 0) {
      if (this.rangePaymentDates[0] != null) {
        this.receivedByCollectorFilter.startPaymentDate = this.rangePaymentDates[0];
      }
      if (this.rangePaymentDates[1] != null) {
        this.receivedByCollectorFilter.endPaymentDate = this.rangePaymentDates[1];
      } else {  
        this.receivedByCollectorFilter.endPaymentDate = null;  
      }
    } else {
      this.receivedByCollectorFilter.startPaymentDate = null;
      this.receivedByCollectorFilter.endPaymentDate = null;
    }
  }

  onChangeCollector() {
    this.receivedByCollectorFilter.collectors.forEach((collector, index) => {
      if (this.receivedByCollectorFilter.collectors.length > 1) {
        if (index == this.receivedByCollectorFilter.collectors.length - 1) {
          if (collector == null) {
            this.receivedByCollectorFilter.collectors = [null];
          }
        }
        if (index == 0 && collector == null) {
          this.receivedByCollectorFilter.collectors.shift();
        }
      }
    });
  }
  
  onChangeAccount() {
    this.receivedByCollectorFilter.accountIds.forEach((account, index) => {
      if (this.receivedByCollectorFilter.accountIds.length > 1) {
        if (index == this.receivedByCollectorFilter.accountIds.length - 1) {
          if (account == null) {
            this.receivedByCollectorFilter.accountIds = [null];
          }
        }
        if (index == 0 && account == null) {
          this.receivedByCollectorFilter.accountIds.shift();
        }
      } 
    });
  }
    
  filterGlobal(): void {
    this.displayPayments = this.payments
      .filter(
        (payment) =>
          (payment.titleName &&
            FormatUtil.getNotAccents(payment.titleName).toUpperCase().includes(FormatUtil.getNotAccents(this.globalFilter).toUpperCase())) ||
          (payment.invoiceNumber &&
            payment.invoiceNumber.toString().includes(this.globalFilter))
      ).map((payment) => {
        return payment;
      });
  }

  exportTable() {
    const data = { table: this.displayPayments, type: 'RECEIVED_BY_COLLECTOR' };
    this.dialog.open(CustomReportExportComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data,
      header: this.messageUtil.translateKey('REPORT-RECEIVED-BY-COLLECTOR'),
    });
  }

  initializeRangePaymentDates() {
    const now = new Date();
    const firstDayLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    const lastDayLastMonth = new Date(now.getFullYear(), now.getMonth(), 0);
  
    this.rangePaymentDates = [firstDayLastMonth, lastDayLastMonth];
    this.receivedByCollectorFilter.startPaymentDate = firstDayLastMonth;
    this.receivedByCollectorFilter.endPaymentDate = lastDayLastMonth;
  }

  initializeCollectorOptions() {
    this.receivedByCollectorFilter.collectors = [null];
  }

  initializeAccountOptions() {
    this.receivedByCollectorFilter.accountIds = [null];
  }

}
