import { Injectable } from '@angular/core';
import { ReceivedByCollectorFilter } from '../models/dtos/received-by-collector-filter';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReceivedByCollectorServiceService {

  constructor(private http: HttpClient) { }
  
  getCollectors():Observable<[]> {
    return this.http.get(environment.apiHost + '/api-orders/secure/received-by-collectors/collectors')
        .pipe(map((response: any) => response))
  }

  getPaymentsByFilter(receivedByCollectorFilter: ReceivedByCollectorFilter):Observable<[]> {
    return this.http.post(environment.apiHost + '/api-orders/secure/received-by-collectors/filtered', receivedByCollectorFilter)
        .pipe(map((response: any) => response))
  }
}
