import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';
import { AccessGroup } from 'src/app/models/dtos/access-controller/access-group';
import { FinancialStatusDto } from 'src/app/models/dtos/report/financial-status-dto';
import { FinancialStatusReport } from 'src/app/models/dtos/report/financial-status-report';
import { AccessGroupService } from 'src/app/services/access-controler/access-group.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-financialStatusReport',
  templateUrl: './financialStatusReport.component.html',
  styleUrls: ['./financialStatusReport.component.scss']
})
export class FinancialStatusReportComponent implements OnInit {

  filter:FinancialStatusReport;

  report:FinancialStatusDto[]= [];

  accessgroupOption: SelectItem[] = [];
  constructor(
    private accessGroupService: AccessGroupService,
    private spinner: NgxSpinnerService,
    private toasty: ToastService,
    private invoiceService: InvoiceService,
    public dialog: DialogService,
  ) { }

  ngOnInit(  ) {
    this.filter = new FinancialStatusReport();
    this.filter.starDate = new Date();
    this.filter.starDate.setMonth(this.filter.starDate.getMonth() - 1);
    this.filter.endDate = new Date();
    this.findAllAccessCategories();
  }

  findAllAccessCategories() {
    this.spinner.show();
    this.accessGroupService.findAll().subscribe({
      next: (categories) => {
        this.accessgroupOption = categories.map((category) => {
          return { label: category.name, value: category.id };
        }
        );


        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
        this.toasty.error(error.message ?? 'Erro ao iniciar a tela');
      },

    });
  }

  search() {
    this.spinner.show();
    this.invoiceService.financialStatusReport(this.filter).subscribe({
      next: (report) => {
        this.report = report.report;
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
        this.toasty.error(error.message ?? 'Erro ao buscar relatório');
      },
    });
  }

  clear() {
    console.log('clear');
  }

  exportTablePdf(){

    const dataExport = {table: this.report, type: 'FINANCIAL_STATUS_REPORT'};
        this.dialog.open(CustomReportExportComponent, {
          width: '70%',
          height: '90vh',
          dismissableMask: false,
          data: dataExport,
          header: 'Exportar Relatório de Embarcações',
        });

  }
}
