import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { Boat } from 'src/app/models';
import { BoatEngine } from 'src/app/models/boat-engine';
import { BoatService } from 'src/app/services/boat.service';

@Component({
  selector: 'app-boat-engines-table',
  templateUrl: './boat-engines-table.component.html',
  styleUrls: ['./boat-engines-table.component.scss'],
})
export class BoatEnginesTableComponent {
  formGroup: UntypedFormGroup;
  formArray: UntypedFormArray;

  boat: Boat;

  @Input('boat') set setBoat(boatObj : Boat){
    this.boat = boatObj;
    this.getBoatEnginesFromBoat();
  }

  @Output()
  finishEngineEvent: EventEmitter<any> = new EventEmitter();

  boatEngines: BoatEngine[];
  engineMarkOptions: SelectItem[] = [];
  showInput: boolean[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private boatService: BoatService
  ) {}

  async ngOnInit(): Promise<void> {
    this.formGroup = this.formBuilder.group({
      boatEngines: this.formBuilder.array([]),
    });
    this.formArray = this.formGroup.get('boatEngines') as UntypedFormArray;

    this.boatService.getEngineMarks().subscribe({
      next: (data) => {
        this.engineMarkOptions = data.map((engine) => ({
          label: engine.name,
          value: engine.name,
        }));
        this.engineMarkOptions.splice(1, 0, {
          label: 'Outros',
          value: 'Outros',
        });
      },
    });
  }

  private getBoatEnginesFromBoat() {
    this.boatEngines = this.boat?.boatEngines || [];
    this.boatEngines.forEach((engine, index) => {
      const form = this.buildEngine(engine, index);
      this.formArray.push(form);
    });
  }

  buildEngine(engine?: BoatEngine, index?: number): UntypedFormGroup {
    const isUpdate = index !== undefined;
    const form = this.formBuilder.group({
      id: new UntypedFormControl(engine?.id || null),
      boat: new UntypedFormControl(engine?.boat || null),
      mark: new UntypedFormControl(engine?.mark || 'Akasaka Diesels Ltd'),
      model: new UntypedFormControl(engine?.model || ''),
      power: new UntypedFormControl(engine?.power || ''),
      serialNumber: new UntypedFormControl(engine?.serialNumber || ''),
      manufactureYear: new UntypedFormControl(engine?.manufactureYear || ''),
    });

    if (isUpdate) {
      const markExists = this.engineMarkOptions.some(
        (option) => option.value === engine?.mark
      );
      if (!markExists) {
        this.showInput[index] = true;
      } else if (engine?.mark === 'Outros') {
        this.showInput[index] = false;
      } else {
        this.showInput[index] = false;
      }
    } else {
      this.showInput.push(false);
    }

    return form;
  }

  getEngines() {
    return this.formArray.controls;
  }

  onMarkChange(event: any, index: number): void {
    const selectedValue = event.value;
    if (selectedValue === 'Outros') {
      this.showInput[index] = true;
    } else {
      this.showInput[index] = false;
    }
    this.send();
  }

  addEngine(): void {
    this.formArray.push(this.buildEngine());
    this.showInput.push(false);
    this.send();
  }

  deleteEngine(index: number): void {
    this.formArray.removeAt(index);
    this.showInput.splice(index, 1);
    this.send();
  }

  send() {
    this.finishEngineEvent.emit(this.formArray.value);
  }
}
