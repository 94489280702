<div class="new-filter-row">
  <div class="subtitles">
    <fieldset class="side-by-side">
      <legend>Legenda (Cores de fundo)</legend>
      <div class="red">
        <p-checkbox
          value="red"
          class="clickable"
          type="checkbox"
          class="checkbox"
        ></p-checkbox>
        Dados Incompletos
      </div>
    </fieldset>
    <div class="new-form-button">
      <button class="new-easymarine primary" (click)="getVisitorsByFilter()">
        {{ "FIND" | translate }}
        &nbsp;
        <i class="pi pi-search"></i>
      </button>
    </div>
  </div>
</div>
<div class="table">
  <p-table
    #visitorDataTable
    [value]="visitorList"
    (onLazyLoad)="onChangePage($event)"
    [paginator]="true"
    [rows]="numberOfRows"
    [lazy]="true"
    [rowsPerPageOptions]="[10, 20, 30, 50, 100]"
    [rows]="pageable ? pageable.size : null"
    dataKey="id"
    sortMode="multiple"
    csvSeparator=";"
    [totalRecords]="totalRecords"
    styleClass="easy-table"
    [alwaysShowPaginator]="true"
    autoLayout="true"
    [globalFilterFields]="['federalId', 'name', 'phone']"
  >
    <ng-template pTemplate="caption">
      <div class="table-header">
        <span class="p-input-icon-left center">
          <i class="pi pi-search"></i>
          <input
            placeholder="Filtro por nome, documento e telefone"
            type="text"
            style="width: 320px"
            pInputText
            (input)="
              visitorDataTable.filterGlobal(
                $any($event.target).value,
                'contains'
              )
            "
          />
        </span>
        <span class="right" style="font-weight: bold; margin-top: 5px">
          {{ "TOTAL-REGISTERS" | translate }}: {{ visitorList?.length }}
          {{ "OF" | translate }} {{ totalRecords }}
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th [pSortableColumn]="'federalId'">
          {{ "CPF" | translate }}
          <p-sortIcon [field]="'federalId'"></p-sortIcon>
        </th>

        <th [pSortableColumn]="'name'">
          {{ "NAME" | translate }}
          <p-sortIcon [field]="'name'"></p-sortIcon>
        </th>

        <th [pSortableColumn]="'photo'">
          {{ "PHOTO" | translate }}
          <p-sortIcon [field]="'photo'"></p-sortIcon>
        </th>

        <th>
          {{ "Autorizado por:" | translate }}
        </th>
        <th>
          {{ "PHONE" | translate }}
        </th>
        <th class="text-center">
          {{ "Última Permissão" | translate }}
        </th>
        <th class="text-center" [pSortableColumn]="'active'">
          {{ "Status Atual" | translate }}
          <p-sortIcon [field]="'active'"></p-sortIcon>
        </th>
        <th class="text-center">{{ "Ações" | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-visitor>
      <tr>
        <td [ngClass]="{ incomplete: hasIncompleteVisitorData(visitor) }">
          {{ visitor.federalId }}
        </td>
        <td [ngClass]="{ incomplete: hasIncompleteVisitorData(visitor) }">
          {{ visitor.name }}
        </td>
        <td [ngClass]="{ incomplete: hasIncompleteVisitorData(visitor) }">
          <img
            *ngIf="visitor.photoUrl"
            [src]="visitor.photoUrl"
            alt="Foto do Visitante"
            class="photo"
          />
        </td>
        <td [ngClass]="{ incomplete: hasIncompleteVisitorData(visitor) }">
          {{
            getLastPermission(visitor.accessPermissions)
              ?.customerResponsibleName
          }}
        </td>
        <td [ngClass]="{ incomplete: hasIncompleteVisitorData(visitor) }">
          {{ visitor?.phone }}
        </td>
        <td
          [ngClass]="{ incomplete: hasIncompleteVisitorData(visitor) }"
          class="text-center"
        >
          {{
            getLastPermission(visitor.accessPermissions)?.createDate
              | date : "dd/MM/yyyy"
          }}
        </td>
        <td
          [ngClass]="{ incomplete: hasIncompleteVisitorData(visitor) }"
          class="text-center"
        >
          {{ visitor.active ? "Ativo" : "Inativo" }}
        </td>

        <td
          [ngClass]="{ incomplete: hasIncompleteVisitorData(visitor) }"
          class="text-center"
        >
          <p-menu
            appendTo="body"
            #menu
            [model]="menuItems"
            [popup]="true"
            [showTransitionOptions]="'150ms'"
            [hideTransitionOptions]="'150ms'"
          ></p-menu>
          <button
            (click)="menu.toggle($event); changeSelectedItem(visitor)"
            pButton
            type="button"
            icon="pi pi-ellipsis-v"
            class="p-button-text p-button-rounded p-button-lg"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
