import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BoatWashService } from 'src/app/services/boat-wash.service';

@Component({
  selector: 'app-boat-wash-engine-turn-history',
  templateUrl: './boat-wash-engine-turn-history.component.html',
  styleUrls: ['./boat-wash-engine-turn-history.component.scss']
})

export class BoatWashEngineTurnHistoryComponent implements OnInit {

  washOrEngineTurnHistory: any[] = [];
  boatName: string;
  showTooltip = false;
  displayDialog = false;
  selectedObservation = '';
  selectedRecord: any; // Para armazenar o registro selecionado
  isEditing = false;
  type: string;
  id: number; // Flag para habilitar o modo de edição

  constructor(
    private config: DynamicDialogConfig,
    private boatWashService: BoatWashService,
  ) { }

  ngOnInit(): void {
    this.washOrEngineTurnHistory = this.config.data.washOrEngineTurnHistory;
    this.boatName = this.config.data.boatName;
    this.type = this.config.data.type;
  }

  showObservationDialog(record: any, isEditing: boolean) {
    console.log('record', record)
    this.selectedRecord = record;
    this.selectedObservation = record.observation;
    this.isEditing = isEditing;
    this.displayDialog = true;
    this.id = record.id
  }

  editObservation() {
    this.isEditing = true;
  }

  saveObservation() {
    if (this.selectedRecord) {
      this.selectedRecord.observation = this.selectedObservation;
    }
    this.isEditing = false;
    this.displayDialog = false;

    this.boatWashService
      .saveBoatWashObservation(this.type, this.id, this.selectedRecord.observation)
      .subscribe({
        next: (response) => {
          console.log('Observação salva com sucesso:', response);
        },
        error: (error) => {
          console.error('Erro ao salvar a observação:', error);
        },
      });
  }
}
