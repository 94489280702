import { Marina } from './marina';
import { Customer } from './customer';
import { Sailor } from './sailor';
import {UserAccess} from "./user/user-access";

export class User {
    id: number;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    email: string;
    bracelet: string;
    marina: Marina;
    token: string;
    created: any;
    active = true;
    customers: Customer[];
    marinas: Marina[];
    confirmPassword?: string;
    roleId: any;
    sailor: Sailor;
    refreshToken: string;
    accessList: UserAccess[];

    constructor() {
        this.marina = new Marina();
        this.customers = [];
        this.marinas = [];
        this.accessList = [];
        this.email = null;
        this.id = null
    }
}
