<div class="login-page">
    <div class="box-white">
        <h1>Login</h1>

        <form (submit)="login()" [formGroup]="loginForm" class="p-field">
            <div class="form-control" [ngClass]="{'invalid': isInvalid(loginForm.get('username'))}">
                <i class="icon pi pi-envelope"></i>
                <span class="p-float-label">
                    <input type="text" autocomplete="username" formControlName="username" id="username" name="username"
                        pInputText autocomplete="off">
                    <label for="username">Usuário *</label>
                 </span>
            </div>

            <div class="form-control" [ngClass]="{'invalid': isInvalid(loginForm.get('password'))}">
                <i class="icon pi pi-lock"></i>
                <span class="p-float-label">
                    <input type="password" formControlName="password" id="password" name="password" pInputText autocomplete="off">
                    <label for="password">Senha *</label>
                </span>
            </div>

            <div class="buttons">
                <button type="submit" [disabled]="!loginForm.valid" class="easy-button full-width" pButton label="Login"></button>
            </div>
        </form>

        <div *ngIf="messageLogin != null" class="alert alert-danger text-center error-message">
            <strong>
                {{getErrorMessage()}}
            </strong>
        </div>
    </div>
</div>
