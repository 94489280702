<div class="table">
    <p-table #tt [value]="usersTable" [paginator]="true" [rows]="numberOfRows" resetPageOnSort="true" [pageLinks]="3"
        [rowsPerPageOptions]="[10,20,30,50,100]" [resizableColumns]="true" [autoLayout]="true"
        [globalFilterFields]="['firstName','lastName','email','username','active', 'lastMobilePlatform', 'versionMobilePlatform', 'versionInstalledApp']">
        <ng-template pTemplate="caption">
            <p-selectButton [ngStyle]="{'float': 'left'}" [(ngModel)]="userStatus"
                [options]="[{label: 'Todos', value: null}, {label: 'Ativo', value: true}, {label: 'Inativo', value: false}]"
                (onChange)="tt.filter($event.value, 'active', 'equals')">
            </p-selectButton>
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input type="text" pInputText size="50" placeholder="Filtro global"
                    (input)="tt.filterGlobal($event.target.value, 'contains')" style="width:auto">
            </span>
            <span style="font-weight: bold; float: right; margin-top: 5px;">{{ 'TOTAL-REGISTERS' | translate }}:
                {{tt.totalRecords}} {{'OF' | translate}} {{usersTable.length}}
            </span>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th [pSortableColumn]="'id'">
                    {{'ID' | translate}}
                    <p-sortIcon [field]="'id'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'firstName'">
                    {{'NAME' | translate}}
                    <p-sortIcon [field]="'firstName'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'email'">
                    {{'EMAIL' | translate}}
                    <p-sortIcon [field]="'email'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'username'">
                    {{'USERNAME' | translate}}
                    <p-sortIcon [field]="'username'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'lastMobilePlatform'">
                    {{'PLATFORM' | translate}}
                    <p-sortIcon [field]="'lastMobilePlatform'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'versionMobilePlatform'">
                    {{'PLATFORM-VERSION' | translate}}
                    <p-sortIcon [field]="'versionMobilePlatform'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'lastLogin'">
                    {{'LAST-LOGIN' | translate}}
                    <p-sortIcon [field]="'lastLogin'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'versionInstalledApp'">
                    {{'APP-VERSION' | translate}}
                    <p-sortIcon [field]="'versionInstalledApp'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'created'">
                    {{'CREATED' | translate}}
                    <p-sortIcon [field]="'created'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'active'">
                    {{'STATUS' | translate}}
                    <p-sortIcon [field]="'active'"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
            <tr>
                <td>{{getCustomerId(user)}}</td>
                <td>{{user.firstName}} {{user.lastName}}</td>
                <td>{{user.email}}</td>
                <td>{{user.username}}</td>
                <td>{{getFieldFromAccessList(user, 'platform')}}</td>
                <td class="text-center">{{getFieldFromAccessList(user, 'platformVersion')}}</td>
                <td>{{getFieldFromAccessList(user, 'loginDate') | date: 'dd/MM/yyyy HH:mm'}}</td>
                <td class="text-center">{{getFieldFromAccessList(user, 'applicationVersion')}}</td>
                <td>{{user.created | date: 'dd/MM/yyyy HH:mm'}}</td>
                <td class="text-center">
                    <span *ngIf="user.active" class="badge badge-pill success">{{ 'ACTIVE' |
                            translate }}</span>
                    <span *ngIf="!user.active" class="badge badge-pill danger">{{ 'INACTIVE' |
                            translate }}</span>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
