<form #f="ngForm" autocomplete="off" (ngSubmit)="confirmRegistration()" class="dialog-form">
    <div class="boat-name">
        <div class="form-control">
            <h5>Barco(s) selecionado(s)</h5>
        </div>
        <div class="badges">
            <span class="badge badge-pill badge-primary" *ngFor="let boat of boatsWash; let i = index;">
                {{ boat.boatName}}
            </span>
        </div>
    </div>
    <br>
    <div class="form-group">
        <div class="date-wash-turn">
            <div class="form-control">
                <label>Data <strong>({{requestDate | date:'dd/MM/yyyy'}} - {{getDayOfWeek()}})</strong></label>
                <p-calendar dateFormat="dd/mm/yy" name="requestDate" [(ngModel)]="requestDate" [inline]="true"
                    [showIcon]="true" i18n rPCalendarMask [maxDate]="today" appendTo="body">
                </p-calendar>
            </div>
        </div>
        <div class="option">
            <div>
                <label><strong>Selecione uma das opções</strong></label>
            </div>
            <br>
            <div class="radio-buttons">
                <div class="form-control">
                    <p-radioButton name="washEngineTurnOption" value="WASH_ONLY" label="Lavação"
                        [(ngModel)]="washEngineTurnOption" inputId="groupWashEngineTurnAction1" required>
                    </p-radioButton>
                </div>
                <div class="form-control">
                    <p-radioButton name="washEngineTurnOption" value="ENGINE_TURN_ONLY" label="Giro de motor"
                        [(ngModel)]="washEngineTurnOption" inputId="groupWashEngineTurnAction2" required>
                    </p-radioButton>
                </div>
                <div class="form-control">
                    <p-radioButton name="washEngineTurnOption" value="WASH_AND_ENGINE_TURN"
                        label="Lavação e giro de motor" [(ngModel)]="washEngineTurnOption"
                        inputId="groupWashEngineTurnAction3" required>
                    </p-radioButton>
                </div>
            </div>
            <div *ngIf="OneTimeScheduling" class="form-control basic observation-input">
                <textarea
                    pInputText
                    name="description"
                    rows="8"
                    [(ngModel)]="observation"
                    (ngModelChange)="onObservationChange($event)"
                    style="width: 600px; min-width: 600px; margin-left: -150px;"
                ></textarea>
                <label style="margin-left: -150px;" for="description">Observações</label>
            </div>
            <div *ngIf="!OneTimeScheduling">
                <p class="observation-alert">Para adicionar observações, selecione apenas uma embarcação.</p>
            </div>
        </div>
    </div>
    <div class="buttons">
        <button class="primary easymarine" type="submit" [disabled]="f.invalid || boatsWash?.length == 0">Salvar
        </button>
    </div>
</form>